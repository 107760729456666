import React from 'react';
import { Toast } from 'react-bootstrap';

function ToastNotification({ show, setShow, invoice }) {
    // const [show, setShow] = useState(false);
    console.log(invoice)
    return (
        <React.Fragment>
            <Toast
                style={{
                    position: 'absolute',
                    top: 100,
                    right: 50,
                    maxWidth: '600px',
                    height: '54px',
                    backgroundColor: '#55616E',
                    fontSize: '14px',
                    lineHeight: '16px',
                    textAlign: 'right',
                    color: '#FFFFFF',
                    display: 'flex',
                    alignItems: 'center'
                }}
                onClose={() => setShow(false)}
                show={show}
                delay={3000}
                autohide
            >
                {invoice && (
                    <Toast.Body>{invoice.invoiceNumber} ({invoice.invoiceType == 'Trans Plus SF Invoice' ? 'Unsigned' : invoice.invoiceType}) is deleted from only the transaction</Toast.Body>
                )}
            </Toast>

            {/* <Button onClick={() => setShow(true)}>Show Toast</Button> */}
        </React.Fragment>

    )
}

export default ToastNotification;
