import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import {
	Row,
	Col,
	Button,
	Form,
	FormControl,
	Table,
} from "react-bootstrap";
import Footer from '../uploadInvoices/Footer';
import { InvSearchtableData, tableData, cols } from './tableData';
import { useDispatch, useSelector } from "react-redux";
import "./mapping-tracker.css";
import Skeleton from 'react-skeleton-loader';
import Datatable1 from "../dashboard/Datatable";
import moment from "moment";


function MappingTracker() {
	const [fromDate, setStartDate] = useState(new Date(moment().subtract(5, "days")));
	const [toDate, setEndDate] = useState(new Date(moment()));

	const [invoiceSearchInput, setInvoiceSearchInput] = useState(null)
	const [selectInput, setSelectInput] = useState(false)
	const [responseData, setResponseData] = useState([])
	const [dataLoading, setDataLoading] = useState(true)

	const dispatch = useDispatch()

	const submitInvoiceSearchValue = (event) => {
		event.preventDefault();
		if (invoiceSearchInput){
			setDataLoading(true)
			dispatch(InvSearchtableData(invoiceSearchInput))
			.then(res => {
				setDataLoading(false)
				if (res.data !== undefined) {
					setResponseData(res.data.results)
				} else {
					setResponseData([])
				}
			}).catch(error => {
				setDataLoading(false)
				setResponseData([])
				console.error('server error during fetching data', error);
			});
		}
	}

	const submitDateSearchValue = (event) => {
		event.preventDefault();
		setDataLoading(true)
		dispatch(tableData(format(fromDate, 'yyyy-MM-dd'), format(toDate, 'yyyy-MM-dd'), selectInput))
			.then(res => {
				setDataLoading(false)
				if (res.data !== undefined) {
					setResponseData(res.data.results)
				} else {
					setResponseData([])
				}
			}).catch(error => {
				setDataLoading(false)
				console.error('server error during fetching data', error);
			});
	}

	useEffect(() => {
		dispatch(tableData(format(fromDate, 'yyyy-MM-dd'), format(toDate, 'yyyy-MM-dd'), selectInput))
			.then(res => {
				setDataLoading(false)
				if (res.data !== undefined) {
					setResponseData(res.data.results)
				} else {
					setResponseData([])
				}
			}).catch(error => {
				setDataLoading(false)
				console.error('Error during fetching data', error);
			});
	}, [dispatch])
	return (
		<div className="viewInvoices">
			<div className="viewInvoices__container">
				<h4 className="viewInvoices__title">Invoice Mapping Tracker</h4>
				<div className="viewInvoices__horizontalLine"></div>
				<div className="searchSection">
					<Row>
						<Col md={2}>
							<FormControl onChange={(e) => setInvoiceSearchInput(e.target.value)} className="tracker__searchInput" type='text' placeholder='Enter invoice number' />
						</Col>
						<Col>
							<Button className="mx-0" onClick={submitInvoiceSearchValue} id='search-btn'>
								<img src='images/search-icon.png' alt='Search Icon' />
							</Button>
						</Col>
					</Row>

					<div className="viewInvoices__horizontalLine"></div>

					<Row>
						
						<Form.Group as={Row} className=""  controlId="formPlaintextEmail">
							<Col>
								<div className="dashboard__timePeriod">
									<img
									className="dashboard__calenderImg"
									src='images/calender.png'
									alt='calender-icon'
									/>
									<DatePicker
									selected={fromDate}
									onChange={date => {
										setStartDate(date);
									}}
									dateFormat="dd-MMM-yyyy"
									className="dashboard__datepicker"
									/>
								</div>
							</Col>
							<Col>
								<div className="dashboard__timePeriod" >
									<img
									className="dashboard__calenderImg"
									src='images/calender.png'
									alt='calender-icon'
									/>
									<DatePicker
									selected={toDate}
									onChange={date => {
										setEndDate(date, 'MM/dd/yyyy');
									}}
									dateFormat="dd-MMM-yyyy"
									minDate={fromDate}
									/>
								</div>
							</Col>
						</Form.Group>
						
						<Col md={2}>
						<Form.Control as='select' onChange={(e) => setSelectInput(e.target.value)} className="tracker__selectSearch">
								<option value={false}>Un-mapped</option>
								<option value={true}>Mapped</option>
						</Form.Control>
						</Col>
						<Col>
							<Button className="mx-0" onClick={submitDateSearchValue} id='search-btn'>
								<img src='images/search-icon.png' alt='Search Icon' />
							</Button>
						</Col>
					</Row>

				</div>
				<div className="viewInvoices__horizontalLine"></div>

				{(!dataLoading) ? (      
				(responseData.length > 0) ? (
					<Datatable1
						data={responseData}
						columns={cols()}
					/>
				): (
					<div className="dashboard__noDataFound">
					  <h1>No data Available</h1>
					</div>
				  )
				): (
					<Table striped bordered>
						<thead>
							<tr>
								{/* <th><Skeleton /></th> */}
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
							</tr>
						</thead>
						<tbody>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => {
								return (
									<tr className='align-items-center'>
										{/* <td><Skeleton /></td> */}
										<td><Skeleton /></td>
										<td><Skeleton /></td>
										<td><Skeleton /></td>
										<td><Skeleton /></td>
									</tr>
								);
							})}
						</tbody>
					</Table>
					)}
			</div>
			<Footer />
		</div>
	)
}


export default MappingTracker;
