import React, { useState, useEffect } from "react";
import format from "date-fns/format";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Row,
  Col,
  Button,
  Form,
  FormControl,
  Table,
} from "react-bootstrap";
// import Spinner from 'react-bootstrap/Spinner';
import Skeleton from 'react-skeleton-loader';
import "./Dashboard.css";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Datatable1 from "./Datatable";
import { connect } from "react-redux";
import { tableData, basicSearch, selectViewAction } from '../../actions/tableData';
import { Redirect } from "react-router-dom";
import CustomViewModal from "./CustomViewModal";
import AdvanceFilterModal from "./AdvanceFilterModal";
import { CSVLink, CSVDownload } from "react-csv";
import { Formik } from "formik";
import * as Yup from 'yup';
import swal from "sweetalert";
import Dropdown from "../common/Dropdown";
import moment from "moment";


function Dashboard({
  tbleData: { data, columns, downloadData },
  tableData,
  basicSearch,
  selectViewAction,
  // columns, 
  // data, 
  auth: { isAuthenticated, loading },
  // downloadData: { downloadData } 
}) {
  // fetching table data
  useEffect(() => {
    let date = {
      fromDate: format(startDate, 'yyyy-MM-dd'),
      toDate: format(endDate, 'yyyy-MM-dd')
    }

    tableData(date)
      .then(res => {
        setDataLoading(false)
      })

  }, []);

  const [bookingDetails, setBookingDetails] = useState([
    { name: "Depature Date", selector: "Departure_Date", sortable: true },
    { name: "Depature Time", selector: "Departure_Time", sortable: true },
    { name: "Arrival Date", selector: "Arrival_Date", sortable: true },
    { name: "Arrival Time", selector: "Arrival_Time", sortable: true },
    { name: "Type Of Travel", selector: "Type_Of_Travel", sortable: true },
    { name: "From", selector: "From", sortable: true },
    { name: "To", selector: "To", sortable: true },
  ])

  const [transactionDetails, setTransactionDetails] = useState([
    { name: "Total Basic", selector: "Total_Basic", sortable: true },
    { name: "Taxes and Other Charges", selector: "Taxes_and_Other_Charges", sortable: true },
    { name: "Meals Amount", selector: "Meals_Amount", sortable: true },
    { name: "Baggage Amount", selector: "Baggage_Amount", sortable: true },
  ]);

  const [selectedColumns, setSelectedColumns] = useState([
    "Billing Sl No",
    "Sl No",
    "G SL No",
    "TTR SL No",
    "Company",
    "Itilite Website Trip ID",
    "Status",
    "Booking ID",
    "Employee First Name",
    "Employee Last Name",
    "Options",
    "Date Of Transaction",
  ])

  const [customValues, setCustomValues] = useState([]);

  const [showCustomViewModal, setShowCustomViewModal] = useState(false);

  const handleCustomViewModalClose = () => setShowCustomViewModal(false);

  const handleCustomViewModalOpen = () => setShowCustomViewModal(true);

  const [showAdvanceFilterModal, setShowAdvanceFilterModal] = useState(false);

  const handleAdvanceFilterModalClose = () => setShowAdvanceFilterModal(false);

  const handleAdvanceFilterModalOpen = () => setShowAdvanceFilterModal(true);

  // const [selectViewInput, setSelectViewInput] = useState('default')

  const handleSelectView = (e) => {

    // 2nd custom drop
    if (e === "customView") {
      handleCustomViewModalOpen();
    } else {
      selectViewAction(e)
    }
  
  };

  const addMoreCustomValue = () => {
    setCustomValues([...customValues, ""]);
  };

  const removeCustomValues = (index) => {
    customValues.splice(index, 1);

    // update the state
    setCustomValues([...customValues]);
  };

  // Sidebar
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const sidebarToggleHandler = () => {
    setSidebarOpen(!sidebarOpen)
  }

  const sidebarOutsidToggler = () => {
    setSidebarOpen(sidebarOpen)
  }

  // datepicker
  // const [fromDate, setFromDate] = useState(`0${(new Date().getMonth() + 1)}/01/${(new Date().getFullYear())}`); 
  const [fromDate, setFromDate] = useState(`${(new Date().getFullYear())}-0${(new Date().getMonth() + 1)}-01`);

  // const [toDate, setToDate] = useState(`${(new Date().getMonth() + 1)}/${new Date().getDate()}/${(new Date().getFullYear())}`);
  const [toDate, setToDate] = useState(`${(new Date().getFullYear())}-${(new Date().getMonth() + 1)}-${new Date().getDate()}`);

  // const [startDate, setStartDate] = useState(new Date(`${(new Date().getFullYear() - 1)}-${new Date().getDate() <= 10 ? (new Date().getMonth()) : (new Date().getMonth() + 1)}-${new Date().getDate() <= 10 ? 31 - (10 - new Date().getDate()) : new Date().getDate() - 10}`));
  const [startDate, setStartDate] = useState(new Date(moment().subtract(10, "days")));
  const [endDate, setEndDate] = useState(new Date(moment()));

  // search
  const [selectInput, setSelectInput] = useState('ITILITE_website_Trip_ID')
  const [searchInput, setSearchInput] = useState('')

  const submitSearchValue = (event) => {
    setDataLoading(true)

    event.preventDefault();
    if (searchInput === '') {
      let dateRange = {
        toDate: format(endDate, 'yyyy-MM-dd'),
        fromDate: format(startDate, 'yyyy-MM-dd')
      }
      tableData(dateRange)
        .then(res => {
          setDataLoading(false)
        })
    }
    else if (selectInput === 'SL_NO' || selectInput === 'G_SL_NO' || selectInput === 'TTR_SL_NO' || selectInput === 'ITILITE_website_Trip_ID' || selectInput === 'Ticket_No') {
      basicSearch(searchInput, selectInput)
      .then(res => {
        setDataLoading(false)
      })
    } else if (selectInput === 'Company' || selectInput === 'Options') {
      basicSearch(searchInput, selectInput, format(startDate, 'yyyy-MM-dd'), format(endDate, 'yyyy-MM-dd'),)
      .then(res => {
        setDataLoading(false)
      })
    }
  }

  // Download data in CSV
  const [csvDownloadData, setCsvDownloadData] = useState([])
  const [csvHeaders, setCsvHeaders] = useState([])
  const downloadCSV = () => {
    let _tableColumns = [...columns]
    let columnSelector = []
    let filteredData = []
    let headers = []
    let _data = [...data]
    if (_data.length > 0){
      for (let i = 0; i < _tableColumns.length; i++) {
        columnSelector.push(_tableColumns[i].selector)
        headers.push({
          label: _tableColumns[i].name,
          key: _tableColumns[i].selector
        })
      }

      for (let i = 0; i < _data.length; i++) {
        let newObj = new Object()
        for (let key in _data[i]) {
          if (columnSelector.includes(key)) {
            newObj[key] = _data[i][key]
          }
        }
        filteredData.push(newObj)
      }
      setCsvDownloadData(filteredData)
      setCsvHeaders(headers)
    }else{
      return false
    }
  }

  // data loading
  const [dataLoading, setDataLoading] = useState(true)

  // table data render 
  const datatableRender = (data, columns, dataLoading) => {
    if (!dataLoading) {
      if (data.length > 0 && columns.length > 0) {
        return (<Datatable1
          columns={columns}
          data={data}
          downloadData={downloadData}
        />);
      } else {
        return <div className="dashboard__noDataFound">
          <h1>No data Available</h1>
        </div>
      }
    } else {
      if (dataLoading) {
        return <Table striped bordered>
          <thead>
            <tr>
              <th><Skeleton /></th>
              <th><Skeleton /></th>
              <th><Skeleton /></th>
              <th><Skeleton /></th>
              <th><Skeleton /></th>
              <th><Skeleton /></th>
              <th><Skeleton /></th>
            </tr>
          </thead>
          <tbody>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => {
              return (
                <tr className='align-items-center'>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                  <td><Skeleton /></td>
                </tr>
              );
            })}
          </tbody>
        </Table>

      } else {
        console.log('no data found')
        return <h1>No data found</h1>
      }
    }
  }

  const selectViewOptions = [
    {
      id: 1,
      itemName: 'Custom View',
      value: 'customView'
    },
    {
      id: 2,
      itemName: 'Default',
      value: 'default'
    },
    {
      id: 3,
      itemName: 'Booking Details',
      value: 'bookingDetails'
    },
    {
      id: 4,
      itemName: 'Vendor Details',
      value: 'vendorDetails'
    },
    {
      id: 5,
      itemName: 'Paybycard Details',
      value: 'paybycardDetails'
    },
    {
      id: 6,
      itemName: 'Invoice Details',
      value: 'invoiceDetails'
    },
    {
      id: 7,
      itemName: 'GST Details',
      value: 'gstDetails'
    },
    {
      id: 8,
      itemName: 'Multicurreny Details',
      value: 'multicurrencyDetails'
    },
    {
      id: 9,
      itemName: 'BBT View',
      value: 'bbtView'
    },
    {
      id: 10,
      itemName: 'Show All',
      value: 'showAll'
    },
  ]

  return (
    <div>

      <div className='container-fluid dashboard-cont'>

        <div
          style={{ display: "flex", justifyContent: "space-between", alignItems: 'center'}}
        >
          <div className="dashboard__searchSection d-flex justify-content-space align-items-center" style={{ paddingTop: '10px', paddingBottom: '20px' }}>
            <div className="mr-3 dashboard__labelText">Time Period</div>
            <div className="dashboard__timePeriod">
              <img
                className="dashboard__calenderImg"
                src='images/calender.png'
                alt='calender-icon'
              />
              <DatePicker
                selected={startDate}
                onChange={date => {
                  setStartDate(date);
                }}
                dateFormat="dd-MMM-yyyy"
                className="dashboard__datepicker"
              />
            </div>

            <div className="dashboard__timePeriod" style={{ marginLeft: '13px' }}>
              <img
                className="dashboard__calenderImg"
                src='images/calender.png'
                alt='calender-icon'
              />
              <DatePicker
                selected={endDate}
                onChange={date => {
                  setEndDate(date, 'MM/dd/yyyy');
                }}
                dateFormat="dd-MMM-yyyy"
                minDate={startDate}
              />
            </div>
            <div className="mx-3 dashboard__labelText">And</div>
            <Form.Control as='select' onChange={(e) => setSelectInput(e.target.value)} className="dashboard__selectSearch">
              <option value="ITILITE_website_Trip_ID" selected >Trip ID (bulk)</option>
              <option value="SL_NO">SL No. (bulk)</option>
              <option value="G_SL_NO">G SL No. (bulk)</option>
              <option value="TTR_SL_NO">TTR SL No. (bulk)</option>
              <option value="Company">Company</option>
              <option value="Options">Options</option>
              <option value="Ticket_No">Ticket No (bulk)</option>
            </Form.Control>
            <FormControl onChange={(e) => setSearchInput(e.target.value)} className="dashbaord__searchInput" type='text' placeholder='Enter the value' />
            <Button className="mx-0" onClick={submitSearchValue} id='search-btn'>
              <img src='images/search-icon.png' alt='Search Icon' />
            </Button>
          </div>
          <div className="dashboard__viewSection">
            <Form.Row className='d-flex align-items-center' style={{ marginBottom: '20px', marginTop: '10px' }}>
              <div className="mr-2">
                <Form.Label className="dashboard__labelText" htmlFor='selectView'>Select View</Form.Label>
              </div>

              <Col className='select-style'>
                <Form.Control
                  as='select'
                  onClick={e => handleSelectView(e)}
                  className='ml-auto custom-column dashboard__selectView d-none'
                >
                  <option value="default">Default</option>
                  <option value="bookingDetails">Booking Details</option>
                  <option value="transactionDetails">Transaction Details</option>
                  <option value="vendorDetails">Vendor Details</option>
                  <option value="paybycardDetails">Paybycard Details</option>
                  <option value="invoiceDetails">Invoice Details</option>
                  <option value="gstDetails">GST Details</option>
                  <option value="multicurrencyDetails">Multicurrency Details</option>
                  <option value="bbtView">BBT View</option>
                  <option value="others">Others</option>
                  <option value="showAll">Show All</option>
                  <option
                    // onClick={handleCustomViewModalOpen}
                    className='custom-view'
                    value='customView'
                  >Custom View</option>
                </Form.Control>

                <Dropdown selectView={handleSelectView} items={selectViewOptions} />   
              </Col>
            </Form.Row>

          </div>

        </div>
        {/* for !st release */}
        <div
          className='d-none'
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div className="dashboard__leftSearch">
            <Form.Group as={Row} className="" style={{ marginBottom: '20px' }} controlId="formPlaintextEmail">
              <Form.Label column sm={2} className="px-0 dashboard__labelText">
                Time Period
                </Form.Label>
              <div className="dashboard__timePeriod">
                <img
                  className="dashboard__calenderImg"
                  src='images/calender.png'
                  alt='calender-icon'
                />
                <DatePicker
                  selected={startDate}
                  onChange={date => {
                    setStartDate(date);
                  }}
                  dateFormat="dd-MMM-yyyy"
                  className="dashboard__datepicker"
                />
              </div>

              <div className="dashboard__timePeriod" style={{ marginLeft: '13px' }}>
                <img
                  className="dashboard__calenderImg"
                  src='images/calender.png'
                  alt='calender-icon'
                />
                <DatePicker
                  selected={endDate}
                  onChange={date => {
                    setEndDate(date, 'MM/dd/yyyy');
                  }}
                  dateFormat="dd-MMM-yyyy"
                  minDate={startDate}
                />
              </div>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail" className="">
              <Form.Label column sm={2} className="pl-0 dashboard__labelText">
                Search
                </Form.Label>
              <Col className="pl-0" md={4}>
                <Form.Control as='select' onChange={(e) => setSelectInput(e.target.value)} className="dashboard__selectSearch">
                  <option
                    value="ITILITE_website_Trip_ID"
                    selected
                  >
                    Trip ID
                    </option>
                  <option
                    value="SL_NO"
                  >
                    SL No.
                    </option>
                  <option value="G_SL_NO">G SL No.</option>
                  <option value="TTR_SL_NO">TTR SL No.</option>
                  <option
                    value="Company"
                  >
                    Company
                    </option>
                  <option
                    value="Options"
                  >
                    Options
                    </option>
                </Form.Control>
              </Col>

              <Col className="pl-0 mr-0" md={4}>
                <FormControl onChange={(e) => setSearchInput(e.target.value)} className="dashbaord__searchInput" type='text' placeholder='Enter the value' />
              </Col>

              <Col className="" md={2}>
                <Button className="mx-0" onClick={submitSearchValue} id='search-btn'>
                  <img src='images/search-icon.png' alt='Search Icon' />
                </Button>
              </Col>
            </Form.Group>


          </div>
          <div className="dashboard__rightSearch" style={{ width: "400px", textAlign: "right" }}>
            <Form.Row className='d-flex align-items-center invisible' style={{ marginBottom: '20px' }}>
              <Col>
                <Form.Label className="dashboard__labelText" htmlFor='selectView'>Select View</Form.Label>
              </Col>

              <Col className='select-style'>
                <Form.Control
                  as='select'
                  // onClick={e => handleSelectView(e)}
                  className='ml-auto custom-column dashboard__selectView'
                >
                  <option value="default">Default</option>
                  <option value="bookingDetails">Booking Details</option>
                  <option value="transactionDetails">Transaction Details</option>
                  <option value="vendorDetails">Vendor Details</option>
                  <option value="paybycardDetails">Paybycard Details</option>
                  <option value="invoiceDetails">Invoice Details</option>
                  <option value="gstDetails">GST Details</option>
                  <option value="multicurrencyDetails">Multicurrency Details</option>
                  <option value="bbtView">BBT View</option>
                  <option value="others">Others</option>
                  <option value="showAll">Show All</option>
                  <option
                    // onClick={handleCustomViewModalOpen}
                    className='custom-view'
                    value='customView'
                  >Custom View</option>
                </Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className='d-flex align-items-center' style={{ marginBottom: '20px' }}>
              <Col>
                <Form.Label className="dashboard__labelText" htmlFor='selectView'>Select View</Form.Label>
              </Col>

              <Col className='select-style'>
                <Form.Control
                  as='select'
                  onClick={e => handleSelectView(e)}
                  className='ml-auto custom-column dashboard__selectView'
                >
                  <option value="default">Default</option>
                  <option value="bookingDetails">Booking Details</option>
                  <option value="transactionDetails">Transaction Details</option>
                  <option value="vendorDetails">Vendor Details</option>
                  <option value="paybycardDetails">Paybycard Details</option>
                  <option value="invoiceDetails">Invoice Details</option>
                  <option value="gstDetails">GST Details</option>
                  <option value="multicurrencyDetails">Multicurrency Details</option>
                  <option value="bbtView">BBT View</option>
                  <option value="others">Others</option>
                  <option value="showAll">Show All</option>
                  <option
                    onClick={handleCustomViewModalOpen}
                    className='custom-view'
                    value='customView'
                  >Custom View</option>
                </Form.Control>
              </Col>
            </Form.Row>

            <Form.Row className="d-none">
              <Button
                onClick={handleAdvanceFilterModalOpen}
                id='btn-advance-filter'
                className='ml-auto mr-1'
              >
                <span>
                  <img
                    className='mr-2'
                    src='images/filter.png'
                    alt='Filter Icon'
                  />
                </span>
                  Advance Filters
                </Button>
            </Form.Row>
          </div>
        </div>

        {/* Table */}
        <div className='table-container'>
          {/* Table Start */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "70px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <div>
              <Form.Row>
                {/* show entries not releasing in V1 */}

                <Col className="d-flex align-items-center px-0 m-0">
                  <Form.Label
                    htmlFor=''
                    className="dashboard__showEntriesText d-none"
                    style={{ width: "110px", marginRight: "10px", marginTop: '5px' }}

                  >
                    Show Entries
                  </Form.Label>
                </Col>

                <Col className='d-none' style={{ display: 'none !important' }}>
                  <Form.Control as='select' className="dashboard__showEntries">
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Form.Control>
                </Col>
              </Form.Row>
            </div>
            <div>
              <CSVLink onClick={downloadCSV} headers={csvHeaders} filename={"finance_bbt_sheet.csv"} data={csvDownloadData}>
                <Button id='btn-download' className="pr-0">
                  <span>
                    <img className='mr-2' src='images/download.png' />
                  </span>
                  Download
                </Button>
              </CSVLink>
            </div>
          </div>

          {/* <Datatable1 /> */}

          {datatableRender(data, columns, dataLoading)}
        </div>

        {/* Pagination */}

        <CustomViewModal
          showCustomViewModal={showCustomViewModal}
          customViewModalClose={handleCustomViewModalClose}
        />

        <AdvanceFilterModal
          showAdvanceFilterModal={showAdvanceFilterModal}
          advanceFilterModalClose={handleAdvanceFilterModalClose}
          customValues={customValues}
          addMoreCustomValue={addMoreCustomValue}
          removeCustomValues={removeCustomValues}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  columns: state.tableData.columns,
  data: state.tableData.data,
  downloadData: state.tableData,
  tbleData: state.tableData,
  auth: state.auth,
})

const mapDispatchToProps = {
  tableData,
  basicSearch,
  selectViewAction
}

export default connect(
  mapStateToProps,
  { tableData, basicSearch, selectViewAction }
)(Dashboard);
