import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute(props) {
	if (!localStorage.token) {
		return <Redirect to='/' />
	}
	return <Route {...props} />
}

export default PrivateRoute;
