import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <div className="footer__left">
                <span>©  &nbsp; &nbsp;</span>
                <div className="footer__borderRight">ITILITE. All Rights Reserved</div>
                <div className="footer__borderRight">FAQ</div>
                <div>Privacy Policy</div>
            </div>
            <div className="footer__right">
                <div className="footer__borderRight">Contact : support@itilite.com </div>
                <span>+91 9513 0711 60</span>
            </div>
        </div>
    )
}

export default Footer;
