import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './UploadInvoices.css';
import Switch from './IndividualUploadInvoice/Switch';
import Footer from './Footer';
import Stepper from './Stepper';
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone';
import axios, { CancelToken, isCancel } from 'axios';
import { ProgressBar } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import swal from 'sweetalert';
import { apiUrl, httpsAgent } from '../../utils/apiUrl';

function UploadInvoices() {

    const [invoiceFile, setInvoiceFile] = useState([]);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [isUploadSuccess, setIsUploadSuccess] = useState(false)
    const [isUploadAccepted, setIsUploadAccepted] = useState(false)
    const [isUploadFailure, setIsUploadFailure] = useState(false);
    const [cancelBtnDisplay, setCancelBtnDisplay] = useState(false);
    const [isSwitchOn, setISwitchOn] = useState(true);

    const [isUploadingTemplate, setIsUploadingTemplate] = useState(false);
    const [isUploadTemplateSuccess, setIsUploadTemplateSuccess] = useState(false)
    const [isUploadTemplateFailure, setIsUploadTemplateFailure] = useState(false);
    const [templateFile, setTemplateFile] = useState([])

    const [mappingResults, setMappingResults] = useState({})
    const [mappingError, setMappingError] = useState([])
    const [mappingWithAllSuccessRows, setMappingWithAllSuccessRows] = useState(false)
    

    const [currentStep, setCurrentStep] = useState(1);

    const handleClick = (clickType) => {
        let newStep = currentStep;

        if(clickType === 'next') {
            newStep++
            setCancelBtnDisplay(false)
        } else {
            newStep--;
        }

        if(newStep > 0 && newStep <= 3) {
            setCurrentStep(newStep)
        }
    }

    const [responseMsg, setResponseMsg] = useState("")
    const [uploadMsg, setUploadMsg] = useState("Uploading...")

    // dropzone
    const pdfMaxSize = 104857600  // bytes 100mb
    const maxXlsSize = 104857600 / 2;
    const [uploadPercentage, setUploadPercentage] = useState(0)
    const cancelFileUpload = useRef(null);

    const { getRootProps, getInputProps, open, acceptedFiles, isDragActive } = useDropzone({
        // Disable click and keydown behavior
        noClick: true,
        noKeyboard: true,
        maxSize: pdfMaxSize,
        accept: ['.pdf', '.zip']
    });

    const cancelUpload = () => {
        if(cancelFileUpload.current) {
            cancelFileUpload.current('User has canceled the file upload')
            setUploadingFile(false)
        }

        if (isUploadFailure) {
            setUploadingFile(false)
            setIsUploadFailure(false)
            setCancelBtnDisplay(false)
        }

        if (isUploadTemplateFailure) {
            setIsUploadingTemplate(false)
            setIsUploadTemplateFailure(false)
            setCancelBtnDisplay(false)
        }
    }

    const handleInvoiceUpload = (acceptedFiles) => {
        setUploadPercentage(0)
        setUploadMsg("Uploading...")

        let fileSizeAllowed = false
        try{
            let fileSize = acceptedFiles[0].size
            fileSizeAllowed = (fileSize > pdfMaxSize) ? false : true
        }catch(error){
            fileSizeAllowed = false
        }

        if(fileSizeAllowed){
            setInvoiceFile(acceptedFiles)
            setUploadingFile(true)
            setCancelBtnDisplay(true)
            let data = new FormData();
            data.append('invoice_file', acceptedFiles[0])
    
            const options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total} = progressEvent;
                    let percent = Math.floor(loaded * 100) / total;
    
                    if (percent <= 100) {
                        setUploadPercentage(percent)
                    }
                    if(percent === 100){
                        setUploadMsg("Processing...")
                    }
                },
                cancelToken: new CancelToken(cancel => cancelFileUpload.current = cancel)
            }

            axios.post(`${apiUrl.INVOICE_SUBMIT}?pdf_process=${isSwitchOn}`, data, options,{ httpsAgent:httpsAgent, timeout:1200 }).then(res => {
                setUploadPercentage(100)
                setTimeout(() => {
                    setUploadPercentage(0)
                }, 1000)
                setUploadingFile(false)
                setCancelBtnDisplay(false)
                if (res.status === 202){
                    setResponseMsg(res.data.message)
                    setIsUploadAccepted(true)
                }else{
                    setResponseMsg(res.data.message)
                    setIsUploadSuccess(true)
                }
            }).catch(err => {
                console.log(err.response)
                try {
                    if (err.response.status >= 400 && err.response.status <= 404){
                        setResponseMsg("Upload failed - "+err.response.data.message)
                    }else{
                        setResponseMsg("Upload failed.")
                    }
                } catch (error) {
                    setResponseMsg("Upload failed.")
                }
                if(isCancel(err)) {
                    alert(err.message)
                }
                setUploadingFile(false)
                setIsUploadFailure(true)
            });

        }else{
            swal('File size exceed the max limit')
        }
    }

    const handleTemplateUpload = (acceptedFiles) => {
        setUploadPercentage(0)
        setUploadMsg("Uploading...")
        setTemplateFile(acceptedFiles)
        setIsUploadingTemplate(true)
        setCancelBtnDisplay(true)
        let formData = new FormData();
        formData.append('mapping_sheet', acceptedFiles[0])

        const options = {
            onUploadProgress: (progressEvent) => {
                const { loaded, total} = progressEvent;
                let percent = Math.floor(loaded * 100) / total;

                if (percent <= 100) {
                    setUploadPercentage(percent)
                }
                if(percent === 100){
                    setUploadMsg("Processing...")
                }
            },
            cancelToken: new CancelToken(cancel => cancelFileUpload.current = cancel)
        }
        axios.post(`${apiUrl.INVOICE_MAPPING_SHEET_URL}`, formData, options, { httpsAgent:httpsAgent, timeout:1200 }).then(res => {
            setUploadPercentage(100)
            setTimeout(() => {
                setUploadPercentage(0)
            }, 1000)
            setIsUploadingTemplate(false)
            setIsUploadTemplateSuccess(true)
            setCancelBtnDisplay(false)

            setMappingWithAllSuccessRows(true)
            
        }).catch(err => {
            console.log(err.response)
            if(isCancel(err)) {
                alert(err.message)
            }
            setIsUploadingTemplate(false)
            setIsUploadTemplateFailure(true)
        })
    }

    const files = invoiceFile.map(file => {
        if(isUploadSuccess || isUploadFailure || isUploadAccepted) {
            return file.path
        }
        return (
            <li key={file.path} className="uploadInvoices__list">
                <span>{file.path}</span>
                <div className="uploadInvoices__percentage">
                    <span>{uploadPercentage.toFixed(0)}%</span>
                    <span className="d-none">&nbsp; • &nbsp;</span>
                    <span className="d-none">3 seconds left</span>
                </div>
            </li>
        )
    });

    const templateFiles = templateFile.map(file => {
        if(isUploadSuccess || isUploadFailure || isUploadAccepted) {
            return file.path
        }
        return (
            <li key={file.path} className="uploadInvoices__list">
                <span>{file.path}</span>
                <div className="uploadInvoices__percentage">
                    <span>{uploadPercentage.toFixed(0)}%</span>
                    <span className="d-none">&nbsp; • &nbsp;</span>
                    <span className="d-none">3 seconds left</span>
                </div>
            </li>
        )
    })

    const handleNextClick = () => {
        handleClick('next');

        if (currentStep === 3) {
            setCurrentStep(1)
        }
    }

    const handleTemplateRetry = () => {
        setIsUploadingTemplate(true)
        setIsUploadTemplateSuccess(false)
        setIsUploadTemplateFailure(false)
        handleTemplateUpload(templateFile);
    }

    const handleInvoiceRetry = () => {
        setUploadingFile(true)
        setIsUploadFailure(false)
        setIsUploadSuccess(false)
        setIsUploadAccepted(false)

        handleInvoiceUpload(invoiceFile);
    }

    const handleUploadAgain = (type) => {
        if (type === "invoice") {
            setIsUploadSuccess(false)
            setIsUploadAccepted(false)
            setIsUploadFailure(false)
            setUploadingFile(false)
        } else {
            setIsUploadTemplateSuccess(false)
            setIsUploadingTemplate(false)
            setIsUploadTemplateFailure(false)
        }
    }

    const renderNextButton = () => {
        if (currentStep === 1) {
            return (<button onClick={handleNextClick} className='uploadInvoices__next active'>
                    Next
                    </button> )
        } else if (currentStep === 2) {
            return (
                isUploadTemplateSuccess ? <button onClick={handleNextClick} className='uploadInvoices__next active'>
                    Next
                </button> :<button  onClick={handleNextClick} disabled className='uploadInvoices__next'>
                    Next
                </button>
            )
        } else if (currentStep === 3) {
            return  <button onClick={() => {
                        setCurrentStep(1);
                        setIsUploadFailure(false);
                        setIsUploadSuccess(false);
                        setIsUploadAccepted(false);
                        setUploadingFile(false);
                        setIsUploadTemplateFailure(false);
                        setIsUploadTemplateSuccess(false);
                        setIsUploadingTemplate(false);
                        }} className='uploadInvoices__next active'>
                    Finish
                </button>
        }
    }

    const stepArray = ["Upload Invoices", "Upload the mapping sheet", "Status"]

    return (
        <div>
            {/* <Navbar /> */}
            <div className="uploadInvoices">
                <div className="uploadInvoices__container">
                    <div className="stepper-container-horizontal">
                        <Stepper steps={stepArray} currentStepNumber={currentStep} />
                    </div>
                    <div className="uploadInvoives__horizontalLine"></div>

                    {/* ----------step 1 upload invoces---------- */}
                    {currentStep === 1 && (
                        <React.Fragment>
                            <div className="uploadInvoives__heading">
                                <img src="images/info_icon_tiny.png" alt="info" title="ON: Read the PDF contents to identify invoice number and split the pdf for each invoice. &#13;OFF: Stores the PDF by file name."/>&nbsp; Upload invoice pdf.</div>
                            <div className="uploadInvoives__heading d-flex align-items-center ">
                                <label style={{marginRight: '10px'}}>Read & Split pdf</label>
                                <Switch
                                    isOn={isSwitchOn}
                                    onColor="#EB5C24"
                                    handleToggle={() => {
                                        setISwitchOn(!isSwitchOn); 
                                    }}
                                />
                            </div>

                            {/* -----------Render Initial---------- */}
                            {!uploadingFile && !isUploadFailure && !isUploadSuccess && !isUploadAccepted && (
                                <Dropzone onDrop={handleInvoiceUpload} accept={['.pdf', '.zip']} maxSize={pdfMaxSize} onClick={false} noKeyboard={true}>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <div {...getRootProps({className: isDragActive ? "dropzone uploadInvoices__uploadArea drag" : "dropzone uploadInvoices__uploadArea"})}>
                                            <input {...getInputProps()} />
                                            <div className="uploadInvoices__uploadingSection">
                                                <div className="uploadInvoices__fileSize">Allowed file - ZIP : 100 MB | PDF : 3 MB</div>
                                                <img src="images/upload.png" alt="upload icon" />
                                                <div className="uploadInvoices__dragDropText">Drag and drop the file here</div>
                                                <div className="uploadInvoices__orText">or</div>
                                                <button type="button" onClick={open} className="uploadInvoices__uploadBtn">Select File</button>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            )}

                            {/*--------Render while uploading--------- */}
                            {uploadingFile && !isUploadFailure && !isUploadSuccess && !isUploadAccepted && (
                                <div {...getRootProps({className: 'dropzone uploadInvoices__uploadArea'})}>
                                    <input {...getInputProps()} />
                                    <div className="uploadInvoices__progressUpload">
                                        <div className="uploadingInvoices__uploadingText">{uploadMsg}</div>
                                        <div className="uploadInvoices__showingProgress">{files}</div>
                                        { uploadPercentage > 0 && <ProgressBar now={uploadPercentage} />}
                                        <div className="uploadInvoices__doNotRefresh">Please DO NOT close or refresh this page while the file is uploading</div>
                                    </div>
                                </div>
                            )}

                            {/*--------Render when uploading successful--------- */}
                            {!uploadingFile && !isUploadFailure && !isUploadAccepted && isUploadSuccess && (
                                <div {...getRootProps({className: `dropzone ${isUploadSuccess ? 'uploadInvoices__uploadArea success' : ''}`})}>
                                    <div className="uploadInvoices__progressUpload">
                                        <div className="uploadingInvoices__uploadedText">{responseMsg}</div>
                                        <div className="uploadInvoices__showingProgress d-flex justify-content-between align-items-center pl-3">
                                            <span><img className="pr-2" src="images/successfully-uploaded.png" alt="succeful icon" /> {files} </span>
                                            <button onClick={() => handleUploadAgain('invoice')} className="uploadInvoices__retry">Upload Again</button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/*--------Render when upload accepted--------- */}
                            {!uploadingFile && !isUploadFailure && !isUploadSuccess && isUploadAccepted && (
                                <div {...getRootProps({className: `dropzone ${isUploadAccepted ? 'uploadInvoices__uploadArea success' : ''}`})}>
                                    <div className="uploadInvoices__progressUpload">
                                        <div className="uploadingInvoices__uploadedText accepted">{responseMsg}</div>
                                        <div className="uploadInvoices__showingProgress d-flex justify-content-between align-items-center pl-3">
                                            <span><img className="pr-2" src="images/successfully-uploaded.png" alt="succeful icon" /> {files} </span>
                                            <button onClick={() => handleUploadAgain('invoice')} className="uploadInvoices__retry">Upload Again</button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/*--------Render when uploading fail--------- */}
                            {!uploadingFile && isUploadFailure && !isUploadSuccess  && !isUploadAccepted && (
                                <div {...getRootProps({className: `dropzone ${!isUploadSuccess && !isUploadAccepted ? 'uploadInvoices__uploadArea fail' : ''}`})}>
                                    <div className="uploadInvoices__progressUpload">
                                        <div className="uploadingInvoices__uploadedText fail">{responseMsg}</div>
                                        <div className="uploadInvoices__showingProgress d-flex justify-content-between align-items-center pl-3">
                                            <span><img className="pr-2" src="images/upload-failed.png" alt="failed icon" /> {files} </span>
                                            <button onClick={() => handleInvoiceRetry()} className="uploadInvoices__retry"><img className="px-1" src="images/retry.png" alt="retry icon" /> Retry</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>  
                    )}

                    {/* ----------step 2 upload & download template---------- */}
                    {currentStep === 2 && (
                        <React.Fragment>
                            <a
                                href="https://itilite-images.s3.amazonaws.com/Invoice_mapping_template.xlsx"
                                className={`${isUploadingTemplate ? 'uploadInvoices__downloadTemplate active' : 'uploadInvoices__downloadTemplate'}`}
                                target="_blank" 
                                download
                            >
                                Download Template
                            </a>
                            <div className="uploadInvoives__templateHeading">Download the mapping template</div>
                            <div className="uploadInvoives__horizontalLine"></div>
                            <div className="uploadInvoives__heading">Upload mapping template.</div>

                            {/* --------initial render----------- */}
                            {!isUploadingTemplate && !isUploadTemplateSuccess && !isUploadTemplateFailure && (
                                <Dropzone onDrop={handleTemplateUpload} accept={['.xlsx', '.xls']} maxSize={maxXlsSize}>
                                    {({getRootProps, getInputProps, isDragActive}) => (
                                        <div {...getRootProps({className: isDragActive ? 'dropzone uploadInvoices__uploadArea drag' : 'dropzone uploadInvoices__uploadArea'})}>
                                            <input {...getInputProps()} />
                                            <div className="uploadInvoices__uploadingSection">
                                                <div className="uploadInvoices__fileSize">File Format: XLSX / XLS</div>
                                                <img src="images/upload.png" alt="upload icon" />
                                                <div className="uploadInvoices__dragDropText">Drag and drop the file here</div>
                                                <div className="uploadInvoices__orText">or</div>
                                                <button type="button" onClick={open} className="uploadInvoices__uploadBtn">Select File</button>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            )}

                            {/* ---------while uploading the template-------- */}
                            {isUploadingTemplate && !isUploadTemplateSuccess && !isUploadTemplateFailure && templateFile && (
                                <div {...getRootProps({className: 'dropzone uploadInvoices__uploadArea'})}>
                                    <input {...getInputProps()} />
                                    <div className="uploadInvoices__progressUpload">
                                        <div className="uploadingInvoices__uploadingText">{uploadMsg}</div>
                                        <div className="uploadInvoices__showingProgress">
                                            {templateFiles}
                                        </div>
                                        { uploadPercentage > 0 && <ProgressBar now={uploadPercentage} />}
                                        <div className="uploadInvoices__doNotRefresh">Please DO NOT close or refresh this page while the file is uploading</div>
                                    </div>
                                </div>
                            )}

                            {/* ---------sucessfully uploaded template-------- */}
                            {!isUploadingTemplate && !isUploadTemplateFailure && isUploadTemplateSuccess && templateFile && (
                                <div {...getRootProps({className: `dropzone ${isUploadTemplateSuccess ? 'uploadInvoices__uploadArea success' : ''}`})}>
                                    <div className="uploadInvoices__progressUpload">
                                        <div className="uploadingInvoices__uploadedText">File Uploaded.</div>
                                        <div className="uploadInvoices__showingProgress d-flex justify-content-between align-items-center pl-3">                       
                                            <span><img className="pr-2" src="images/successfully-uploaded.png" alt="uploaded icon" /> {templateFile[0].name} </span>
                                            <button onClick={() => handleUploadAgain('template')} className="uploadInvoices__retry">Upload Again</button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* ---------upload failed template-------- */}
                            {!isUploadingTemplate && isUploadTemplateFailure && !isUploadTemplateSuccess && templateFile && (
                                <div {...getRootProps({className: `dropzone ${!isUploadTemplateSuccess ? 'uploadInvoices__uploadArea fail' : ''}`})}>
                                    <div className="uploadInvoices__progressUpload">
                                        <div className="uploadingInvoices__uploadedText fail">Upload Failed</div>
                                        <div className="uploadInvoices__showingProgress d-flex justify-content-between align-items-center pl-3">
                                            <span><img className="pr-2" src="images/upload-failed.png" alt="failed icon" /> {templateFile[0].name} </span>
                                            <button onClick={() => handleTemplateRetry()} className="uploadInvoices__retry"><img className="px-1" src="images/retry.png" alt="retry icon" /> Retry</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )}

                    {/* ----------step 3 mapping invoice and template---------- */}
                    {currentStep === 3 && (
                        <React.Fragment>
                            {mappingWithAllSuccessRows && (
                                    <React.Fragment>
                                        <h3></h3>
                                        You will receive mapping status over mail.
                                    </React.Fragment>
                                )}

                        </React.Fragment>
                    )}
                    
                </div>

                <div className="uploadInvoices__btns">
                    {cancelBtnDisplay && <button onClick={() => cancelUpload()} className="uploadInvoices__cancle">Cancel</button>}
                    <div></div>
                    <div>
                        {currentStep > 1 && 
                            <button 
                                onClick={() => handleClick()}
                                className={`${mappingResults && mappingResults.result && mappingResults.result.bbt_sl_missing.length === 0 &&
                                    mappingResults.result.invoice_not_uploaded_yet.length === 0 ? 'd-none' : 'uploadInvoices__prev'}`}
                            >
                                Previous
                            </button>
                        }
                        {renderNextButton()}                        
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default UploadInvoices;
