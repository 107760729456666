import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './components/login/Login';
import Dashboard from './components/dashboard/Dashboard';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';



// Redux
import { Provider } from 'react-redux';
import store from './store';
import PrivateRoute from './components/routing/PrivateRoute';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth';
import UploadInvoices from './components/uploadInvoices/UploadInvoices';
import ViewInvoices from './components/uploadInvoices/IndividualUploadInvoice/ViewInvoices';
import DeleteInvoice from './components/uploadInvoices/IndividualUploadInvoice/DeleteInvoice';
import IndividualInvoiceUpload from './components/uploadInvoices/IndividualUploadInvoice/IndividualInvoice';
import Navbar from './components/dashboard/Navbar';
import Sidebar from './components/dashboard/Sidebar';
import Dropdown from './components/common/Dropdown';
import ToastNotification from './components/common/ToastNotification';
import ProcessTacker from './components/process_tracker/ProcessTacker';
import MappingTracker from './components/mapping_tracker/MappingTracker';
import ZohoInput from './components/zoho_input/ZohoInput';
import TTR_REPORTDownload from './components/download-ttr/TTR_REPORTDownload';
import LatestCompanyBalance from './components/latest_balance/LatestCompanyBalance';
import zohoInvoices from './components/zoho_invoices/ZohoInvoices';
import zohoAutoGeneratedInvoices from './components/zoho_invoices/ZohoAutoGeneratedInvoices';
import InvoiceGeneration from './components/invoice_generate/InvoiceGeneration';
import { apiUrl } from './utils/apiUrl';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { USER_LOAD, AUTH_ERROR, LOGOUT } from './actions/types';
import PublicRoute from './components/routing/PublicRoute';
import swal from 'sweetalert';


function App(props) {
	const dispatch = useDispatch();
	const [isInitCheck, setIsInitCheck] = useState(false);

	useEffect(() => {
		if (!isInitCheck) {
			(async () => {
				setIsInitCheck(true)
				try {
					let config = { headers: { "Authorization": localStorage.token } }
					const res = await axios.get(apiUrl.LOAD_USER, config);

					dispatch({
						type: USER_LOAD,
						payload: res.data
					})

					if (res.status === 200) {
						dispatch({
							type: USER_LOAD,
							payload: res.data
						})
						props.history.push('/dashboard')
					}

				} catch (error) {
					dispatch({
						type: LOGOUT
					})
				}
			})();
		}
		if (localStorage.token) {
			setAuthToken(localStorage.token)
		}


	}, [isInitCheck, dispatch])

	const [sidebarOpen, setSidebarOpen] = useState(false);

	const sidebarToggleHandler = (x) => {
		setSidebarOpen(x)
	}

	return (
		<div className="app">
			{/* <Redirect to="/dashboard" /> */}
			{localStorage.token && <Sidebar
				show={sidebarOpen}
				toggle={sidebarToggleHandler}
			/>}
			{localStorage.token && <Navbar
				toggle={sidebarToggleHandler}
			/>}
			<Switch>
				<PublicRoute exact path="/" exact component={Login} />
				{isInitCheck && <PrivateRoute exact path="/dashboard" component={Dashboard} />}
				{isInitCheck && <PrivateRoute exact path="/uploadInvoices" component={UploadInvoices} />}
				{isInitCheck && <PrivateRoute exact path="/viewInvoices" component={ViewInvoices} />}
				{isInitCheck && <PrivateRoute exact path="/deleteInvoice" component={DeleteInvoice} />}
				{isInitCheck && <PrivateRoute exact path="/uploadInvoice" component={IndividualInvoiceUpload} />}
				{isInitCheck && <PrivateRoute exact path="/processTacker" component={ProcessTacker} />}
				{isInitCheck && <PrivateRoute exact path="/mappingTracker" component={MappingTracker} />}
				{isInitCheck && <PrivateRoute exact path="/zohoInput" component={ZohoInput} />}
				{isInitCheck && <PrivateRoute exact path="/zohoInvoices" component={zohoInvoices} />}
				{isInitCheck && <PrivateRoute exact path="/zohoAutoGeneratedInvoices" component={zohoAutoGeneratedInvoices} />}
				{isInitCheck && <PrivateRoute exact path="/InvoiceGeneration" component={InvoiceGeneration} />}
				{isInitCheck && <PrivateRoute exact path="/ttrDownload" component={TTR_REPORTDownload} />}
				{isInitCheck && <PrivateRoute exact path="/latestBalance" component={LatestCompanyBalance} />}
			</Switch>
		</div>
	);

}

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(App);
