import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import { Row, Col, Button, Form, FormControl } from "react-bootstrap";
import Footer from "../uploadInvoices/Footer";
import { useDispatch, useSelector } from "react-redux";
import "./TTR_REPORTDownload.css";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import moment from "moment";
import { fetchCompany } from "../../actions/ViewInvoices/invoices";

import axios from "axios";
import { apiUrl } from "../../utils/apiUrl";
import swal from "sweetalert";

function TTR_REPORTDownload() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [model, setModel] = useState("agency");
  const [regularInput, setRegularInput] = useState(true);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [compCurrency, SetCompCurrency] = useState("inr_companies");
  const [dataLoading, setDataLoading] = useState(false);
  const dispatch = useDispatch();
  const { companies } = useSelector((state) => state.viewInvoices);

  const handleDownload = (event) => {
    event.preventDefault();
    if (!startDate || !endDate) {
      alert("Please select both start and end dates.");
      return;
    }
    if (regularInput){
			let opt = []
			for(let opt_row of selectedOptions){
				let select_val = opt_row.value
				if (select_val !== "*"){
					opt.push(select_val);
        }
			}
      const from_date = format(startDate, "yyyy-MM-dd");
      const to_date = format(endDate, "yyyy-MM-dd");
      const diffInDays = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24));
      if((diffInDays)< 32){
        if(opt.length > 0){
          fetchData(opt.toString(),from_date,to_date);
          setDataLoading(true);
        }
        else{
          fetchData(null,from_date,to_date);
          setDataLoading(true);
        }
      }
      

  }
}
  useEffect(() => {
    if (companies.length === 0) {
      dispatch(fetchCompany("inr"));
    }
    if (companies) {
      let temp = companies.map((com) => {
        return { label: com, value: com };
      });
      setCompanyOptions(temp);
    }
  }, [companies, dispatch]);

  function onChange(value, event) {
    if (event.action === "select-option" && event.option.value === "*") {
      this.setState(this.options);
    } else if (event.action === "deselect-option" && event.option.value === "*") {
      this.setState([]);
    } else if (event.action === "deselect-option") {
      this.setState(value.filter((o) => o.value !== "*"));
    } else if (value.length === this.options.length - 1) {
      this.setState(this.options);
    } else {
      this.setState(value);
    }
  }
  const handleStartDateChange = (date) => {
    setStartDate(date);
    setEndDate(null); // Reset end date when start date changes
  };

  const handleEndDateChange = (date) => {
    const diffInDays = Math.ceil((date - startDate) / (1000 * 60 * 60 * 24));
    if (diffInDays > 31) {
      alert("Date range should be within 31 days from the start date.");
      return;
    }    
    setEndDate(date);
  };

  async function fetchData(selectedCompanies, from, to) {
    var stmt = null;
		if (selectedCompanies){
			stmt = `${apiUrl.TTR_REPORT}?companies=${selectedCompanies}&from_date=${from}&to_date=${to}`
		}else{
			stmt = `${apiUrl.TTR_REPORT}?from_date=${from}&to_date=${to}`
		}
		
		let config = {headers: {"Authorization": localStorage.token}}
		try {
			const response = await axios.get(stmt, config);    
  		setDataLoading(false);
			if (response.data.results.statusCode === 200) {
				if (response.data.results.isBase64Encoded){
          const fileName = `ttr_report_${from}_to_${to}.zip`;
					downloadEncodedFile(response.data.results.fileEncode,fileName);
				}else{
					console.log("wrong file foramt response");
					swal("Can't download file.");
				}
			}
      if (response.data.results.statusCode === 500) {
        console.log("No data found for given data range");
        swal("No data found for given data range");
      }

		} catch(error) {
			console.log(error);
			setDataLoading(false);
      try{
				if (error.response.status === 400){
					swal(error.response.data.message);
				}else if (error.response.status === 404){
					swal("No data found");
				}else if (error.response.status === 500){
					swal("Error occoured while downloading data");
				}
			}catch(try_err){
				console.log(error);
				swal("Error in download file.");
			}
		}
	}

  const downloadEncodedFile = (encodedData, file_name) => {
    const blob = b64toBlob(encodedData, "application/zip");
    const blobUrl = URL.createObjectURL(blob);

    let a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = blobUrl;
    a.download = file_name;
    a.click();
    window.URL.revokeObjectURL(blobUrl);
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

	const onChangeCurrency = (event) => {
		let radio_value = event.target.value
		SetCompCurrency(radio_value)
		if (radio_value === "inr_companies"){
			dispatch(fetchCompany("inr"))
			setSelectedOptions([])
			setModel(model.replace('_international',''))
		}else{
			dispatch(fetchCompany("non-inr"))
			setSelectedOptions([])
			setModel(model+"_international")
		}	
    }


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted black",
      width: 400,
    }),
  };  
  

  return (
    <div className="viewInvoices">
      <div className="viewInvoices__container">
        <h4 className="viewInvoices__title">TTR Report</h4>
        <div className="viewInvoices__horizontalLine"></div>

        <Row>
          <Col md={6}>
            {
              <div className="currencyModel__radioContainer">
                <div className="d-flex">
                  <input
                    onChange={onChangeCurrency}
                    defaultChecked
                    type="radio"
                    id="inr_companies"
                    name="currency"
                    value="inr_companies"
                  />
                  <label
                    className="individualInvoice__labelText"
                    for="inr_companies"
                  >
                    INR Companies
                  </label>
                </div>
                <div className="d-flex">
                  <input
                    onChange={onChangeCurrency}
                    type="radio"
                    id="non_inr_companies"
                    name="currency"
                    value="non_inr_companies"
                  />
                  <label
                    className="individualInvoice__labelText"
                    for="non_inr_companies"
                  >
                    Non-INR Companies
                  </label>
                </div>
              </div>
            }
          </Col>
        </Row>
        <div className="viewInvoices__horizontalLine"></div>
        <Row>
          {regularInput && (
            <Form.Group as={Row} className="" controlId="formPlaintextEmail">
              <Col>
                <div className="dashboard__timePeriod">
                  <img
                    className="dashboard__calenderImg"
                    src="images/calender.png"
                    alt="calender-icon"
                  />
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="dd-MMM-yyyy"
                    className="dashboard__datepicker"
                    placeholderText="Start Date"
                    maxDate={new Date()}                    
                  />
                </div>
              </Col>
              <Col>
                <div
                  className="dashboard__timePeriod"
                  style={{ marginLeft: "13px" }}
                >
                  <img
                    className="dashboard__calenderImg"
                    src="images/calender.png"
                    alt="calender-icon"
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="dd-MMM-yyyy"
                    minDate={startDate}
                    maxDate={
                      startDate &&
                      new Date(
                        Math.min(startDate.getTime() + 31 * 24 * 60 * 60 * 1000, new Date().setDate(new Date().getDate() + 5))
                      )
                    }
                    placeholderText="End Date"
                    disabled={!startDate} // Disable end date until start date is selected
                  />
                </div>
              </Col>
              <div className="searchttrSelection">
                <Col>
                  {regularInput && (
                    <ReactMultiSelectCheckboxes
                      options={[
                        { label: "All", value: "*" },
                        ...companyOptions,
                      ]}
                      placeholderButtonLabel="Select Companies"
                      value={selectedOptions}
                      onChange={onChange}
                      setState={setSelectedOptions}
                      styles={customStyles}
                    />
                  )}
                </Col>
              </div>

              <Col>
                <Button
                  className="mx-0" id="btn-download" onClick={handleDownload} >
                  <img src="images/download.png" alt="Search Icon" />
                </Button>
              </Col>
            </Form.Group>
          )}
        </Row>
      </div>
    </div>
  );
}

export default TTR_REPORTDownload;
