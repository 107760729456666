import {
    FETCHING_INVOICES_TABLE_REQUEST,
    FETCHING_INVOICES_TABLE_SUCCESS,
    FETCHING_INVOICES_TABLE_FAIL,
    DELETE_INVOICE_REQUEST,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAIL,
    SAVE_DELETE_INVOICE,
    SAVE_COMPANY_N_DATERANGE,
    FILTER_DELETE_INVOICES,
    SET_COMPANY_SELECTED,
    GET_COLUMNS,
    SAVE_INDIVIDUAL_UPLOADED_INVOICE,
    SET_CURRENT_PAGE,
    SET_PER_PAGE,
    FETCH_COMPANY_SUCCESS,
    FETCH_COMPANY_FAIL,
    FETCH_TRANS_PLUS_SF_CLIENTS
} from './../actions/ViewInvoices/types';

const initialState = {
    data: [],
    totalRows: 0,
    columns: [
        {name: "Sl No", selector: "SL_NO", sortable: true, maxWidth: '120px'},
        {name: "G Sl No", selector: "gseqnumber", sortable: true, maxWidth: '110px'},
        {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true, maxWidth: '110px'},
        {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true, maxWidth: '100px'},
        {name: "Traveler Name", selector: "Traveler_name", sortable: true},
        {name: "Transaction Type", selector: "Transaction_Type", sortable: true},
        {name: "Transaction Date", selector: "Date_of_Transaction", sortable: true, maxWidth: '110px'},
        {name: "Trip Details", selector: "Trip_Details", sortable: true},
        {name: "Transaction Invoice No.", selector: "transaction_invoice", sortable: true},
        {name: "Service Fee Invoice No.", selector: "service_fee_invoice", sortable: true},
        {name: "Vendor Invoice No.", selector: "vendor_invoice", sortable: true},
    ],
    invoiceViews: {
        default: [
            {name: "Sl NO", selector: "SL_NO", sortable: true, maxWidth: '120px'},
            {name: "G Sl No", selector: "gseqnumber", sortable: true, maxWidth: '110px'},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true, maxWidth: '110px'},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true, maxWidth: '100px'},
            {name: "Traveler Name", selector: "Traveler_name", sortable: true},
            {name: "Transaction Type", selector: "Transaction_Type", sortable: true},
            {name: "Transaction Date", selector: "Date_of_Transaction", sortable: true, maxWidth: '110px'},
            {name: "Trip Details", selector: "Trip_Details", sortable: true},
            {name: "Transaction Invoice No.", selector: "transaction_invoice", sortable: true, invoice_group: "invoice_group1"},
            {name: "Service Fee Invoice No.", selector: "service_fee_invoice", sortable: true, invoice_group: "invoice_group1"},
            {name: "Vendor Invoice No.", selector: "vendor_invoice", sortable: true, invoice_group: "invoice_group1"},
            {name: "Unsigned Invoice No.", selector: "trans_plus_sf_invoice", sortable: true, invoice_group: "invoice_group2"},
            {name: "Signed Invoice No.", selector: "signed_invoice", sortable: true, invoice_group: "invoice_group2"},
            {name: "Invoice No.", selector: "invoice", sortable: true, invoice_group: "invoice_group3"},

        ],
        showAll: [
            // Default
            {name: "Sl NO", selector: "SL_NO", sortable: true, maxWidth: '120px'},
            {name: "G Sl No", selector: "gseqnumber", sortable: true, maxWidth: '110px'},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true, maxWidth: '110px'},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true, maxWidth: '100px'},
            {name: "Traveler Name", selector: "Traveler_name", sortable: true},
            {name: "Transaction Type", selector: "Transaction_Type", sortable: true},
            {name: "Transaction Date", selector: "Date_of_Transaction", sortable: true, maxWidth: '110px'},
            // Booking Details
            {name: "Depature Date", selector: "Departure_Date", sortable: true},
            {name: "Depature Time", selector: "Departure_Time", sortable: true},
            {name: "Arrival Date", selector: "Arrival_Date", sortable: true},
            {name: "Arrival Time", selector: "Arrival_Time", sortable: true},
            {name: "Type Of Travel", selector: "Type_Of_Travel", sortable: true},
            {name: "From", selector: "From", sortable: true},
            {name: "To", selector: "To", sortable: true},
            {name: "Oneway / Return", selector: "Oneway___Return", sortable: true},
            {name: "Airline", selector: "Airline", sortable: true},
            {name: "Flight No", selector: "Flight_No_", sortable: true},
            {name: "Class of Travel", selector: "Class_of_Travel", sortable: true},
            {name: "Class", selector: "Class", sortable: true},
            {name: "Riya PNR", selector: "Riya_PNR", sortable: true},
            {name: "Airline PNR", selector: "Airline_PNR", sortable: true},
            {name: "CRS PNR", selector: "CRS_PNR", sortable: true},
            {name: "Ticket No", selector: "Ticket_No_", sortable: true},
            {name: "Check In Date", selector: "Check_In_Date", sortable: true},
            {name: "Checkout Date", selector: "Check_Out_Date", sortable: true},
            {name: "Total Nights", selector: "Total_Nights", sortable: true},
            {name: "Rate per Night", selector: "Rate_per_Night", sortable: true},
            {name: "Property", selector: "Property", sortable: true},
            {name: "City", selector: "City", sortable: true},

            // Transaction 
            {name: "Total Basic", selector: "Total_Basic", sortable: true},
            {name: "Taxes and Other Charges", selector: "Taxes_and_Other_Charges", sortable: true},
            {name: "Meals Amount", selector: "Meals_Amount", sortable: true},
            {name: "Baggage Amount", selector: "Baggage_Amount", sortable: true},
            {name: "Discount", selector: "Discount", sortable: true},
            {name: "Cancellation / Reschedule Charges", selector: "Cancellation___Reschedule_Charges", sortable: true},
            {name: "Gross Amount (Ticket value pre mark-up)", selector: "Gross_Amount__Ticket_value_pre_mark-up_", sortable: true},
            {name: "Commision", selector: "Commision", sortable: true},
            {name: "TDS", selector: "TDS", sortable: true},
            {name: "Net Amount (Payable to Vendor)", selector: "Net_Amount__Payable_to_Vendor_", sortable: true},
            {name: "Itilite Discount", selector: "Itilite_Discount", sortable: true},
            {name: "Markup", selector: "Markup", sortable: true},
            // Added new cols
            {name: "Total Transaction Amount", selector: "Total_Transaction_Amount", sortable: true},
            {name: "Total Transaction Amount in User Currency", selector: "Total_Transaction_Amount_in_User_Currency", sortable: true},
            {name: "Service Fee", selector: "Service_Fee", sortable: true},
            {name: "GST on SF", selector: "GST_on_SF", sortable: true},
            {name: "TDS on SF", selector: "TDS_on_SF", sortable: true},
            {name: "Net Service Fee", selector: "Net_Service_Fee", sortable: true},
            // gross amount to charged to customer
            {name: "Index", selector: "Index", sortable: true},

            // vendor 
            {name: "Vendor", selector: "Vendor", sortable: true},
            {name: "Payment Mode", selector: "Payment_Mode", sortable: true},
            {name: "Company Balance (with ITILITE) after transaction", selector: "Company_Balance__with_ITILITE__after_transaction", sortable: true},
            {name: "Company Balance (with ITILITE) after transaction V2", selector: "balanceAfterTransactionV2", sortable: true},
            {name: "ITILITE Balance (with Riya/TBO) after transaction", selector: "ITILITE_Balance__with_Riya_TBO__after_transaction", sortable: true},
            {name: "Amount Received from Client", selector: "Amount_Received_from_Client", sortable: true},
            {name: "Amount Paid to Vendor", selector: "Amount_Paid_to_Vendor", sortable: true},
            {name: "Remarks", selector: "Remarks", sortable: true, wrap: true, format: row => `${row.Remarks && row.Remarks.length > 35 ? row.Remarks.slice(0, 40)+'...' : row.Remarks}`,},
            {name: "Date Updated", selector: "Date_Updated", sortable: true},
            {name: "GST", selector: "GST", sortable: true},
            {name: "Entity", selector: "Entity", sortable: true},
            {name: "PG charges", selector: "PG_charges", sortable: true},
            {name: "Department", selector: "Department", sortable: true},
            {name: "Emp ID", selector: "Emp_ID", sortable: true}, 
            {name: "TDS Due", selector: "TDS_Due", sortable: true},
            {name: "Agent/ Reseller", selector: "Agent__Reseller", sortable: true},
            {name: "Vendor code for customer", selector: "Vendor_code_for_customer", sortable: true},
            {name: "Automated/ Manual Booking", selector: "Automated__Manual_Booking", sortable: true},
            {name: "Vendor Service fee", selector: "Vendor_Service_fee", sortable: true},
            {name: "TDS", selector: "TDS", sortable: true},
            {name: "Card Payment", selector: "Card_Payment", sortable: true},

            // paybycard details
            {name: "Card Transaction Id", selector: "Card_Transaction_Id", sortable: true},
            {name: "Amount Showed To Customer", selector: "Amount_Showed_To_Customer", sortable: true},
            {name: "Card Service Charge", selector: "Card_Service_Charge", sortable: true},
            {name: "Card PG Charge", selector: "Card_PG_Charge", sortable: true},
            {name: "Amount charged to Customer", selector: "Amount_charged_to_Customer", sortable: true},
            {name: "Amount Paid By Card", selector: "Amount_Paid_By_Card", sortable: true},
            {name: "Currency Code", selector: "Currency_Code", sortable: true},
            {name: "Exchange Rate", selector: "Exchange_Rate", sortable: true},
            // {name: "FC Net Amount", selector: "FC_Net_Amount", sortable: true},
            {name: "FC Balance After Transaction", selector: "FC_Balance_After_Transaction", sortable: true},
            {name: "FC Balance After Transaction V2", selector: "fcbalanceaftertransactionV2", sortable: true},
            {name: "FC Transaction Amount", selector: "FC_Transaction_Amount", sortable: true},
            {name: "FC Service Fee", selector: "FC_Service_Fee", sortable: true},
            {name: "FC Gross Amount (To be charged to customer)", selector: "FC_Gross_Amount__(To_be_charged_to_customer)", sortable: true},
            {name: "FC Amount Received from Client", selector: "FC_Amount_Received_from_Client", sortable: true},         

            // invoice details
            {name: "ITILITE Invoice", selector: "ITILITE_Invoice__", sortable: true},
            {name: "ITILITE Invoice Date", selector: "ITILITE_Invoice_Date", sortable: true},
            {name: "Airline / Hotel Invoice", selector: "Airline___Hotel_Invoice__", sortable: true},
            {name: "Airline / Hotel Invoice Date", selector: "Airline___Hotel_Invoice_Date", sortable: true},
            {name: "Airline / Hotel Invoice Status", selector: "Airline___Hotel_Invoice_Status", sortable: true},

            // gstDetails
            {name: "State of Departure / Hotel", selector: "State_of_Departure___Hotel", sortable: true},
            {name: "Vendor Tax Type - IGST- CGST- UTGST", selector: "Vendor_Tax_Type_-_IGST-_CGST-_UTGST", sortable: true},
            {name: "Vendor Tax Amount - IGST", selector: "Vendor_Tax_Amount_-_IGST", sortable: true},
            {name: "Vendor Tax Amount - CGST", selector: "Vendor_Tax_Amount_-_CGST", sortable: true},
            {name: "Vendor Tax Amount - SGST", selector: "Vendor_Tax_Amount_-_SGST", sortable: true},
            {name: "Vendor Tax Amount - UTGST", selector: "Vendor_Tax_Amount_-_UTGST", sortable: true},
            {name: "Payment Ref", selector: "Payment_Ref", sortable: true},
            {name: "Payment Date", selector: "Payment_Date", sortable: true},
            {name: "Paid By (ITILITE / CLIENT)", selector: "Paid_By__ITILITE___CLIENT_", sortable: true},


            {name: "Traveler Email Id", selector: "Traveler_Email-Id", sortable: true},
            {name: "Trip Creation Date", selector: "Trip_Creation_Date", sortable: true},
            {name: "Parent Company", selector: "Parent_Company", sortable: true},

            {name: "Unused Credit Applied", selector: "Unused_credit_applied", sortable: true},
            {name: "Credit Change Fee", selector: "Credit_change_fee", sortable: true},
            {name: "Unused Credit Source Booking ID", selector: "Ununsed_credit_source_booking_ID", sortable: true},
            {name: "Unused Credit Source Trip ID", selector: "Unused_credit_source_trip_ID", sortable: true},
            {name: "Input Tax (Staybazar and ITILITE)", selector: "Input_Tax__Staybazar_and_ITILITE_", sortable: true},
            {name: "StayBazar Cost", selector: "StayBazar_Cost", sortable: true},
            {name: "Spice Jet and Indigo PLB", selector: "Spice_Jet_and_Indigo_PLB", sortable: true},
            {name: "Gal Segment Fee", selector: "Gal_Segment_fee", sortable: true},
            {name: "Vistara PLB", selector: "Vistara_PLB", sortable: true},
            {name: "Amex Cash Back", selector: "Amex_Cash_Back", sortable: true},
            {name: "Kodo CashBack", selector: "Kodo_CashBack", sortable: true},
            {name: "PriceLine Hotel and Flight", selector: "PriceLine_Hotel__Flight", sortable: true},
            {name: "SBI CB", selector: "SBI_CB", sortable: true},
            {name: "Service Fee for RR", selector: "Service_fee_for_RR", sortable: true},
            {name: "Total Commission for RR", selector: "Total_Commission_for_RR", sortable: true},
            {name: "Lufthansa, SQ and AI Via GDS", selector: "Lufthansa__SQ_and_AI_Via_GDS", sortable: true},
            {name: "Total comm plus other incentives", selector: "Total_comm_plus_other_incentives", sortable: true},
            {name: "Indusind", selector: "Indusind", sortable: true},
            {name: "Transaction BBT Version", selector: "transaction_bbt_version", sortable: true},
            {name: "TTR to BBT Automated", selector: "is_automated", sortable: true},
            {name: "Automated", selector: "automated", sortable: true},
            {name: "SBT Automated", selector: "sbt_automated", sortable: true},

            {name: "Transaction Invoice No.", selector: "transaction_invoice", sortable: true, invoice_group: "invoice_group1"},
            {name: "Service Fee Invoice No.", selector: "service_fee_invoice", sortable: true, invoice_group: "invoice_group1"},
            {name: "Vendor Invoice No.", selector: "vendor_invoice", sortable: true, invoice_group: "invoice_group1"},
            {name: "Unsigned Invoice No.", selector: "trans_plus_sf_invoice", sortable: true, invoice_group: 'invoice_group2'},
            {name: "Signed Invoice No.", selector: "signed_invoice", sortable: true, invoice_group: "invoice_group2"},
            {name: "Invoice No.", selector: "invoice", sortable: true, invoice_group: "invoice_group3"}
        ],
    },
    loading: false,
    isCompanySelected: false,
    error: '',
    message: '',
    deleteInvoice: [],
    companyDaterange: {},
    saveUploadedInvoice: null,
    perPage: 10,
    currentPage: 1,
    companies: [],
    isTransPlusSfEnabled: false,
    trans_plus_sf_clients: []
}

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case FETCHING_INVOICES_TABLE_REQUEST:
        case DELETE_INVOICE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case FETCHING_INVOICES_TABLE_SUCCESS:
            // add the new cols key
            function removeSpecialChar(str, seprator) {
                return str = str.replace(/[&\/\\#, +()$~%.'":*?<>{}_]/g, seprator);
            }

            let cols = Object.keys(payload.results[0])
            let colName = []
            let colSelector = []
            for (let i = 0; i < cols.length; i++) {
                colSelector.push(removeSpecialChar(cols[i], '_'))
                colName.push(removeSpecialChar(cols[i], ' ').split(' ').filter(char => char !== '').join(' '))
            }

            let tableColumns = []
            for(let i = 0 ; i < colName.length && i < colSelector.length; i++) {
                tableColumns.push({
                    name: colName[i],
                    selector: colSelector[i],
                    sortable: true,
                })
            }
            tableColumns.push({
                name: 'Traveler Name',
                selector: "Traveler_name",
                sortable: true
            })

            let tempData = []
            for (let i = 0; i < payload.results.length; i++) {
                let obj = {}
                let name = "";
                let from = ""
                let to = ""
                let property = ""
                const { From, To, Property, Options, Status, Employee_First_Name, Employee_Last_Name } = payload.results[i]


                for (let key in payload.results[i]) {
                    let newKey = removeSpecialChar(key, '_')
                    if (key === "Employee_First_Name" || key === "Employee_Last_Name") {
                        // if (payload.results[i][key] === null) {
                        //     continue
                        // }
                        // name += payload.results[i][key] + " "
                        // obj["Traveler_name"] = name
                    }
                    else if (key === "invoice_details") {
                        obj['vendor_invoice'] = payload.results[i][key][0]['vendor']['invoice_number']
                        obj['transaction_invoice'] = payload.results[i][key][1]['transaction']['invoice_number']
                        obj['service_fee_invoice']  = payload.results[i][key][2]['service_fee']['invoice_number']
                        obj['trans_plus_sf_invoice']  = payload.results[i][key][3]['trans_plus_sf']['invoice_number']
                        obj['signed_invoice']  = payload.results[i][key][4]['signed']['invoice_number']
                        obj['invoice']  = payload.results[i][key][5]['invoice']['invoice_number']
                    } 
                    else {
                        obj[newKey] = payload.results[i][key]
                    }
                }

                // For Traveller Name
                if (Employee_First_Name !== null && Employee_Last_Name !== null) {
                    name = Employee_First_Name +" "+ Employee_Last_Name
                } else {
                    if (Employee_First_Name !== null && Employee_Last_Name === null) {
                        name += Employee_First_Name + ""
                    } else if (Employee_First_Name === null && Employee_Last_Name !== null) {
                        name += "" + Employee_Last_Name
                    } else {
                        name = ""
                    }
                }
                obj["Traveler_name"] = name

                // For Transaction Type
                if (Options) {
                    obj['Transaction_Type'] = Options 
                } else {
                    if (Options === null) {
                        obj['Transaction_Type'] = Status
                    }
                }

                // For Trip details
                if (Property === null) {
                    if (From !== null && To !== null) {
                        obj['Trip_Details'] = From + '  to  ' + To
                    } else {
                        obj['Trip_Details'] = ""
                    }
                } else {
                    obj['Trip_Details'] = Property
                }
                // console.log(`from - ${from} to - ${to} property - ${property}`)
                tempData.push(obj)
            }
            
            return {
                ...state, 
                loading: false,
                // data: payload.results,
                data: tempData,
                // columns: tableColumns,
                totalRows: payload.count
            }

        case FETCHING_INVOICES_TABLE_FAIL:
        case DELETE_INVOICE_FAIL:
        case FETCH_COMPANY_FAIL:
            return {
                ...state, 
                loading: false,
                error: payload,
                data: []
            }
        
        case DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                message: payload
            }

        case SAVE_DELETE_INVOICE:
            let invoices = []
            for (let key in payload[0]) {
                if (key === 'transaction_invoice') {
                    let txnInv = payload[0][key][0] && payload[0][key][0].split(',')
                    if (txnInv) {
                        txnInv.forEach(inv => {
                            invoices.push({
                                invoiceNumber: inv.trim(),
                                invoiceType: 'Transaction',
                                bbtSlNo: payload[0].SL_NO,
                            })
                        })
                    }
                } 
                else if (key === 'service_fee_invoice') {
                    let srvInv = payload[0][key][0] && payload[0][key][0].split(',')
                    if (srvInv) {
                        srvInv.forEach(inv => {
                            invoices.push({
                                invoiceNumber: inv.trim(),
                                invoiceType: 'Service Fee',
                                bbtSlNo: payload[0].SL_NO,
                            })
                        })
                    }
                } 
                else if (key === 'vendor_invoice') {
                    let venInv = payload[0][key][0] && payload[0][key][0].split(',')
                    if(venInv) {
                        venInv.forEach(inv => {
                            invoices.push({
                                invoiceNumber: inv.trim(),
                                invoiceType: 'Vendor',
                                bbtSlNo: payload[0].SL_NO,
                            })
                        })
                    }
                }
                else if (key === 'trans_plus_sf_invoice') {
                    let venInv = payload[0][key][0] && payload[0][key][0].split(',')
                    if(venInv) {
                        venInv.forEach(inv => {
                            invoices.push({
                                invoiceNumber: inv.trim(),
                                invoiceType: 'Trans Plus SF',
                                bbtSlNo: payload[0].SL_NO,
                            })
                        })
                    }
                }
                else if (key === 'signed_invoice') {
                    let venInv = payload[0][key][0] && payload[0][key][0].split(',')
                    if(venInv) {
                        venInv.forEach(inv => {
                            invoices.push({
                                invoiceNumber: inv.trim(),
                                invoiceType: 'Signed',
                                bbtSlNo: payload[0].SL_NO,
                            })
                        })
                    }
                }
            }
            return {
                ...state,
                // deleteInvoice: payload,
                deleteInvoice: invoices
            }

        case SAVE_COMPANY_N_DATERANGE:
            return {
                ...state,
                companyDaterange: payload
            }

        case FILTER_DELETE_INVOICES: 
            let _tempDelInvoice = state.deleteInvoice.filter(invoice => `${invoice.invoiceType} Invoice` !== payload.invoiceType);

            return {
                ...state,
                deleteInvoice: _tempDelInvoice
            }

        case SET_COMPANY_SELECTED:
            return {
                ...state,
                isCompanySelected: payload
            }

        case GET_COLUMNS:
            let _tempCols = []
            if (payload === "showAll") {
                _tempCols = [...state.invoiceViews.showAll]
            } else {
                _tempCols = [...state.invoiceViews.default]
            }
            
            return {
                ...state,
                columns: _tempCols
            }

        case SAVE_INDIVIDUAL_UPLOADED_INVOICE:
            return {
                ...state,
                saveUploadedInvoice: payload
            }

        case SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: payload
            }

        case SET_PER_PAGE:
            return {
                ...state,
                perPage: payload
            }

        case FETCH_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                companies: payload
            }

        case FETCH_TRANS_PLUS_SF_CLIENTS:
            let tpsf = false;
            if(state.companyDaterange){
                tpsf = payload.includes(state.companyDaterange.company)
            }
            return {
                ...state,
                trans_plus_sf_clients: payload,
                isTransPlusSfEnabled: tpsf
            }

        default:
            return state 
    }
}
