import React from 'react'
import './Dashboard.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { Dropdown } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';


function Navbar({ toggle, logout, user }) {

    const location = useLocation();

    console.log('navbar>>>', location.pathname)
    return (
        <React.Fragment>
            <nav id="navbar">
                <ul>
                    <li>
                        <button className="hamburger" onClick={() => toggle(true)}>
                            <img src="images/hamburger.png" alt="hamburger" />
                        </button>
                    </li>
                    <li id="itilite-logo">
                        <img src="images/logo-source.png" alt="itilite logo" />
                    </li>
                    <li className="header-name">
                        {location.pathname === '/dashboard' ? "BBT Entries" : (location.pathname === '/ttrDownload' ? "TTR Report" : "Invoice")}
                    </li>
                </ul>
                <ul>
                    <li>
                        {/* <a onClick={logout} href="#"> */}
                            <img src="images/user.png" alt="user" />
                        {/* </a> */}
                        <Dropdown className="ml-1">
                            <Dropdown.Toggle id="dropdown-basic" className="navbar__dropdown">
                                {user && user.first_name}{' '}{user && user.last_name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item className="navbar__dropdownItem" onClick={logout} href="/"> Logout </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>
                </ul>

            </nav>
        </React.Fragment>
    )
}

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps, { logout })(Navbar);
