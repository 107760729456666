// Login
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const USER_LOAD = 'USER_LOAD';
export const AUTH_ERROR = 'AUTH_ERROR';

// Table Data
export const FETCHING_TABLE_DATA_SUCCESS = 'FETCHING_TABLE_DATA_SUCCESS';
export const FETCHING_TABLE_DATA_FAIL = 'FETCHING_TABLE_DATA_FAIL';

// Basic Search
export const BASIC_SEARCH_SUCCESS = 'BASIC_SEARCH_SUCCESS';
export const BASIC_SEARCH_FAIL = 'BASIC_SEARCH_FAIL';

// Select View
export const SELECT_VIEW_SUCCESS = 'SELECT_VIEW_SUCCESS';
export const SELECT_VIEW_FAIL = 'SELECT_VIEW_FAIL';

export const CUSTOM_VIEW_SUCCESS = 'CUSTOM_VIEW_SUCCESS';
export const CUSTOM_VIEW_FAIL = 'CUSTOM_VIEW_FAIL';

export const CUSTOM_VIEW_APPLY = 'CUSTOM_VIEW_APPLY';
export const CUSTOM_COLUMN_REMOVE = 'CUSTOM_COLUMN_REMOVE';

// Advance Search
export const ADVANCE_SEARCH_SUCCESS = 'ADVANCE_SEARCH_SUCCESS';
export const ADVANCE_SEARCH_FAIL = 'ADVANCE_SEARCH_FAIL';


