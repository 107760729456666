import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function PublicRoute(props) {
	if (localStorage.token) {
		return <Redirect to="/dashboard" />
	}
	return <Route {...props} />
}

export default PublicRoute;
