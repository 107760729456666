import axios from 'axios';
import { 
    FETCHING_TABLE_DATA_SUCCESS, 
    FETCHING_TABLE_DATA_FAIL,
} from '../../actions/types';
import { apiUrl } from '../../utils/apiUrl';


// Data Fetch
export const tableData = (selectInput=null, searchInput=null) => async dispatch => {
    let stmt = null;
    if (selectInput && searchInput){
        stmt = `${apiUrl.INVOICE_PROCESS_TRACKER}?${selectInput}=${searchInput}`
    }else{
        stmt = `${apiUrl.INVOICE_PROCESS_TRACKER}`
    }
    
    let config = {headers: {"Authorization": localStorage.token}}
    try {
        const res = await axios.get(stmt, config);
        dispatch({
            type: FETCHING_TABLE_DATA_SUCCESS,
            payload: res.data
        })
        return res
    } catch(error) {
        console.log(error)
        dispatch({
            type: FETCHING_TABLE_DATA_FAIL
        })
        return error
    }
}

export const cols = () =>{
     return [
        {name: "id", selector: "id", sortable: true, maxWidth: '80px'},
        {name: "File Name", selector: "file_name", sortable: true},
        {name: "Batch Id", selector: "upload_batch_id", sortable: true, maxWidth: '160px'},
        {name: "Process Type", selector: "process_type", sortable: true, maxWidth: '220px', cell: row => {
            if(row.process_type === 0){
                return "OCR PROCESS"
            }else if(row.process_type === 1){
                return "WITHOUT OCR"
            }else if(row.process_type === 2){
                return "MAPPED AND WITHOUT_OCR"
            }else{
                return row.process_type
            }
        }},
        {name: "Process Status", selector: "process_status", sortable: true, maxWidth: '150px', cell: row => {
            if(row.process_status === 0){
                return "UPLOADED"
            }else if(row.process_status === 1){
                return "PROCESSING"
            }else if(row.process_status === 2){
                return "SUCCESS"
            }else{
                return "FAILED"
            }
        }},
        {name: "Comment", selector: "comment", sortable: false},
        {name: "Uploaded At", selector: "created_at", sortable: true, maxWidth: '160px'}
    ]
}