import axios from 'axios';
import { 
    FETCHING_TABLE_DATA_SUCCESS, 
    FETCHING_TABLE_DATA_FAIL,
} from '../../actions/types';


export const cols = () =>{
     return [
        // {name: "id", selector: "id", sortable: true, maxWidth: '80px'},
        {name: "BBT SL NO", selector: "sequence_number", sortable: true, maxWidth: '250px'},
        {name: "Trip ID", selector: "trip_id", sortable: true, maxWidth: '250px'},
        {name: "Invoice Number", selector: "invoice_number", sortable: true, maxWidth: '250px'},
        {name: "Invoice Date", selector: "invoice_date",sortable: true, maxWidth: '180px'},
        {name: "Downloaded from zoho", selector: "download_status",maxWidth: '250px',sortable: true, cell: row => {
            if(row.download_status === 1){
                return ("Yes");
            }else{
                return ("No");
            }
        }},
        {name: "Migrated to Itilite app", selector: "migration_status", maxWidth: '250px',sortable: true, cell: row => {
            if(row.migration_status === 1){
                return ("Yes");
            }else{
                return ("No");
            }
        }},
        {name: "Comment", selector: "comment", sortable: true},
    ]
}

export const cols1 = () =>{
    return [
       {name: "BBT SL NO", selector: "sequence_number", sortable: true, maxWidth: '250px'},
       {name: "Client ID", selector: "client_id", sortable: true, maxWidth: '250px'},
       {name: "Trip ID", selector: "trip_id", sortable: true, maxWidth: '250px'},
       {name: "Invoice Number", selector: "invoice_number", sortable: true, maxWidth: '250px'},
       {name: "Invoice Date", selector: "invoice_date",sortable: true, maxWidth: '180px'},
       {name: "Invoice ID", selector: "invoice_id",sortable: true, maxWidth: '180px'},
       {name: "Created At", selector: "created_at", sortable: true}
   ]
}

export const fetchData = (url) => async dispatch => {
    let config = {headers: {"Authorization": localStorage.token}}
    try {
        const res = await axios.get(url, config);
        dispatch({
            type: FETCHING_TABLE_DATA_SUCCESS,
            payload: res.data
        })
        return res
    } catch(error) {
        console.log(error)
        dispatch({
            type: FETCHING_TABLE_DATA_FAIL
        })
        return error
    }
}