import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import {
	Row,
	Col,
	Button,
	Form,
	FormControl
} from "react-bootstrap";
import Footer from '../uploadInvoices/Footer';
import { useDispatch, useSelector } from "react-redux";
import "./zoho-input.css";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import moment from "moment";
import {fetchCompany} from '../../actions/ViewInvoices/invoices';

import axios from 'axios';
import { apiUrl } from '../../utils/apiUrl';
import swal from 'sweetalert';


function ZohoInput() {
	const [fromDate, setStartDate] = useState(new Date(moment().subtract(1, "months")));
	const [toDate, setEndDate] = useState(new Date(moment().subtract(1, "days")));
	const [invDate, setInvDate] = useState(new Date());
	const [invMnth, setInvMnth] = useState(new Date());
	const [model, setModel] = useState('agency');
	const [selectedOptions, setSelectedOptions] = useState([]);
	
	const [dataLoading, setDataLoading] = useState(false);
	const [companyOptions, setCompanyOptions] = useState([]);
	const [regularInput, setRegularInput] = useState(true);
	const [sfInput, SetsfInput] = useState(false);
	const [compCurrency, SetCompCurrency] = useState("inr_companies");

	const [isLoading, setLoading] = useState(false); // edf loading
	

	const dispatch = useDispatch()

	const {companies} = useSelector(state => state.viewInvoices)

	const submitInput = (event) => {
		event.preventDefault();

		if (regularInput){
			let opt = []
			for(let opt_row of selectedOptions){
				let select_val = opt_row.value
				if (select_val !== "*"){
					opt.push(select_val);
				}
			}
			var difference_in_time = toDate.getTime() - fromDate.getTime();
			var difference_in_days = difference_in_time / (1000 * 3600 * 24);
			if (difference_in_days < 63){
				if (opt.length > 0){
					fetchData(opt.toString(), format(fromDate, 'yyyy-MM-dd'), format(toDate, 'yyyy-MM-dd'));
					setDataLoading(true);
				}else{
					swal("no company selected")
				}
			}else{
				swal("date range exceed max limit - 2 months")
			}
		}else if(sfInput){
			fetchData(null, format(invMnth, 'yyyy-MM'), format(invDate, 'yyyy-MM-dd'));
			setDataLoading(true);
		}
	}

	useEffect(() => {
			dispatch(fetchCompany("inr", 0))
		}, [])

	useEffect(() => {
		if (companies){
			
			let temp = companies.map(com => {
				return { label: com, value: com}
			})
			setCompanyOptions(temp);
		}
		}, [companies, dispatch])
		

	function onChange(value, event) {
		if (event.action === "select-option" && event.option.value ==="*") {
		this.setState(this.options);
		} else if (event.action === "deselect-option" &&
		event.option.value === "*") {
		this.setState([]);
		} else if (event.action === "deselect-option") {
		this.setState(value.filter(o => o.value !== "*"));
		} else if (value.length === this.options.length - 1) {
		this.setState(this.options);
		} else {
		this.setState(value);
		}
	}

	async function fetchData(selectedCompanies, from, to) {
		var stmt = null;
		if (selectedCompanies){
			stmt = `${apiUrl.ZOHO_INPUT}?companies=${selectedCompanies}&from_date=${from}&to_date=${to}&model=${model}`
		}else{
			stmt = `${apiUrl.ZOHO_INPUT}?from_date=${from}&invoice_date=${to}&model=${model}`
		}
		
		let config = {headers: {"Authorization": localStorage.token}}
		try {
			const response = await axios.get(stmt, config);
			setDataLoading(false)
			if (response.status === 200) {
				if (response.data.isBase64Encoded){
					downloadEncodedFile(response.data.fileEncode, "zoho_inputs.zip")
				}else{
					console.log("wrong file foramt response")
					swal("Can't download file.")
				}
			}
		} catch(error) {
			console.log(error)
			setDataLoading(false)
			try{
				if (error.response.status === 400){
					swal(error.response.data.message)
				}else if (error.response.status === 404){
					swal("No data found")
				}else if (error.response.status === 500){
					swal("Error occoured while downloading data")
				}
			}catch(try_err){
				console.log(error)
				swal("Error in download file.")
			}
		}
	}

	const downloadEncodedFile = (encodedData, file_name) => {
		const blob = b64toBlob(encodedData, "application/zip");
		const blobUrl = URL.createObjectURL(blob);

		let a = document.createElement("a");
    	document.body.appendChild(a);
		a.style = "display: none";		
		a.href = blobUrl;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(blobUrl);
	}

	const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		  const slice = byteCharacters.slice(offset, offset + sliceSize);

		  const byteNumbers = new Array(slice.length);
		  for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		  }

		  const byteArray = new Uint8Array(byteNumbers);
		  byteArrays.push(byteArray);
		}
	  
		const blob = new Blob(byteArrays, {type: contentType});
		return blob;
	}

	const onChangeRadio = (event) => {
		let radio_value = event.target.value
		let previous_model = model
		let current_model = radio_value

		if (compCurrency === "non_inr_companies"){
			radio_value = radio_value + "_international"
		}

        setModel(radio_value)
        if (compCurrency === "inr_companies"){
            let arr = ['reseller', 'credit_note']
			if(previous_model == 'agency_international' && current_model != 'agency_international' ||
			(previous_model == 'trans_plus_sf' && current_model != 'trans_plus_sf') ||
			(previous_model != 'trans_plus_sf' && current_model == 'trans_plus_sf') ||
			(arr.indexOf(previous_model) === -1 && arr.indexOf(current_model) !== -1) ||
			(arr.indexOf(previous_model) !== -1 && arr.indexOf(current_model) === -1)){
                let filter_trans_plus_sf = radio_value == 'trans_plus_sf' ? 1 : 0;
                if(arr.includes(radio_value)){
                    filter_trans_plus_sf = 2;
                }
                dispatch(fetchCompany("inr", filter_trans_plus_sf))
                setSelectedOptions([])
            }
		}

		if (radio_value === "service_fee" || radio_value === "service_fee_international"){
			setRegularInput(false)
			SetsfInput(true)
		}else{
			setRegularInput(true)
			SetsfInput(false)
		}	
    }

	const onChangeCurrency = (event) => {
		let radio_value = event.target.value
		SetCompCurrency(radio_value)
		if (radio_value === "inr_companies"){
		    let arr = ['reseller', 'credit_note']
			let filter_trans_plus_sf = model == 'trans_plus_sf' ? 1 : 0;
			if(arr.includes(model)){
                filter_trans_plus_sf = 2;
            }
			dispatch(fetchCompany("inr", filter_trans_plus_sf))
			setSelectedOptions([])
			setModel(model.replace('_international',''))
		}else{
			dispatch(fetchCompany("non-inr"))
			setSelectedOptions([])
			setModel(model+"_international")
		}	
    }

	const customStyles = {
		option: (provided, state) => ({
		  ...provided,
		  borderBottom: '1px dotted black',
		  width: 400,
		}),		
	  }

	const handleEDFClick = () => setLoading(true);

	useEffect(() => {
		if (isLoading) {
			makeNetworkRequest().then((response) => {
				if (response.status === 200) {
					if (response.data.isBase64Encoded){
						downloadEncodedFile(response.data.fileEncode, "amex_edf_file.zip")
					}else{
						console.log("wrong file foramt response")
						swal("Can't download file.")
					}
				}
				setLoading(false);
			}).catch(error => {
				setLoading(false);
				console.log(error)
				try{
					if (error.response.status === 400){
						swal(error.response.data.message)
					}else if (error.response.status === 404){
						swal("No file created")
					}else if (error.response.status === 500){
						swal("Error occoured while downloading data")
					}
				}catch(try_err){
					console.log(error)
					swal("Error in download file.")
				}
				
			});
		}
	}, [isLoading]);

	function makeNetworkRequest() {
		let config = {headers: {"Authorization": localStorage.token}}
		let stmt = `${apiUrl.EDF_FILE}`
		return axios.get(stmt, config);
	}

	return (
		<div className="viewInvoices">
			<div className="viewInvoices__container">
				<h4 className="viewInvoices__title">Generate Zoho Input File</h4>
				<div className="viewInvoices__horizontalLine"></div>
				<Row>
					<Col md={6}>
							{<div className="currencyModel__radioContainer">
								<div className="d-flex">
									<input onChange={onChangeCurrency} defaultChecked type="radio" id="inr_companies" name="currency" value="inr_companies" />
									<label className="individualInvoice__labelText" for="inr_companies">INR Companies</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeCurrency} type="radio" id="non_inr_companies" name="currency" value="non_inr_companies" />
									<label className="individualInvoice__labelText" for="non_inr_companies">Non-INR Companies</label>
								</div>
							</div>}
					</Col>
				</Row>
				
				<div className="viewInvoices__horizontalLine"></div>
				<div className="searchSection">
					<Row>
							<Col md={2}>
								{regularInput &&<ReactMultiSelectCheckboxes
									options={[{label: "All", value: "*"}, ...companyOptions]}
									placeholderButtonLabel="Select Companies"
									value={selectedOptions}
									onChange={onChange}
									setState={setSelectedOptions}
									styles={customStyles}
								/>}
							</Col>

							<Col md={6}>
							{compCurrency === "inr_companies" && <div className="uploadModel__radioContainer">
								<div className="d-flex">
									<input onChange={onChangeRadio} defaultChecked type="radio" id="agency" name="model" value="agency" />
									<label className="individualInvoice__labelText" for="agency">Bill of Supply</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="credit_note" name="model" value="credit_note" />
									<label className="individualInvoice__labelText" for="credit_note">Credit Note</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="reseller" name="model" value="reseller" />
									<label className="individualInvoice__labelText" for="reseller">Reseller</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="service_fee" name="model" value="service_fee" />
									<label className="individualInvoice__labelText" for="service_fee">Service Fee</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="trans_plus_sf" name="model" value="trans_plus_sf" />
									<label className="individualInvoice__labelText" for="trans_plus_sf">Unsigned</label>
								</div>
							</div>}

							{compCurrency === "non_inr_companies" && <div className="uploadModel__radioContainer">
								<div className="d-flex">
									<input onChange={onChangeRadio} defaultChecked type="radio" id="agency" name="model" value="agency" />
									<label className="individualInvoice__labelText" for="agency">Debit Invoices</label>
								</div>
								
							</div>}
							</Col>
						
						</Row>

					<div className="viewInvoices__horizontalLine"></div>

					<Row>
						{regularInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
							<Col>
								<div className="dashboard__timePeriod">
									<img
									className="dashboard__calenderImg"
									src='images/calender.png'
									alt='calender-icon'
									/>
									<DatePicker
									selected={fromDate}
									onChange={date => {
										setStartDate(date);
									}}
									dateFormat="dd-MMM-yyyy"
									className="dashboard__datepicker"
									/>
								</div>
							</Col>
							<Col>
								<div className="dashboard__timePeriod" >
									<img
									className="dashboard__calenderImg"
									src='images/calender.png'
									alt='calender-icon'
									/>
									<DatePicker
									selected={toDate}
									onChange={date => {
										setEndDate(date, 'MM/dd/yyyy');
									}}
									dateFormat="dd-MMM-yyyy"
									minDate={fromDate}
									maxDate={new Date(moment(fromDate).add(2, 'months'))}
									/>
								</div>
							</Col>
						</Form.Group>}

						{sfInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
						<div className="dashboard__searchSection d-flex justify-content-space align-items-center" >

							<div className="mr-3 dashboard__labelText">Month</div>
							<div className="dashboard__timePeriod">
							<img
								className="dashboard__calenderImg"
								src='images/calender.png'
								alt='calender-icon'
							/>
							<DatePicker
								selected={invMnth}
								onChange={date => {
									setInvMnth(date);
								}}
								dateFormat="MMM-yyyy"
								showMonthYearPicker
								className="dashboard__datepicker"
							/>
							</div>

            				<div className="mx-3 dashboard__labelText">Invoice Date</div>

							<div className="dashboard__timePeriod">
							<img
								className="dashboard__calenderImg"
								src='images/calender.png'
								alt='calender-icon'
							/>
							<DatePicker
								selected={invDate}
								onChange={date => {
									setInvDate(date);
								}}
								dateFormat="dd-MMM-yyyy"
								className="dashboard__datepicker"
							/>
							</div>
						</div>

						</Form.Group>}

						<Col>
							<button onClick={submitInput} className="viewInvoices__txnDateSeach" id="submit_btn">Submit</button>
						</Col>
					</Row>

				</div>
				<div className="viewInvoices__horizontalLine"></div>

				<div className="dashboard__noDataFound">
				{!dataLoading && regularInput && compCurrency === "inr_companies" && <ul><b>Instructions for Agency:</b>
									<li> File can not be generated for companies: Indigrid, Fulcrum, Compass, Lindstrom, HDFC Securities because we cannot pick non-default GST related information as of now. </li>
								</ul>}
				{dataLoading && <h2> Processing..</h2>}
				</div>

				<Button
					className="viewInvoices__txnDateSeach"
					disabled={isLoading}
					onClick={!isLoading ? handleEDFClick : null}
					>
					{isLoading ? 'Processing.…' : ' AMEX EDF '} 
				</Button>

			</div>
			<Footer />
		</div>
	)
}


export default ZohoInput;
