import axios from 'axios';
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, USER_LOAD, AUTH_ERROR } from './types';
import setAuthToken from '../utils/setAuthToken';
import { apiUrl } from '../utils/apiUrl';
import swal from 'sweetalert';



// Check user on load
export const loadUser = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token)
    }

    try {
        const res = await axios.get(apiUrl.LOAD_USER);

        dispatch({
            type: USER_LOAD,
            payload: res.data
        })

    } catch (error) {
        dispatch({
            type: LOGOUT
        })
    }
}

// Login
export const login = (email, password) => async dispatch => {
    const config = {headers: {'Content-Type': 'application/json'}}
    const body = JSON.stringify({email, password})

    try{
        let res = await axios.post(apiUrl.LOGIN_URL, body, config);
        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        })
        dispatch(loadUser());
    }catch(err){
        if (err.response){
            let errors = err.response.data.message
            swal(errors)
        }else if(err.request){
            console.log(err.request)
        }
        dispatch({
            type: LOGIN_FAIL
        })
    }
}

// Logout 
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    })
}