import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import {
	Row,
	Col,
	Form,
	FormControl,
	Table,
	Button
} from "react-bootstrap";
import Footer from '../uploadInvoices/Footer';
import { useDispatch, useSelector } from "react-redux";
import "./zoho-invoice.css";
import moment from "moment";

import Skeleton from 'react-skeleton-loader';
import { apiUrl } from '../../utils/apiUrl';
import Datatable1 from "../dashboard/Datatable";
import swal from 'sweetalert';
import { fetchData, cols } from './tableData';
import { CSVLink, CSVDownload } from "react-csv";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {fetchCompany} from '../../actions/ViewInvoices/invoices';


function ZohoInput() {
	const [fromDate, setStartDate] = useState(new Date(moment().subtract(15, "days")));
	const [toDate, setEndDate] = useState(new Date());
	const [selectValue, setSelectValue] = useState('true')
	const [slValue, setSlValue] = useState(null)
	const [tripValue, setTripValue] = useState(null)
	const [invoiceValue, setInvoiceValue] = useState(null)


	const [dateInput, setdateInput] = useState(true);
	const [invoiceInput, setInvoiceInput] = useState(false);
	const [slnoInput, setSlnoInput] = useState(false);
	const [migrationInput, setMigrationInput] = useState(false);
	const [tripIdInput, setTripIdInput] = useState(false);
	const [companyOptions, setCompanyOptions] = useState([]);
	const [selectedCompanies, setSelectedCompanies] = useState([]);

	const [dataLoading, setDataLoading] = useState(true);
	const [responseData, setResponseData] = useState([]);

	const {companies} = useSelector(state => state.viewInvoices)
	
	const dispatch = useDispatch()

	const customStyles = {
		option: (provided, state) => ({
		  ...provided,
		  borderBottom: '1px dotted black',
		  width: 400,
		}),		
	  }

	const submitInput = (event) => {
		event.preventDefault();
		let url = get_url()
		setDataLoading(true)
		dispatch(fetchData(url))
		.then(res => {
			setDataLoading(false)
			if (res.data !== undefined) {
				setResponseData(res.data.results)
			} else {
				setResponseData([])
			}
		}).catch(error => {
			setDataLoading(false)
			setResponseData([])
			swal('server error during fetching data')
			console.error('server error during fetching data', error);
		});
	}

	
	const onChangeRadio = (event) => {
		let radio_value = event.target.value

		if (radio_value === "date"){
			setdateInput(true)
			setInvoiceInput(false)
			setSlnoInput(false)
			setMigrationInput(false)
			setTripIdInput(false)
			// setSelectedCompanies([])
		}
		else if (radio_value === "sl_no"){
			setdateInput(false)
			setInvoiceInput(false)
			setSlnoInput(true)
			setMigrationInput(false)
			setTripIdInput(false)
		}
		else if (radio_value === "invoice"){
			setdateInput(false)
			setInvoiceInput(true)
			setSlnoInput(false)
			setMigrationInput(false)
			setTripIdInput(false)
		}
		else if (radio_value === "trip_id"){
			setdateInput(false)
			setInvoiceInput(false)
			setSlnoInput(false)
			setMigrationInput(false)
			setTripIdInput(true)
		}
		else if (radio_value === "migration"){
			setdateInput(false)
			setInvoiceInput(false)
			setSlnoInput(false)
			setMigrationInput(true)
			setTripIdInput(false)
			setSelectedCompanies([])
		}
    }

	function is_allowed_date_range(){
		let difference_in_time = toDate.getTime() - fromDate.getTime();
		let difference_in_days = difference_in_time / (1000 * 3600 * 24);
		return (difference_in_days < 63) ? true : false
	}

	function onChange(value, event) {
		if (event.action === "select-option" && event.option.value ==="*") {
		this.setState(this.options);
		} else if (event.action === "deselect-option" &&
		event.option.value === "*") {
		this.setState([]);
		} else if (event.action === "deselect-option") {
		this.setState(value.filter(o => o.value !== "*"));
		} else if (value.length === this.options.length - 1) {
		this.setState(this.options);
		} else {
		this.setState(value);
		}
	}

	function get_url(){
		let selected_final_companies = []
		for(let opt_row of selectedCompanies){
			let select_val = opt_row.value
			if (select_val !== "*"){
				selected_final_companies.push(select_val);
			}
		}

		if (dateInput){
			if (selected_final_companies.length > 0){
				let com_str = selected_final_companies.toString()
				return `${apiUrl.ZOHO_INVOICE}?company=${com_str}&from_date=${format(fromDate, 'yyyy-MM-dd')}&to_date=${format(toDate, 'yyyy-MM-dd')}`
			}else{
				return `${apiUrl.ZOHO_INVOICE}?from_date=${format(fromDate, 'yyyy-MM-dd')}&to_date=${format(toDate, 'yyyy-MM-dd')}`
			}
			
		}
		else if (invoiceInput){
			return `${apiUrl.ZOHO_INVOICE}?invoice_number=${invoiceValue}`
		}
		else if (tripIdInput){
			return `${apiUrl.ZOHO_INVOICE}?trip_id=${tripValue}`
		}
		else if (slnoInput){
			return `${apiUrl.ZOHO_INVOICE}?bbt_sl_no=${slValue}`
		}
		else if (migrationInput){
			if (selected_final_companies.length > 0){
				let com_str = selected_final_companies.toString()
				return `${apiUrl.ZOHO_INVOICE}?company=${com_str}&from_date=${format(fromDate, 'yyyy-MM-dd')}&to_date=${format(toDate, 'yyyy-MM-dd')}&migration=${selectValue}`
			}else{
				return `${apiUrl.ZOHO_INVOICE}?from_date=${format(fromDate, 'yyyy-MM-dd')}&to_date=${format(toDate, 'yyyy-MM-dd')}&migration=${selectValue}`
			}
		}
	}

	useEffect(() => {
		if (companies.length === 0) {
			dispatch(fetchCompany())
		}
		if (companies){
			
			let temp = companies.map(com => {
				return { label: com, value: com}
			})
			setCompanyOptions(temp);
			}
		}, [companies, dispatch])

	// Download data in CSV
	const [csvDownloadData, setCsvDownloadData] = useState([])
	const [csvHeaders, setCsvHeaders] = useState([])
	const downloadCSV = () => {
	   let _tableColumns = [...cols()]
	   let columnSelector = []
	   let filteredData = []
	   let headers = []
	   let _data = [...responseData]
	   if (_data.length > 0){
		 for (let col of _tableColumns) {
		   columnSelector.push(col.selector)
		   headers.push({
			 label: col.name,
			 key: col.selector
		   })
		 }
   
		 for (let d of _data) {
		   let newObj = new Object()
		   for (let key in d) {
			 if (columnSelector.includes(key)) {
			   newObj[key] = d[key]
			 }
		   }
		   filteredData.push(newObj)
		 }
		 setCsvDownloadData(filteredData)
		 setCsvHeaders(headers)
	   }else{
		 return false
	   }
	 }

	useEffect(() => {
		let url = get_url()
		dispatch(fetchData(url))
			.then(res => {
				setDataLoading(false)
				if (res.data !== undefined) {
					setResponseData(res.data.results)
				} else {
					setResponseData([])
				}
			}).catch(error => {
				setDataLoading(false)
				console.error('Error during fetching data', error);
			});
	}, [dispatch])

	return (
		<div className="viewInvoices">
			<div className="viewInvoices__container">
				<h4 className="viewInvoices__title">Zoho Invoices</h4>
				<div className="viewInvoices__horizontalLine"></div>
				<div className="searchSection">
					<Row>
							Filter By :
							<Col md={6}>
							{<div className="uploadModel__radioContainer">
								<div className="d-flex">
									<input onChange={onChangeRadio} defaultChecked type="radio" id="date" name="model" value="date" />
									<label className="individualInvoice__labelText" for="date">Company and Date Range</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="sl_no" name="model" value="sl_no" />
									<label className="individualInvoice__labelText" for="sl_no">BBT SL NO</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="trip_id" name="model" value="trip_id" />
									<label className="individualInvoice__labelText" for="trip_id">Trip Id</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="invoice" name="model" value="invoice" />
									<label className="individualInvoice__labelText" for="invoice">Invoice</label>
								</div>
								{/* <div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="migration" name="model" value="migration" />
									<label className="individualInvoice__labelText" for="migration">Status</label>
								</div> */}
							</div>}
							</Col>
						
						</Row>

					<div className="viewInvoices__horizontalLine"></div>

					<Row>
						{dateInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
		
							<Col>
							<ReactMultiSelectCheckboxes
									options={[{label: "All", value: "*"}, ...companyOptions]}
									placeholderButtonLabel="Select Companies"
									value={selectedCompanies}
									onChange={onChange}
									setState={setSelectedCompanies}
									styles={customStyles}
									width="200"
							/>
							</Col>
							From
							<Col>
								<div className="dashboard__timePeriod">
									<img
									className="dashboard__calenderImg"
									src='images/calender.png'
									alt='calender-icon'
									/>
									<DatePicker
									selected={fromDate}
									onChange={date => {
										setStartDate(date);
									}}
									dateFormat="dd-MMM-yyyy"
									className="dashboard__datepicker"
									/>
								</div>
							</Col>
							To
							<Col>
								<div className="dashboard__timePeriod" >
									<img
									className="dashboard__calenderImg"
									src='images/calender.png'
									alt='calender-icon'
									/>
									<DatePicker
									selected={toDate}
									onChange={date => {
										setEndDate(date, 'MM/dd/yyyy');
									}}
									dateFormat="dd-MMM-yyyy"
									minDate={fromDate}
									maxDate={new Date(moment(fromDate).add(1, 'months'))}
									/>
								</div>
							</Col>
						</Form.Group>}

						{invoiceInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
							Invoice Number
						<Col className="pl-0 mr-0" md={6}>
							<FormControl onChange={(e) => setInvoiceValue(e.target.value)} className="viewInvoices__inputSearch" type='text' placeholder='Enter Invoice to search' />
						</Col>

						</Form.Group>}

						{slnoInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
							Sequence Number
						<Col className="pl-0 mr-0" md={4}>
							<FormControl onChange={(e) => setSlValue(e.target.value)} className="viewInvoices__inputSearch" type='text' placeholder='Enter SL NO to search' />
						</Col>
						</Form.Group>}

						{tripIdInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
							Trip Id
						<Col className="pl-0 mr-0" md={4}>
							<FormControl onChange={(e) => setTripValue(e.target.value)} className="viewInvoices__inputSearch" type='text' placeholder='Enter Trip ID to search' />
						</Col>
						</Form.Group>}

						{migrationInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
							<Col>
							<ReactMultiSelectCheckboxes
									options={[{label: "All", value: "*"}, ...companyOptions]}
									placeholderButtonLabel="Select Companies"
									value={selectedCompanies}
									onChange={onChange}
									setState={setSelectedCompanies}
									styles={customStyles}
							/>
							</Col>
							From
							<Col>
								<div className="dashboard__timePeriod">
									<img
									className="dashboard__calenderImg"
									src='images/calender.png'
									alt='calender-icon'
									/>
									<DatePicker
									selected={fromDate}
									onChange={date => {
										setStartDate(date);
									}}
									dateFormat="dd-MMM-yyyy"
									className="dashboard__datepicker"
									/>
								</div>
							</Col>
							To
							<Col>
								<div className="dashboard__timePeriod" >
									<img
									className="dashboard__calenderImg"
									src='images/calender.png'
									alt='calender-icon'
									/>
									<DatePicker
									selected={toDate}
									onChange={date => {
										setEndDate(date, 'MM/dd/yyyy');
									}}
									dateFormat="dd-MMM-yyyy"
									minDate={fromDate}
									maxDate={new Date(moment(fromDate).add(2, 'months'))}
									/>
								</div>
							</Col>
						</Form.Group>}

						<Col>
							<button onClick={submitInput} className="viewInvoices__txnDateSeach" id="submit_btn">Submit</button>
						</Col>
					</Row>

				</div>
				<div className="viewInvoices__horizontalLine"></div>

				<div style={{ textAlign: "right", padding: "0px 25px 15px 0"}}>
					<CSVLink onClick={downloadCSV} headers={csvHeaders} filename={"zoho_invoices.csv"} data={csvDownloadData}>
						<Button id='btn-download' className="pr-0">
						<span>
							<img className='mr-2' src='images/download.png' />
						</span>
							Download
						</Button>
					</CSVLink>
				</div>

				{(!dataLoading) ? (      
				(responseData.length > 0) ? (
					<Datatable1
						data={responseData}
						columns={cols()}
					/>
				): (
					<div className="dashboard__noDataFound">
					  <h1>No data Available</h1>
					</div>
				  )
				): (
					<Table striped bordered>
						<thead>
							<tr>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
							</tr>
						</thead>
						<tbody>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => {
								return (
									<tr className='align-items-center'>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
									</tr>
								);
							})}
						</tbody>
					</Table>
					)}

			</div>
			<Footer />
		</div>
	)
}


export default ZohoInput;
