export const FETCHING_INVOICES_TABLE_REQUEST = 'FETCHING_INVOICES_TABLE_REQUEST';
export const FETCHING_INVOICES_TABLE_SUCCESS = 'FETCHING_INVOICES_TABLE_SUCCESS';
export const FETCHING_INVOICES_TABLE_FAIL = 'FETCHING_INVOICES_TABLE_FAIL';


/* ------------Delete Invoice---------------*/
export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAIL = 'DELETE_INVOICE_FAIL';
export const SAVE_DELETE_INVOICE = 'SAVE_DELETE_INVOICE';

export const SAVE_COMPANY_N_DATERANGE = 'SAVE_COMPANY_N_DATERANGE';
export const FILTER_DELETE_INVOICES = 'FILTER_DELETE_INVOICES';
export const SET_COMPANY_SELECTED = 'SET_COMPANY_SELECTED';
export const GET_COLUMNS = 'GET_COLUMNS';
export const SAVE_INDIVIDUAL_UPLOADED_INVOICE = 'SAVE_INDIVIDUAL_UPLOADED_INVOICE';
export const SET_PER_PAGE = 'SET_PER_PAGE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';


// Fetch company
export const FETCH_COMPANY_SUCCESS = 'FETCH_COMPANY_SUCCESS';
export const FETCH_COMPANY_FAIL = 'FETCH_COMPANY_FAIL';

export const SET_GSEQNUMBER = 'SET_GSEQNUMBER'
export const FETCHING_TABLE_REQUEST = 'FETCHING_TABLE_REQUEST';
export const FETCHING_TABLE_SUCCESS = 'FETCHING_TABLE_SUCCESS';
export const FETCHING_TABLE_FAIL = 'FETCHING_TABLE_FAIL';

export const FETCH_TRANS_PLUS_SF_CLIENTS = 'FETCH_TRANS_PLUS_SF_CLIENTS'




