import React, { Component } from 'react';
import './Stepper.css';
import propTypes from 'prop-types';

// props:
// 1. Array of steps = steps
// 2. stepper direction = direction

export default class Stepper extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // complete - to show a checkmark
            // selected - to fill the step with color
            // highlighted - to make the description bold
            // ["Create an account", "", "", ""]

            steps: []
        }
    }

    componentDidMount() {
        const { steps, currentStepNumber } = this.props

        const stepState = steps.map((step, index) => {
            const stepObj = {};
            stepObj.description = step;
            stepObj.completed = false;
            stepObj.highlighted = index === 0 ? true : false;
            stepObj.selected = index === 0 ? true : false;
            return stepObj
        })

        const currentSteps = this.updateStep(currentStepNumber - 1, stepState)

        this.setState({
            steps: currentSteps
        })
        
    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentStepNumber !== this.props.currentStepNumber) {
            const { steps } = this.state;
            const currentSteps =  this.updateStep(this.props.currentStepNumber - 1, steps)

            this.setState({
                steps: currentSteps
            })
        }
    }

    updateStep(stepNumber, steps) {
        const newSteps = [...steps];
        // complete - to show a checkmark
        // selected - to fill the step with color
        // highlighted - to make the description bold

        // 1. current steps
        // 2. past steps
        // 3. future steps
        let stepCounter = 0;
        while(stepCounter < newSteps.length) {
            // current step
            if (stepCounter === stepNumber) {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted:  true,
                    selected: true, 
                    completed: false,
                }
                stepCounter++
            }

            // past step
            else if (stepCounter < stepNumber) {
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: false, 
                    selected: true, 
                    completed: true
                }
                stepCounter++
            } else {
                // future
                newSteps[stepCounter] = {
                    ...newSteps[stepCounter],
                    highlighted: false, 
                    selected: false, 
                    completed: false
                }
                stepCounter++
            }
        }

        return newSteps;
    }
    
    render() {
        // const { steps } = this.props;
        const { steps } = this.state;
        const stepDisplay = steps.map((step, index) => {
            return (
                <div className="step-wrapper" key={index}>
                    <div className={`step-number ${step.selected ? "step-number-active" : "step-number-disabled"}`}>{step.completed ? <span><img src="images/okay.png" alt="okay" /></span> : index + 1}</div>
                    <div className={`step-description ${step.highlighted && "step-description-active"} ${!step.selected && 'step-description active'} `}>{ step.description }</div>
                    <div className={index !== steps.length - 1 ? `${step.completed ? "divider-line-active" : "divider-line"} divider-line divider-line-${steps.length}`: ""}></div>
                </div>
            )
        })
        return (
            <div className="stepper-wrapper-horizontal">
                {stepDisplay}
            </div>
        )
    }
}


Stepper.propTypes = {
    steps: propTypes.array.isRequired
}