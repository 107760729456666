import axios from 'axios';
import  { apiUrl } from '../../utils/apiUrl';
import {
    FETCHING_TABLE_REQUEST,
    FETCHING_TABLE_SUCCESS,
    FETCHING_TABLE_FAIL,
    SET_GSEQNUMBER,
    SET_CURRENT_PAGE,
    SET_PER_PAGE
} from '../../actions/ViewInvoices/types.js';
import swal from 'sweetalert';


export const fetchData = ({perPage="", page=1, filterBy="", searchTerm=""}) => async dispatch => {
     dispatch({
          type:  FETCHING_TABLE_REQUEST
     })
     try {
          var url = null;
          if(filterBy && searchTerm){
               url = `${apiUrl.COMP_LATEST_BALANCE}?filter_by=${filterBy}&value=${searchTerm}&start=${((page - 1)*perPage) + 1}&limit=${perPage}`
          }
          else{
               url = `${apiUrl.COMP_LATEST_BALANCE}?start=${((page - 1)*perPage) + 1}&limit=${perPage}`
          }
          const res = await axios.get(url);
          dispatch({
               type: FETCHING_TABLE_SUCCESS,
               payload: res.data
          })
     } catch (error) {
          console.log(error)
          try{
               if (error.response.status === 400){
                    swal(error.response.data.message)
               }
          }catch(try_err){
               console.log(error)
          }
          dispatch({
               type: FETCHING_TABLE_FAIL,
               payload: error.response
          })
     }
}

export const setPerPage = (payload) => dispatch => {
     console.log('inside setPerPage',payload)
     dispatch({
          type: SET_PER_PAGE,
          payload: payload
     })
}

export const setCurrentPage = (payload) => dispatch => {
     dispatch({
          type: SET_CURRENT_PAGE,
          payload: payload
     })
}

export const setGseqnumber = (payload) => dispatch => {
     dispatch({
          type: SET_GSEQNUMBER,
          payload: payload
     })
}