import React from 'react';
// import './Datatable.css';
import DataTable, { defaultThemes }  from 'react-data-table-component';
import { connect } from 'react-redux';


const customStyles = {
  headRow: {
    style: {
      height: '40px',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      '&:nth-child(n)': {
        // backgroundColor: 'rgba(240, 241, 243, 0.35)',
        // backgroundColor: 'white'
      },
      minHeight: '40px',
      paddingTop: '3px',
      paddingBottom: '3px',
      width: '152px',
      color: 'color: rgba(51, 51, 51, 0.85)',
      fontSize: '12px',
      lineHeight: '14px',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};

const SortIcon = () => <img src="images/sort.svg" alt="sort" />


function DatatableServerSide({ columns, data, downloadData, handlePerRowsChange, handlePageChange, totalRows, currentPage, perPage, paginationResetDefaultPage }) {  

  return (    
    <div>
      <DataTable
        data={data}
        columns={columns}
        customStyles={customStyles}
        pagination
        paginationServer
        sortIcon={<SortIcon />}
        noHeader
        paginationPerPage={perPage}
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        paginationResetDefaultPage={paginationResetDefaultPage}
        // paginationComponentOptions
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationRowsPerPageOptions={[10,50,100]}
        dense
      />
    </div>
  ) 
}


// const mapStateToProps = state => ({
//   cols: state.tableData.columns,
//   data: state.tableData.data,
//   testCols: state.tableData.testCols
// })

export default connect(
  // mapStateToProps,
  null,
  null,
)(DatatableServerSide);