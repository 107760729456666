import React, { useState } from 'react';
import './DeleteInvoice.css';
// import Navbar from '../../dashboard/Navbar';
import Footer from '../Footer';
import { Modal } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { deleteInvoice, filterDeleteInvoices } from '../../../actions/ViewInvoices/invoices';
import ToastNotification from '../../common/ToastNotification';

function DeleteInvoice() {
    const [modalShow, setModalShow] = useState(false);

    const [deleteNotification, setDeleteNotification] = useState(false);

    const invs = useSelector(state => state.viewInvoices.deleteInvoice)

    // const history = useHistory();


    // const [invoices, setInvoices] = useState([
        // {
        //     invoiceNumber: 'INV0123456',
        //     invoiceType: 'Transaction',
        //     bbtSlNo: '123456',
        // },
        // {
        //     invoiceNumber: 'INV0123457',
        //     invoiceType: 'Service Fee',
        //     bbtSlNo: '123456',
        // },
        // {
        //     invoiceNumber: 'INV0123458',
        //     invoiceType: 'Vendor',
        //     bbtSlNo: '123456',
        // },
        // {
        //     invoiceNumber: invs[0].transaction_invoice,
        //     invoiceType: 'Transaction',
        //     bbtSlNo: invs[0].SL_NO,
        // },
        // {
        //     invoiceNumber: invs[0].service_fee_invoice,
        //     invoiceType: 'Service Fee',
        //     bbtSlNo: invs[0].SL_NO,
        // },
        // {
        //     invoiceNumber: invs[0].vendor_invoice,
        //     invoiceType: 'Vendor',
        //     bbtSlNo: invs[0].SL_NO,
        // },
    // ])

    const [invoice, setInvoice] = useState([])

    const dispatch = useDispatch();

    const handleDeleteInvoice = clickType => {
        let all;
        if (clickType === 'every') {
            all = 'true'
        } else {
            all = 'false'
        }

        let obj = {
            ...invoice,
            all: all
        }

        // let obj = {
        //     invoiceNumber: invoice[0].invoiceNumber,
        //     invoiceType: invoice[0].invoiceType,
        //     bbtSlNo: invoice[0].bbtSlNo,
        //     all: all
        // }
        
        dispatch(deleteInvoice(obj)).then(res => {
            if (res.status === 200) {
                dispatch(filterDeleteInvoices(obj))
                setModalShow(false)
                setDeleteNotification(true)
            }
        })
        
        // let invs = invoices.filter(item => item.invoiceType !== invoice[0].invoiceType)
        // setInvoices(invs)
        // console.log('deleteInvoice>>>', )

        // setModalShow(false)
    }

    // const getInvoice = (invNo, invType, invSl) => {
    const getInvoice = ({ invoiceNumber, invoiceType, bbtSlNo }) => {
        let inv = {
            invoiceNumber: invoiceNumber,
            invoiceType:  `${invoiceType} Invoice`,
            bbtSlNo:  bbtSlNo,
        }
        setInvoice(inv)
    }

    // console.log(invs)

    if (invs.length === 0) {
        return <Redirect to="/viewInvoices" />
    }
    return (
        <div className="deleteInvoice">
            {/* <Navbar /> */}
            <ToastNotification show={deleteNotification} setShow={setDeleteNotification} invoice={invoice} />
            <div className="deleteInvoice__container">
                <h4 className="deleteInvoice__title">Delete Invoice</h4>
                <div className="deleteInvoice__horizontalLine"></div>
                <div className="deleteInvoice__bbtSl">BBT Sl. No. : {invs[0].bbtSlNo}</div>

                <div className="deleteInvoice__tableContainer">
                    <table className="deleteInvoice_table">
                        <thead>
                            <tr>
                                <th className="deleteInvoice__invoiceNo">Invoice No.</th>
                                <th className="deleteInvoice__action">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* {invs.length === 1 && (
                                <React.Fragment>
                                    <tr key={invs[0].id}>
                                        <td>{invs[0].transaction_invoice} &nbsp; &nbsp; <span className="deleteInvoice__muteText">( Transaction )</span></td>
                                        <td>
                                            <button onClick={(event) => {setModalShow(true); getInvoice(invs[0].transaction_invoice, 'Transaction', invs[0].SL_NO)}} className="deleteInvoive__deleteBtn"><img src="images/delete.png" /></button>
                                        </td>
                                    </tr>

                                    <tr key={invs[0].id}>
                                        <td>{invs[0].service_fee_invoice} &nbsp; &nbsp; <span className="deleteInvoice__muteText">( Service Fee )</span></td>
                                        <td>
                                            <button onClick={(event) => {setModalShow(true); getInvoice(invs[0].service_fee_invoice, 'Service Fee', invs[0].SL_NO)}} className="deleteInvoive__deleteBtn"><img src="images/delete.png" /></button>
                                        </td>
                                    </tr>

                                    <tr key={invs[0].id}>
                                        <td>{invs[0].vendor_invoice} &nbsp; &nbsp; <span className="deleteInvoice__muteText">( Vendor )</span></td>
                                        <td>
                                            <button onClick={(event) => {setModalShow(true); getInvoice(invs[0].vendor_invoice, 'Vendor', invs[0].SL_NO )}} className="deleteInvoive__deleteBtn"><img src="images/delete.png" /></button>
                                        </td>
                                    </tr>

                                </React.Fragment>


                            )} */}
                            {/* {renderTableInvoices(invs)} */}
                            {/* invs[0].invoiceNumber !== null && invs[1].invoiceNumber !== null && invs[2].invoiceNumber !== null */}
                            { invs && invs.map(invoice => (
                                <React.Fragment>
                                    {invoice.invoiceNumber !== null || invoice.invoiceNumber !== "" ? (
                                        <tr key={invoice}>
                                            {invoice.invoiceNumber && (
                                                <React.Fragment>
                                                    <td>{invoice.invoiceNumber} &nbsp; &nbsp; <span className="deleteInvoice__muteText">({invoice.invoiceType == 'Trans Plus SF' ? 'Unsigned' : invoice.invoiceType})</span></td>
                                                    <td>
                                                        <button value={JSON.stringify(invoice)} onClick={(event) => {setModalShow(true); getInvoice(invoice)}} className="deleteInvoive__deleteBtn"><img src="images/delete.png" /></button>
                                                    </td>
                                                </React.Fragment>
                                            )}
                                        </tr>
                                    ) : null }
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                    <div style={{width: '427px', marginTop: '20px', color: '#EB5C24'}}>
                        <Link className="deleteInvoice__goBack" to="/viewInvoices">Go Back</Link>
                    </div>
                </div>
            </div>

            {/* -------Modal------- */}
            <div className="">
                <Modal
                    size="md"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                    onHide={() => setModalShow(false)}
                    show={modalShow}
                    dialogClassName="deleteInovice__modal"
                >
                    <div className="deleteInvoice__closeBtnCont">
                        <button onClick={() => setModalShow(false)} className="deleteInvoice__closeBtn"><img src="images/close.png" alt="Close Icon" /></button>
                    </div>
                    <Modal.Body>
                        <p className="deleteInvoice__warningText">
                            Do you want to delete this invoice from this transaction or from every transaction?
                        </p>

                        <div className="deleteIncvoice__modalBts">
                            <button onClick={() => handleDeleteInvoice('only')} className="deleteInvoice__onlyTnxBtn">Only This Transaction</button>
                            <button onClick={() => handleDeleteInvoice('every')} className="deleteInvoice__everyTnxBtn">Every Transaction</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            <Footer />
        </div>
    )
}

export default DeleteInvoice
