import React, { useState } from 'react';
import './Datatable.css';
import { Button } from 'react-bootstrap';
import DataTable, { defaultThemes }  from 'react-data-table-component';
import { connect } from 'react-redux';


const customStyles = {
  headRow: {
    style: {
      height: '40px',
      borderTopStyle: 'solid',
      borderTopWidth: '1px',
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      '&:nth-child(n)': {
        // backgroundColor: 'rgba(240, 241, 243, 0.35)',
        // backgroundColor: 'white'
      },
      height: '40px',
      width: '152px',
      color: 'color: rgba(51, 51, 51, 0.85)',
      fontSize: '12px',
      lineHeight: '14px',
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};

const SortIcon = () => <img src="images/sort.svg" />

const Export = ({ onExport }) => (
  <Button onClick={e => onExport(e.target.value)}>Download</Button>
)

const Pagination = () => (
  <nav aria-label='Page navigation example'>
    <ul class='pagination justify-content-end'>
      <li class='page-item mr-2'>
        <a class='page-link' href='#' tabindex='-1' aria-disabled='true'>
          <img src='images/arrow-left.png' alt='Arrow Left' />
        </a>
      </li>
      <li class='page-item'>
        <a class='page-link' href='#'>
          01
        </a>
      </li>
      <li class='page-item'>
        <a class='page-link' href='#'>
          02
        </a>
      </li>
      <li class='page-item'>
        <a class='page-link' href='#'>
          03
        </a>
      </li>
      <li class='page-item'>
        <a class='page-link' href='#'>
          04
        </a>
      </li>
      <li class='page-item'>
        <a class='page-link' href='#'>
          05
        </a>
      </li>
      <li class='page-item'>
        <a class='page-link' href='#'>
          06
        </a>
      </li>
      <li class='page-item ml-2'>
        <a class='page-link' href='#'>
          <img src='images/arrow-right.png' alt='Right Arrow' />
        </a>
      </li>
    </ul>
  </nav>  
);

function Datatable1({ columns, data, downloadData, handlePerRowsChange, handlePageChange, totalRows, currentPage, perPage, paginationResetDefaultPage }) {

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  const convertArrayOfObjectsToCSV = (array) => {
    let result;
  
    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(data[0]);
  
    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;
  
    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
  
        result += item[key];
        
        ctr++;
      });
      result += lineDelimiter;
    });
  
    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  const downloadCSV = (array) => {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;
  
    const filename = 'bbtsheet.csv';
  
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }
  
    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const actionsMemo = React.useMemo(() => <Export onExport={() => downloadCSV(downloadData)} />, [])
  const actionsMemo1 = React.useMemo(() => <Export onExport={() => downloadCSV(downloadData)} />, [])

  const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
  const [defaultPage, setDefaultPage] = useState(1)
  const handlePageChange1 = () => {
    setResetPaginationToggle(!resetPaginationToggle)
  }
  const [p, setP] =useState(10)
  return (    
    <div>
      <DataTable
        columns={columns}
        data={data}
        customStyles={customStyles}
        pagination
        sortIcon={<SortIcon />}
        // striped
        noHeader
        // fixedHeader={false}
        paginationDefaultPage={defaultPage}
        paginationResetDefaultPage={resetPaginationToggle}
        onChangeRowsPerPage={handlePageChange}
        paginationRowsPerPageOptions={[10,50,100]}
        dense
        actions={[actionsMemo, actionsMemo1]}
        // actions={actionsMemo}
        // wrap={true}
        // progressPending
        // paginationComponent={Pagination}
      />

      {/* <DataTable
        data={data}
        columns={columns}
        customStyles={customStyles}
        pagination
        paginationServer
        sortIcon={<SortIcon />}
        noHeader
        paginationPerPage={perPage}
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        paginationResetDefaultPage={paginationResetDefaultPage}
        // paginationComponentOptions
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationRowsPerPageOptions={[10,50,100]}
        dense
      /> */}
    </div>
  ) 
}


// const mapStateToProps = state => ({
//   cols: state.tableData.columns,
//   data: state.tableData.data,
//   testCols: state.tableData.testCols
// })

export default connect(
  // mapStateToProps,
  null,
  null,
)(Datatable1);