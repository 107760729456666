import axios from 'axios';
import { 
    FETCHING_TABLE_DATA_SUCCESS, 
    FETCHING_TABLE_DATA_FAIL,
} from '../../actions/types';
import { apiUrl } from '../../utils/apiUrl';


// Data Fetch
export const InvSearchtableData = (searchInput) => async dispatch => {
    let stmt = `${apiUrl.INVOICE_MAPPING_TRACKER}?invoice_number=${searchInput}`
    
    let config = {headers: {"Authorization": localStorage.token}}
    try {
        const res = await axios.get(stmt, config);
        dispatch({
            type: FETCHING_TABLE_DATA_SUCCESS,
            payload: res.data
        })
        return res
    } catch(error) {
        console.log(error)
        dispatch({
            type: FETCHING_TABLE_DATA_FAIL
        })
        return error
    }
}

export const tableData = (fromDate, toDate, mapped) => async dispatch => {
    let stmt = `${apiUrl.INVOICE_MAPPING_TRACKER}?from_date=${fromDate}&to_date=${toDate}&map=${mapped}`
    
    let config = {headers: {"Authorization": localStorage.token}}
    try {
        const res = await axios.get(stmt, config);
        dispatch({
            type: FETCHING_TABLE_DATA_SUCCESS,
            payload: res.data
        })
        return res
    } catch(error) {
        console.log(error)
        dispatch({
            type: FETCHING_TABLE_DATA_FAIL
        })
        return error
    }
}

export const cols = () =>{
     return [
        // {name: "id", selector: "id", sortable: true, maxWidth: '80px'},
        {name: "Invoice Number", selector: "invoice_number", sortable: true, maxWidth: '180px'},
        {name: "Batch Id", selector: "upload_batch_id", sortable: true},
        {name: "Parent File", selector: "parent_file"},
        {name: "Mapped SL NO", selector: "mapped_sl"},
        {name: "Mapped Invoice Type", selector: "mapped_invoice_type", cell: row => {
            if(row.mapped_invoice_type){
                return row.mapped_invoice_type.replace(/_/g, ' ');
            }
        }},
        {name: "Status", selector: "status", sortable: false},
    ]
}