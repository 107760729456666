import React, { useState, useEffect } from 'react';
import Skeleton from 'react-skeleton-loader';
import { Table, Form, Button } from "react-bootstrap";
import './LatestCompanyBalance.css';
import Footer from '../uploadInvoices/Footer';
import DatatableServerSide from '../common/DatatableServerSide';
import { fetchData, setCurrentPage, setPerPage, setGseqnumber } from './tableData';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import swal from 'sweetalert';
import { apiUrl } from '../../utils/apiUrl.js';
import moment from 'moment';

function ViewCompanyLatestBalance(props) {
	const [searchTerm, setSearchTerm] = useState('');
	const [filterBy, setFilterBy] = useState('companyId')
	const dispatch = useDispatch();
	const history = useHistory();

	const { data, columns, totalRows, loading, currentPage, perPage, gseqnumber } = useSelector(state => state.viewLatestBalance);

	useEffect(() => {
          let obj = {
               perPage: perPage,
               page: currentPage,
			filterBy: filterBy,
               searchTerm: searchTerm
          }
		dispatch(fetchData(obj))
	}, [])

	const [paginationResetDefaultPage, setPaginationResetDefaultPage] = useState(false)

	const handlePageChange = async (page) => {
          let obj = {
               perPage: perPage,
               page: page,
			filterBy: filterBy,
               searchTerm: searchTerm
          }
		dispatch(fetchData(obj))
		dispatch(setCurrentPage(page))
		console.log('currentpage>>>', currentPage)
	}


	const handlePerRowsChange = async (newPerPage, page) => {
          let obj = {
               perPage: newPerPage,
               page: page,
			filterBy: filterBy,
               searchTerm: searchTerm
          }
		dispatch(fetchData(obj))
		dispatch(setPerPage(newPerPage))
		dispatch(setCurrentPage(1))
		setPaginationResetDefaultPage(!paginationResetDefaultPage)
	};

	const refreshData = (event) => {
		event.preventDefault();
		let obj = {
               perPage: perPage,
               page: currentPage,
			filterBy: filterBy,
               searchTerm: searchTerm
          }
		dispatch(fetchData(obj))
	}

	const submitSearchValue =  async (event) => {
		event.preventDefault();
		let obj = {
               perPage: 10,
               page: 1,
			filterBy: filterBy,
               searchTerm: searchTerm
          }
          dispatch(fetchData(obj))
          setCurrentPage(1)
	}

	const resetSearchValue =  async (event) => {
		event.preventDefault();
		setSearchTerm('')
		let obj = {
               perPage: 10,
               page: 1,
			filterBy: filterBy,
               searchTerm: ''
          }
          dispatch(fetchData(obj))
          setCurrentPage(1)
	}

	const handleDownload = async (event) => {
		event.preventDefault();
		fetchDataForDownload(filterBy, searchTerm);
	}

	const fetchDataForDownload = async (filter_by, search_term) => {
		var url = null;
		if(filterBy && searchTerm){
               url = `${apiUrl.COMP_LATEST_BALANCE}?filter_by=${filter_by}&value=${search_term}&is_download=1`
          }
          else{
               url = `${apiUrl.COMP_LATEST_BALANCE}?is_download=1`
          }
		try {
			const response = await axios.get(url);
			dispatch(setGseqnumber(response.data.results.gseqnumber));
			if (response.data.results.statusCode === 200) {
				if (response.data.results.isBase64Encoded){
					const fileName = `latest_balance_${moment().format('YYYYMMDD_HHmmss')}.zip`;
					downloadEncodedFile(response.data.results.fileEncode,fileName);
				}else{
					console.log("wrong file foramt response");
					swal("Can't download file.");
				}
			}
			if (response.data.results.statusCode === 500) {
				console.log("No data found");
				swal("No data found");
			}
		} catch(error) {
			console.log(error);
			try{
				if (error.response.status === 400){
					swal(error.response.data.message);
				}else if (error.response.status === 404){
					swal("No data found");
				}else if (error.response.status === 500){
					swal("Error occoured while downloading data");
				}
			}catch(try_err){
				console.log(error);
				swal("Error in downloading file.");
			}
		}
	}

	const downloadEncodedFile = (encodedData, file_name) => {
		const blob = b64toBlob(encodedData, "application/zip");
		const blobUrl = URL.createObjectURL(blob);
		let a = document.createElement("a");
		document.body.appendChild(a);
		a.style = "display: none";
		a.href = blobUrl;
		a.download = file_name;
		a.click();
		window.URL.revokeObjectURL(blobUrl);
	}

	const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		     const slice = byteCharacters.slice(offset, offset + sliceSize);
		     const byteNumbers = new Array(slice.length);
               for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
               }
		     const byteArray = new Uint8Array(byteNumbers);
		     byteArrays.push(byteArray);
	     }
		const blob = new Blob(byteArrays, {type: contentType});
		return blob;
	}

	const datatableRender = (data, cols, dataLoading) => {
		let columns = [...cols]
		if (!dataLoading) {
			if (data.length > 0 && cols.length > 0) {
				return (<DatatableServerSide
					data={data}
					columns={columns}
					totalRows={totalRows}
					handlePerRowsChange={handlePerRowsChange}
					handlePageChange={handlePageChange}
					currentPage={currentPage}
					perPage={perPage}
					paginationResetDefaultPage={paginationResetDefaultPage}
				/>);
			} else {
				return <div className="dashboard__noDataFound">
					<h1>No data Available</h1>
				</div>
			}
		} else {
			console.log("data", dataLoading)
			if (dataLoading) {
				console.log('len', data.length)
				return <Table striped bordered>
					<thead>
						<tr>
							<th><Skeleton /></th>
							<th><Skeleton /></th>
							<th><Skeleton /></th>
							<th><Skeleton /></th>
						</tr>
					</thead>
					<tbody>
						{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => {
							return (
								<tr className='align-items-center'>
									<td><Skeleton /></td>
									<td><Skeleton /></td>
									<td><Skeleton /></td>
									<td><Skeleton /></td>
								</tr>
							);
						})}
					</tbody>
				</Table>

			} else {
				console.log('no data found')
				return <h1>No data found</h1>
			}
		}
	}

	return (
		<div className="viewInvoices">
			<div className="viewInvoices__container">
				<div className="viewInvoice__header">
					<h4></h4>
					<h4 className="viewInvoices__title">Latest Company Balance</h4>
					<Button className="mx-0" onClick={refreshData} id='refresh-btn'>Refresh Data</Button>
				</div>
				<div className="viewInvoices__horizontalLine"></div>
				{(
					<React.Fragment>

						<div className="viewInvoices__tableSection">
							<div className="viewInvoices__filterSection">
                                        <div style={{display:"flex"}}>
									<Form.Control as='select' value={filterBy} onChange={(e) => setFilterBy(e.target.value)} className="dashboard__selectSearch">
										<option value="companyId">Company ID (bulk)</option>
										<option value="Company">Company Name</option>
									</Form.Control>
                                             <input
										value={searchTerm}
                                                  onChange={(e) => setSearchTerm(e.target.value)}
                                                  className="viewInvoices__inputSearch"
                                                  placeholder={filterBy === 'companyId' ? 'Values comma separated' : 'Enter Value'}
                                             />
									<button className="mx-0" onClick={submitSearchValue} id='search-btn'>
										<img src='images/search-icon.png' alt='Search Icon' />
									</button>
									<button className="mx-0" onClick={resetSearchValue} id='reset-btn'>
										<img src='images/retry.png' alt='Reset Icon' />
									</button>
									<button className="mx-0" onClick={handleDownload} id="download-btn">
										<img src="images/download.svg" alt="Download Icon" />
									</button>
                                        </div>
                                        <div className="gseqnumber">Latest G SL NO : {gseqnumber}</div>
							</div>
						</div>

						{data && columns.length > 1 && datatableRender(data, columns, loading)}

					</React.Fragment>
				)}
			</div>
			<Footer />
		</div>
	)
}

export default ViewCompanyLatestBalance
