import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  InputGroup,
  Button,
  FormControl,
  Modal,
} from "react-bootstrap";
import { connect } from "react-redux";
import { customViewAction, customViewApply, customColumnRemove } from '../../actions/tableData';

function CustomViewModal({
  showCustomViewModal,
  customViewModalClose,
  bookingDetails,
  transactionDetails,
  selectedColumns,
  availableColumns,
  allColumns,
  customViewAction,
  customViewApply,
  customColumnRemove,
  selectView,
  tableDataState
}) {

  const [columns, setColumns] = useState(allColumns);

  // const [selectedCols, setSelectedCols] = useState([]);

  const [searchTerm, setSearchTerm] = useState('')

  const [searchResults, setSearchResults] = React.useState({});


  // console.log('customView', selectedColumns)

  const customViewApplied = (event) => {
    event.preventDefault();
    customViewApply();
  }

  const rednderSwitchCase = (param) => {
    switch(param) {
      case 'bookingDetails':
        return 'Booking Details'
      case 'transactionDetails':
        return 'Transaction Details'
      case 'vendorDetails':
        return 'Vendor Details'
      case 'payByCardDetails':
        return 'Paybycard Details'
      case 'invoiceDetails':
        return 'Invoice Details'
      case 'gstDetails':
        return 'GST Details'
      case 'bbtView':
        return 'BBT View'
      case 'others':
        return 'Others'
      case 'multicurrencyDetails':
        return 'Multicurrency Details'
      // default:
      //   return 'No Columns Details'
    }
  }

  const renderSelectedColumns = (col, index) => {
    if (index < 10) {
      return <li className='' style={{paddingBottom: '10px'}}>{col.name}</li>
    } else {
      return <li className="d-flex justify-content-between pb-1 customViewModal__selectedCols" style={{paddingBottom: '10px !important'}}>
        <div>{col.name} <span className="text-muted">({rednderSwitchCase(col.key)})</span></div>
          <button
            onClick={(e) => customColumnRemove({name: col.name, selector: col.selector, sortable: true, key: col.key})}
            className="customViewModal__addCols"
            style={{
              border: 'none',
              background: 'none',
              outline: 'none'
            }}
          >
            <img src='images/minus.png' />
          </button>
      </li>
    }
  }

  // Live Search
  const handleSearch = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value.toLowerCase())
  }

  useEffect(() => {
    let results = {}
    for (let key in tableDataState.availableColumns) {
      let res = tableDataState.availableColumns[key].filter((colsName, index) => (
        colsName.name.toLowerCase().includes(searchTerm)
      ))
      results[key] = res
    }

    setSearchResults(results)
    // console.log(results)

  }, [searchTerm, tableDataState]);

  return (
    <Modal
      show={showCustomViewModal}
      onHide={customViewModalClose}
      size='xl'
      centered
      aria-labelledby='example-custom-modal-styling-title'
    >
      <Row className='modal-header-text'>
        <Col className='mr-5 pl-0'>Available Columns</Col>
        <Col className="pl-0">Selected Columns</Col>
      </Row>

      <div className='my-3'>
        <Row>
          <Col className='modal-body-border mr-5'>
            <InputGroup className='mb-3 customViewModal__search'>
              <InputGroup.Prepend>
                <InputGroup.Text className='customViewModal__searchInput'>
                  <img src='images/search.png' />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl className='customViewModal__searchInput' onChange={handleSearch} placeholder='Search...' />
            </InputGroup>
            <div style={{marginTop: '15px'}}>
              { Object.keys(searchResults).map((item, i) => (
                <ul key={i} className={`${searchResults[item].length > 0 ? ('customViewModal__ul') : 'customViewModal__ul d-none'}`}>
                  {searchResults[item].length > 0 ? <p style={{marginBottom: '10px'}} className="text-muted">{rednderSwitchCase(item)}</p> : null}
                        {searchResults[item].map((colsName, ind) =>
                          <li className="d-flex justify-content-between pb-1 customViewModal__list" key={ind}>
                            <div>{colsName.name}</div>
                            <button
                              onClick={(e) => customViewAction({name: colsName.name, selector: colsName.selector, sortable: true, key: item})}
                              className="customViewModal__addCols"
                              style={{
                                border: 'none',
                                background: 'none',
                                outline: 'none'
                              }}
                            >
                              <img src='images/plus.png' />
                            </button>
                          </li>
                      )}
                </ul>
              ))}
            </div>

          </Col>
          <Col className='modal-body-border'>
            <ul className='py-3'>
              {tableDataState.selectedColumns && tableDataState.selectedColumns.map((selectedColumn, i) => (
                renderSelectedColumns(selectedColumn, i)

              ))}
            </ul>
          </Col>
        </Row>
      </div>

      <div className='d-flex justify-content-end customViewModal__buttons'>
        <Button onClick={customViewModalClose} className='customViewModal__btnCancle'>
          Cancel
        </Button>
        <Button 
          className='customViewModal__applyBtn ml-2'
          onClick={(e) => (
            customViewApplied(e),
            customViewModalClose()
          )}
        >
          Apply
        </Button>
      </div>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  allColumns: state.tableData.allColumns,
  // selectedColumns: state.tableData.selectView.default,
  selectView: state.tableData.selectView,
  // selectedColumns: state.tableData.selectedColumns,
  tableDataState: state.tableData,
  // availableColumns: state.tableData.availableColumns
});

const mapDispatchToProps = {
  customViewAction,
  customViewApply,
  customColumnRemove,
};

export default connect(
  mapStateToProps,
  { customViewAction, customViewApply, customColumnRemove }
  // null
)(CustomViewModal);
