import { 
    FETCHING_TABLE_DATA_SUCCESS, 
    FETCHING_TABLE_DATA_FAIL,
    BASIC_SEARCH_SUCCESS, 
    BASIC_SEARCH_FAIL,
    SELECT_VIEW_SUCCESS,
    SELECT_VIEW_FAIL,
    CUSTOM_VIEW_SUCCESS,
    CUSTOM_VIEW_FAIL,
    CUSTOM_VIEW_APPLY,
    CUSTOM_COLUMN_REMOVE
} from '../actions/types';
import { selectViewAction } from '../actions/tableData';


const initialState = {
    data: [],
    loading: true, 
    columns: [                    
        {name: "Billing Sl No", selector: "id", sortable: true},
        {name: "Sl No", selector: "SL_NO", sortable: true},
        {name: "G SL No", selector: "gseqnumber", sortable: true},
        {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
        {name: "Company", selector: "Company", sortable: true},
        {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
        {name: "Status", selector: "Status", sortable: true},
        {name: "Booking ID", selector: "Booking_ID", sortable: true},
        {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
        {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
        {name: "Options", selector: "Options", sortable: true},
        {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
        {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
    ],
    allColumns: [],
    selectView: {
        default: [
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true,},
        ],
        bookingDetails: [
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "Depature Date", selector: "Departure_Date", sortable: true},
            {name: "Depature Time", selector: "Departure_Time", sortable: true},
            {name: "Arrival Date", selector: "Arrival_Date", sortable: true},
            {name: "Arrival Time", selector: "Arrival_Time", sortable: true},
            {name: "Type Of Travel", selector: "Type_Of_Travel", sortable: true},
            {name: "From", selector: "From", sortable: true},
            {name: "To", selector: "To", sortable: true},
            {name: "Oneway / Return", selector: "Oneway___Return", sortable: true},
            {name: "Airline", selector: "Airline", sortable: true},
            {name: "Flight No", selector: "Flight_No_", sortable: true},
            {name: "Class of Travel", selector: "Class_of_Travel", sortable: true},
            {name: "Class", selector: "Class", sortable: true},
            {name: "Riya PNR", selector: "Riya_PNR", sortable: true},
            {name: "Airline PNR", selector: "Airline_PNR", sortable: true},
            {name: "CRS PNR", selector: "CRS_PNR", sortable: true},
            {name: "Ticket No", selector: "Ticket_No_", sortable: true},
            {name: "Check In Date", selector: "Check_In_Date", sortable: true},
            {name: "Checkout Date", selector: "Check_Out_Date", sortable: true},
            {name: "Total Nights", selector: "Total_Nights", sortable: true},
            {name: "Rate per Night", selector: "Rate_per_Night", sortable: true},
            {name: "Property", selector: "Property", sortable: true},
            {name: "City", selector: "City", sortable: true},
            {name: "Remarks", selector: "Remarks", sortable: true, wrap: true, format: row => `${row.Remarks && row.Remarks.length > 35 ? row.Remarks.slice(0, 40)+'...' : row.Remarks}`,},
            {name: "Agent/ Reseller", selector: "Agent__Reseller", sortable: true},
            {name: "Automated/ Manual Booking", selector: "Automated__Manual_Booking", sortable: true},
        ],
        transactionDetails: [
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "Total Basic", selector: "Total_Basic", sortable: true},
            {name: "Taxes and Other Charges", selector: "Taxes_and_Other_Charges", sortable: true},
            {name: "Meals Amount", selector: "Meals_Amount", sortable: true},
            {name: "Baggage Amount", selector: "Baggage_Amount", sortable: true},
            {name: "Discount", selector: "Discount", sortable: true},
            {name: "Cancellation / Reschedule Charges", selector: "Cancellation___Reschedule_Charges", sortable: true},
            {name: "Gross Amount (Ticket value pre mark-up)", selector: "Gross_Amount__Ticket_value_pre_mark-up_", sortable: true},
            {name: "Commision", selector: "Commision", sortable: true},
            {name: "TDS", selector: "TDS", sortable: true},
            {name: "Net Amount (Payable to Vendor)", selector: "Net_Amount__Payable_to_Vendor_", sortable: true},
            {name: "Itilite Discount", selector: "Itilite_Discount", sortable: true},
            {name: "Markup", selector: "Markup", sortable: true},
            {name: "Total Transaction Amount", selector: "Total_Transaction_Amount", sortable: true},
            {name: "Service Fee", selector: "Service_Fee", sortable: true},
            {name: "GST on SF", selector: "GST_on_SF", sortable: true},
            {name: "TDS on SF", selector: "TDS_on_SF", sortable: true},
            {name: "Net Service Fee", selector: "Net_Service_Fee", sortable: true},
            {name: "Company Balance (with ITILITE) after transaction", selector: "Company_Balance__with_ITILITE__after_transaction", sortable: true},
            {name: "Company Balance (with ITILITE) after transaction V2", selector: "balanceAfterTransactionV2", sortable: true},
            {name: "Amount Received from Client", selector: "Amount_Received_from_Client", sortable: true},
            {name: "GST", selector: "GST", sortable: true},
            {name: "PG charges", selector: "PG_charges", sortable: true},
            {name: "TDS Due", selector: "TDS_Due", sortable: true},
            // {name: "TDS", selector: "TDS", sortable: true},
            {name: "Payment Ref", selector: "Payment_Ref", sortable: true},
            {name: "Payment Date", selector: "Payment_Date", sortable: true},
            {name: "Paid By (ITILITE / CLIENT)", selector: "Paid_By__ITILITE___CLIENT_", sortable: true},
        ],
        vendorDetails: [
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "Vendor", selector: "Vendor", sortable: true},
            {name: "Payment Mode", selector: "Payment_Mode", sortable: true},
            {name: "ITILITE Balance (with Riya/TBO) after transaction", selector: "ITILITE_Balance__with_Riya_TBO__after_transaction", sortable: true},
            {name: "Amount Paid to Vendor", selector: "Amount_Paid_to_Vendor", sortable: true},
            {name: "Vendor code for customer", selector: "Vendor_code_for_customer", sortable: true},
            {name: "Vendor Service fee", selector: "Vendor_Service_fee", sortable: true},
        ],
        payByCardDetails: [
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "Card Payment", selector: "Card_Payment", sortable: true},
            {name: "Card Transaction Id", selector: "Card_Transaction_Id", sortable: true},
            {name: "Amount Showed To Customer", selector: "Amount_Showed_To_Customer", sortable: true},
            {name: "Card Service Charge", selector: "Card_Service_Charge", sortable: true},
            {name: "Card PG Charge", selector: "Card_PG_Charge", sortable: true},
            {name: "Amount charged to Customer", selector: "Amount_charged_to_Customer", sortable: true},
            {name: "Amount Paid By Card", selector: "Amount_Paid_By_Card", sortable: true},
        ],
        invoiceDetails: [
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "ITILITE Invoice", selector: "ITILITE_Invoice__", sortable: true},
            {name: "ITILITE Invoice Date", selector: "ITILITE_Invoice_Date", sortable: true},
            {name: "Airline / Hotel Invoice", selector: "Airline___Hotel_Invoice__", sortable: true},
            {name: "Airline / Hotel Invoice Date", selector: "Airline___Hotel_Invoice_Date", sortable: true},
            {name: "Airline / Hotel Invoice Status", selector: "Airline___Hotel_Invoice_Status", sortable: true},
            {name: "Transaction Invoice No.", selector: "transaction_invoice", sortable: true},
            {name: "Service Fee Invoice No.", selector: "service_fee_invoice", sortable: true},
            {name: "Vendor Invoice No.", selector: "vendor_invoice", sortable: true},
            {name: "Unsigned Invoice No.", selector: "trans_plus_sf_invoice", sortable: true},
            {name: "Signed Invoice No.", selector: "signed_invoice", sortable: true},
        ],
        multicurrencyDetails: [
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "Currency Code", selector: "Currency_Code", sortable: true},
            {name: "Exchange Rate", selector: "Exchange_Rate", sortable: true},
            // {name: "FC Net Amount", selector: "FC_Net_Amount", sortable: true},
            {name: "FC Balance After Transaction", selector: "FC_Balance_After_Transaction", sortable: true},
            {name: "FC Balance After Transaction V2", selector: "fcbalanceaftertransactionV2", sortable: true},
            {name: "FC Transaction Amount", selector: "FC_Transaction_Amount", sortable: true},
            {name: "FC Service Fee", selector: "FC_Service_Fee", sortable: true},
            {name: "FC Gross Amount (To be charged to customer)", selector: "FC_Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "FC Amount Received from Client", selector: "FC_Amount_Received_from_Client", sortable: true},
        ],
        gstDetails: [
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "GST", selector: "GST", sortable: true},
            {name: "State of Departure / Hotel", selector: "State_of_Departure___Hotel", sortable: true},
            {name: "Vendor Tax Type - IGST- CGST- UTGST", selector: "Vendor_Tax_Type_-_IGST-_CGST-_UTGST", sortable: true},
            {name: "Vendor Tax Amount - IGST", selector: "Vendor_Tax_Amount_-_IGST", sortable: true},
            {name: "Vendor Tax Amount - CGST", selector: "Vendor_Tax_Amount_-_CGST", sortable: true},
            {name: "Vendor Tax Amount - SGST", selector: "Vendor_Tax_Amount_-_SGST", sortable: true},
            {name: "Vendor Tax Amount - UTGST", selector: "Vendor_Tax_Amount_-_UTGST", sortable: true},
        ],
        others: [
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "Index", selector: "Index", sortable: true},
            {name: "Date Updated", selector: "Date_Updated", sortable: true},
            {name: "Entity", selector: "Entity", sortable: true},
            {name: "Department", selector: "Department", sortable: true},
            {name: "Emp ID", selector: "Emp_ID", sortable: true},
        ],
        bbtView: [
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Depature Date", selector: "Departure_Date", sortable: true},
            {name: "Depature Time", selector: "Departure_Time", sortable: true},
            {name: "Arrival Date", selector: "Arrival_Date", sortable: true},
            {name: "Arrival Time", selector: "Arrival_Time", sortable: true},
            {name: "Type Of Travel", selector: "Type_Of_Travel", sortable: true},
            {name: "From", selector: "From", sortable: true},
            {name: "To", selector: "To", sortable: true},
            {name: "Oneway / Return", selector: "Oneway___Return", sortable: true},
            {name: "Airline", selector: "Airline", sortable: true},
            {name: "Flight No", selector: "Flight_No_", sortable: true},
            {name: "Class of Travel", selector: "Class_of_Travel", sortable: true},
            {name: "Class", selector: "Class", sortable: true},
            {name: "Riya PNR", selector: "Riya_PNR", sortable: true},
            {name: "Airline PNR", selector: "Airline_PNR", sortable: true},
            {name: "CRS PNR", selector: "CRS_PNR", sortable: true},
            {name: "Ticket No", selector: "Ticket_No_", sortable: true},
            {name: "Check In Date", selector: "Check_In_Date", sortable: true},
            {name: "Checkout Date", selector: "Check_Out_Date", sortable: true},
            {name: "Total Nights", selector: "Total_Nights", sortable: true},
            {name: "Rate per Night", selector: "Rate_per_Night", sortable: true},
            {name: "Property", selector: "Property", sortable: true},
            {name: "City", selector: "City", sortable: true},
            {name: "Total Basic", selector: "Total_Basic", sortable: true},
            {name: "Taxes and Other Charges", selector: "Taxes_and_Other_Charges", sortable: true},
            {name: "Meals Amount", selector: "Meals_Amount", sortable: true},
            {name: "Baggage Amount", selector: "Baggage_Amount", sortable: true},
            {name: "Discount", selector: "Discount", sortable: true},
            {name: "Cancellation / Reschedule Charges", selector: "Cancellation___Reschedule_Charges", sortable: true},
            {name: "Gross Amount (Ticket value pre mark-up)", selector: "Gross_Amount__Ticket_value_pre_mark-up_", sortable: true},
            {name: "Commision", selector: "Commision", sortable: true},
            {name: "TDS", selector: "TDS", sortable: true},
            {name: "Net Amount (Payable to Vendor)", selector: "Net_Amount__Payable_to_Vendor_", sortable: true},
            {name: "Itilite Discount", selector: "Itilite_Discount", sortable: true},
            {name: "Markup", selector: "Markup", sortable: true},
            {name: "Total Transaction Amount", selector: "Total_Transaction_Amount", sortable: true},
            {name: "Service Fee", selector: "Service_Fee", sortable: true},
            {name: "GST on SF", selector: "GST_on_SF", sortable: true},
            {name: "TDS on SF", selector: "TDS_on_SF", sortable: true},
            {name: "Net Service Fee", selector: "Net_Service_Fee", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "Index", selector: "Index", sortable: true},
            {name: "Vendor", selector: "Vendor", sortable: true},
            {name: "Payment Mode", selector: "Payment_Mode", sortable: true},
            {name: "Company Balance (with ITILITE) after transaction", selector: "Company_Balance__with_ITILITE__after_transaction", sortable: true},
            {name: "ITILITE Balance (with Riya/TBO) after transaction", selector: "ITILITE_Balance__with_Riya_TBO__after_transaction", sortable: true},
            {name: "Amount Received from Client", selector: "Amount_Received_from_Client", sortable: true},
            {name: "Amount Paid to Vendor", selector: "Amount_Paid_to_Vendor", sortable: true},
            {name: "Remarks", selector: "Remarks", sortable: true, wrap: true, format: row => `${row.Remarks && row.Remarks.length > 35 ? row.Remarks.slice(0, 40)+'...' : row.Remarks}`,},
            {name: "Date Updated", selector: "Date_Updated", sortable: true},
            {name: "GST", selector: "GST", sortable: true},
            {name: "Entity", selector: "Entity", sortable: true},
            {name: "PG charges", selector: "PG_charges", sortable: true},
            {name: "Department", selector: "Department", sortable: true},
            {name: "Emp ID", selector: "Emp_ID", sortable: true},
            {name: "TDS Due", selector: "TDS_Due", sortable: true},
            {name: "Agent/ Reseller", selector: "Agent__Reseller", sortable: true},
            {name: "Vendor code for customer", selector: "Vendor_code_for_customer", sortable: true},
            {name: "Automated/ Manual Booking", selector: "Automated__Manual_Booking", sortable: true},
            {name: "Vendor Service fee", selector: "Vendor_Service_fee", sortable: true},
            {name: "TDS", selector: "TDS", sortable: true},
            {name: "Card Payment", selector: "Card_Payment", sortable: true},
            {name: "Card Transaction Id", selector: "Card_Transaction_Id", sortable: true},
            {name: "Amount Showed To Customer", selector: "Amount_Showed_To_Customer", sortable: true},
            {name: "Card Service Charge", selector: "Card_Service_Charge", sortable: true},
            {name: "Card PG Charge", selector: "Card_PG_Charge", sortable: true},
            {name: "Amount charged to Customer", selector: "Amount_charged_to_Customer", sortable: true},
            {name: "Amount Paid By Card", selector: "Amount_Paid_By_Card", sortable: true},
            {name: "Currency Code", selector: "Currency_Code", sortable: true},
            {name: "Exchange Rate", selector: "Exchange_Rate", sortable: true},
            {name: "FC Transaction Amount", selector: "FC_Transaction_Amount", sortable: true},
            {name: "FC Service Fee", selector: "FC_Service_Fee", sortable: true},
            {name: "FC Gross Amount (To be charged to customer)", selector: "FC_Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "FC Balance After Transaction", selector: "FC_Balance_After_Transaction", sortable: true},
            {name: "FC Amount Received from Client", selector: "FC_Amount_Received_from_Client", sortable: true},
            {name: "ITILITE Invoice", selector: "ITILITE_Invoice__", sortable: true},
            {name: "ITILITE Invoice Date", selector: "ITILITE_Invoice_Date", sortable: true},
            {name: "Airline / Hotel Invoice", selector: "Airline___Hotel_Invoice__", sortable: true},
            {name: "Airline / Hotel Invoice Date", selector: "Airline___Hotel_Invoice_Date", sortable: true},
            {name: "Airline / Hotel Invoice Status", selector: "Airline___Hotel_Invoice_Status", sortable: true},
            {name: "State of Departure / Hotel", selector: "State_of_Departure___Hotel", sortable: true},
            {name: "Vendor Tax Type - IGST- CGST- UTGST", selector: "Vendor_Tax_Type_-_IGST-_CGST-_UTGST", sortable: true},
            {name: "Vendor Tax Amount - IGST", selector: "Vendor_Tax_Amount_-_IGST", sortable: true},
            {name: "Vendor Tax Amount - CGST", selector: "Vendor_Tax_Amount_-_CGST", sortable: true},
            {name: "Vendor Tax Amount - SGST", selector: "Vendor_Tax_Amount_-_SGST", sortable: true},
            {name: "Vendor Tax Amount - UTGST", selector: "Vendor_Tax_Amount_-_UTGST", sortable: true},
            {name: "Payment Ref", selector: "Payment_Ref", sortable: true},
            {name: "Payment Date", selector: "Payment_Date", sortable: true},
            {name: "Paid By (ITILITE / CLIENT)", selector: "Paid_By__ITILITE___CLIENT_", sortable: true},
            {name: "Traveler Email Id", selector: "Traveler_Email-Id", sortable: true},
            {name: "Trip Creation Date", selector: "Trip_Creation_Date", sortable: true},
            {name: "Parent Company", selector: "Parent_Company", sortable: true},
            {name: "Testing", selector: "Testing", sortable: true},
            {name: "Classification", selector: "Classification", sortable: true},
            {name: "Total Transaction Amount in User Currency", selector: "Total_Transaction_Amount_in_User_Currency", sortable: true},
            {name: "Vendor For RR", selector: "Vendor_For_RR", sortable: true},
            {name: "FC Cancellation / Reschedule Charges", selector: "FC_Cancellation___Reschedule_Charges", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Unused Credit Applied", selector: "Unused_credit_applied", sortable: true},
            {name: "Credit Change Fee", selector: "Credit_change_fee", sortable: true},
            {name: "Unused Credit Source Booking ID", selector: "Ununsed_credit_source_booking_ID", sortable: true},
            {name: "Unused Credit Source Trip ID", selector: "Unused_credit_source_trip_ID", sortable: true},
            {name: "Service Fee for RR", selector: "Service_fee_for_RR", sortable: true},
            {name: "Total Commission for RR", selector: "Total_Commission_for_RR", sortable: true},
            {name: "Input Tax (Staybazar and ITILITE)", selector: "Input_Tax__Staybazar_and_ITILITE_", sortable: true},
            {name: "StayBazar Cost", selector: "StayBazar_Cost", sortable: true},
            {name: "Spice Jet and Indigo PLB", selector: "Spice_Jet_and_Indigo_PLB", sortable: true},
            {name: "Gal Segment Fee", selector: "Gal_Segment_fee", sortable: true},
            {name: "Vistara PLB", selector: "Vistara_PLB", sortable: true},
            {name: "Amex Cash Back", selector: "Amex_Cash_Back", sortable: true},
            {name: "Kodo CashBack", selector: "Kodo_CashBack", sortable: true},
            {name: "PriceLine Hotel and Flight", selector: "PriceLine_Hotel__Flight", sortable: true},
            {name: "SBI CB", selector: "SBI_CB", sortable: true},
            {name: "Lufthansa, SQ and AI Via GDS", selector: "Lufthansa__SQ_and_AI_Via_GDS", sortable: true},
            {name: "Indusind", selector: "Indusind", sortable: true},
            {name: "Total comm plus other incentives", selector: "Total_comm_plus_other_incentives", sortable: true},
            {name: "Error Message", selector: "Error_Message", sortable: true},
            {name: "Error Timestamp", selector: "Error_Timestamp", sortable: true}
        ],
        showAll: [
            // Default
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            // Booking Details
            {name: "Depature Date", selector: "Departure_Date", sortable: true},
            {name: "Depature Time", selector: "Departure_Time", sortable: true},
            {name: "Arrival Date", selector: "Arrival_Date", sortable: true},
            {name: "Arrival Time", selector: "Arrival_Time", sortable: true},
            {name: "Type Of Travel", selector: "Type_Of_Travel", sortable: true},
            {name: "From", selector: "From", sortable: true},
            {name: "To", selector: "To", sortable: true},
            {name: "Oneway / Return", selector: "Oneway___Return", sortable: true},
            {name: "Airline", selector: "Airline", sortable: true},
            {name: "Flight No", selector: "Flight_No_", sortable: true},
            {name: "Class of Travel", selector: "Class_of_Travel", sortable: true},
            {name: "Class", selector: "Class", sortable: true},
            {name: "Riya PNR", selector: "Riya_PNR", sortable: true},
            {name: "Airline PNR", selector: "Airline_PNR", sortable: true},
            {name: "CRS PNR", selector: "CRS_PNR", sortable: true},
            {name: "Ticket No", selector: "Ticket_No_", sortable: true},
            {name: "Check In Date", selector: "Check_In_Date", sortable: true},
            {name: "Checkout Date", selector: "Check_Out_Date", sortable: true},
            {name: "Total Nights", selector: "Total_Nights", sortable: true},
            {name: "Rate per Night", selector: "Rate_per_Night", sortable: true},
            {name: "Property", selector: "Property", sortable: true},
            {name: "City", selector: "City", sortable: true},

            // Transaction 
            {name: "Total Basic", selector: "Total_Basic", sortable: true},
            {name: "Taxes and Other Charges", selector: "Taxes_and_Other_Charges", sortable: true},
            {name: "Meals Amount", selector: "Meals_Amount", sortable: true},
            {name: "Baggage Amount", selector: "Baggage_Amount", sortable: true},
            {name: "Discount", selector: "Discount", sortable: true},
            {name: "Cancellation / Reschedule Charges", selector: "Cancellation___Reschedule_Charges", sortable: true},
            {name: "Gross Amount (Ticket value pre mark-up)", selector: "Gross_Amount__Ticket_value_pre_mark-up_", sortable: true},
            {name: "Commision", selector: "Commision", sortable: true},
            {name: "TDS", selector: "TDS", sortable: true},
            {name: "Net Amount (Payable to Vendor)", selector: "Net_Amount__Payable_to_Vendor_", sortable: true},
            {name: "Itilite Discount", selector: "Itilite_Discount", sortable: true},
            {name: "Markup", selector: "Markup", sortable: true},
            // Added new cols
            {name: "Total Transaction Amount", selector: "Total_Transaction_Amount", sortable: true},
            {name: "Service Fee", selector: "Service_Fee", sortable: true},
            {name: "GST on SF", selector: "GST_on_SF", sortable: true},
            {name: "TDS on SF", selector: "TDS_on_SF", sortable: true},
            {name: "Net Service Fee", selector: "Net_Service_Fee", sortable: true},
            // gross amount to charged to customer
            {name: "Index", selector: "Index", sortable: true},

            // vendor 
            {name: "Vendor", selector: "Vendor", sortable: true},
            {name: "Payment Mode", selector: "Payment_Mode", sortable: true},
            {name: "Company Balance (with ITILITE) after transaction", selector: "Company_Balance__with_ITILITE__after_transaction", sortable: true},
            {name: "Company Balance (with ITILITE) after transaction V2", selector: "balanceAfterTransactionV2", sortable: true},
            {name: "ITILITE Balance (with Riya/TBO) after transaction", selector: "ITILITE_Balance__with_Riya_TBO__after_transaction", sortable: true},
            {name: "Amount Received from Client", selector: "Amount_Received_from_Client", sortable: true},
            {name: "Amount Paid to Vendor", selector: "Amount_Paid_to_Vendor", sortable: true},
            {name: "Remarks", selector: "Remarks", sortable: true, wrap: true, format: row => `${row.Remarks && row.Remarks.length > 35 ? row.Remarks.slice(0, 40)+'...' : row.Remarks}`,},
            {name: "Date Updated", selector: "Date_Updated", sortable: true},
            {name: "GST", selector: "GST", sortable: true},
            {name: "Entity", selector: "Entity", sortable: true},
            {name: "PG charges", selector: "PG_charges", sortable: true},
            {name: "Department", selector: "Department", sortable: true},
            {name: "Emp ID", selector: "Emp_ID", sortable: true}, 
            {name: "TDS Due", selector: "TDS_Due", sortable: true},
            {name: "Agent/ Reseller", selector: "Agent__Reseller", sortable: true},
            {name: "Classification", selector: "Classification", sortable: true},
            {name: "Vendor code for customer", selector: "Vendor_code_for_customer", sortable: true},
            {name: "Automated/ Manual Booking", selector: "Automated__Manual_Booking", sortable: true},
            {name: "Vendor Service fee", selector: "Vendor_Service_fee", sortable: true},
            {name: "TDS", selector: "TDS", sortable: true},
            {name: "Card Payment", selector: "Card_Payment", sortable: true},

            // paybycard details
            {name: "Card Transaction Id", selector: "Card_Transaction_Id", sortable: true},
            {name: "Amount Showed To Customer", selector: "Amount_Showed_To_Customer", sortable: true},
            {name: "Card Service Charge", selector: "Card_Service_Charge", sortable: true},
            {name: "Card PG Charge", selector: "Card_PG_Charge", sortable: true},
            {name: "Amount charged to Customer", selector: "Amount_charged_to_Customer", sortable: true},
            {name: "Amount Paid By Card", selector: "Amount_Paid_By_Card", sortable: true},
            {name: "Currency Code", selector: "Currency_Code", sortable: true},
            {name: "Exchange Rate", selector: "Exchange_Rate", sortable: true},
            {name: "FC Balance After Transaction", selector: "FC_Balance_After_Transaction", sortable: true},
            {name: "FC Balance After Transaction V2", selector: "fcbalanceaftertransactionV2", sortable: true},
            {name: "FC Transaction Amount", selector: "FC_Transaction_Amount", sortable: true},
            {name: "FC Service Fee", selector: "FC_Service_Fee", sortable: true},
            {name: "FC Gross Amount (To be charged to customer)", selector: "FC_Gross_Amount___To_be_charged_to_customer_", sortable: true},
            {name: "FC Amount Received from Client", selector: "FC_Amount_Received_from_Client", sortable: true},

            {name: "Total Transaction Amount in User Currency", selector: "Total_Transaction_Amount_in_User_Currency", sortable: true},

            // invoice details
            {name: "ITILITE Invoice", selector: "ITILITE_Invoice__", sortable: true},
            {name: "ITILITE Invoice Date", selector: "ITILITE_Invoice_Date", sortable: true},
            {name: "Airline / Hotel Invoice", selector: "Airline___Hotel_Invoice__", sortable: true},
            {name: "Airline / Hotel Invoice Date", selector: "Airline___Hotel_Invoice_Date", sortable: true},
            {name: "Airline / Hotel Invoice Status", selector: "Airline___Hotel_Invoice_Status", sortable: true},

            // gstDetails
            {name: "State of Departure / Hotel", selector: "State_of_Departure___Hotel", sortable: true},
            {name: "Vendor Tax Type - IGST- CGST- UTGST", selector: "Vendor_Tax_Type_-_IGST-_CGST-_UTGST", sortable: true},
            {name: "Vendor Tax Amount - IGST", selector: "Vendor_Tax_Amount_-_IGST", sortable: true},
            {name: "Vendor Tax Amount - CGST", selector: "Vendor_Tax_Amount_-_CGST", sortable: true},
            {name: "Vendor Tax Amount - SGST", selector: "Vendor_Tax_Amount_-_SGST", sortable: true},
            {name: "Vendor Tax Amount - UTGST", selector: "Vendor_Tax_Amount_-_UTGST", sortable: true},
            {name: "Payment Ref", selector: "Payment_Ref", sortable: true},
            {name: "Payment Date", selector: "Payment_Date", sortable: true},
            {name: "Paid By (ITILITE / CLIENT)", selector: "Paid_By__ITILITE___CLIENT_", sortable: true},


            {name: "Traveler Email Id", selector: "Traveler_Email-Id", sortable: true},
            {name: "Trip Creation Date", selector: "Trip_Creation_Date", sortable: true},
            {name: "Parent Company", selector: "Parent_Company", sortable: true},

            {name: "Unused Credit Applied", selector: "Unused_credit_applied", sortable: true},
            {name: "Credit Change Fee", selector: "Credit_change_fee", sortable: true},
            {name: "Unused Credit Source Booking ID", selector: "Ununsed_credit_source_booking_ID", sortable: true},
            {name: "Unused Credit Source Trip ID", selector: "Unused_credit_source_trip_ID", sortable: true},
            {name: "Input Tax (Staybazar and ITILITE)", selector: "Input_Tax__Staybazar_and_ITILITE_", sortable: true},
            {name: "StayBazar Cost", selector: "StayBazar_Cost", sortable: true},
            {name: "Spice Jet and Indigo PLB", selector: "Spice_Jet_and_Indigo_PLB", sortable: true},
            {name: "Gal Segment Fee", selector: "Gal_Segment_fee", sortable: true},
            {name: "Vistara PLB", selector: "Vistara_PLB", sortable: true},
            {name: "Amex Cash Back", selector: "Amex_Cash_Back", sortable: true},
            {name: "Kodo CashBack", selector: "Kodo_CashBack", sortable: true},
            {name: "PriceLine Hotel and Flight", selector: "PriceLine_Hotel__Flight", sortable: true},
            {name: "SBI CB", selector: "SBI_CB", sortable: true},
            {name: "Service Fee for RR", selector: "Service_fee_for_RR", sortable: true},
            {name: "Total Commission for RR", selector: "Total_Commission_for_RR", sortable: true},
            {name: "Lufthansa, SQ and AI Via GDS", selector: "Lufthansa__SQ_and_AI_Via_GDS", sortable: true},
            {name: "Total comm plus other incentives", selector: "Total_comm_plus_other_incentives", sortable: true},
            {name: "Indusind", selector: "Indusind", sortable: true},
            {name: "Transaction BBT Version", selector: "transaction_bbt_version", sortable: true},
            {name: "TTR to BBT Automated", selector: "is_automated", sortable: true},
            {name: "Automated", selector: "automated", sortable: true},
            {name: "SBT Automated", selector: "sbt_automated", sortable: true},

            //invoice details
            {name: "Transaction Invoice No.", selector: "transaction_invoice", sortable: true},
            {name: "Service Fee Invoice No.", selector: "service_fee_invoice", sortable: true},
            {name: "Vendor Invoice No.", selector: "vendor_invoice", sortable: true},
            {name: "Unsigned Invoice No.", selector: "trans_plus_sf_invoice", sortable: true},
            {name: "Signed Invoice No.", selector: "signed_invoice", sortable: true},
        ],
        customView: [
            {name: "Billing Sl No", selector: "id", sortable: true},
            {name: "Sl No", selector: "SL_NO", sortable: true},
            {name: "G Sl No", selector: "gseqnumber", sortable: true},
            {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
            {name: "Company", selector: "Company", sortable: true},
            {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
            {name: "Status", selector: "Status", sortable: true},
            {name: "Booking ID", selector: "Booking_ID", sortable: true},
            {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
            {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
            {name: "Options", selector: "Options", sortable: true},
            {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
            {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
            // {name: "Department", selector: "Department", sortable: true},
            // {name: "Emp ID", selector: "Emp_ID", sortable: true},
            // {name: "Amount Received from Client", selector: "Amount_Received_from_Client", sortable: true},
            // {name: "City", selector: "City", sortable: true},
        ],
    },
    // test
    colNames: [],
    colSelectors: [],
    selectedColumns: [
        {name: "Billing Sl No", selector: "id", sortable: true},
        {name: "Sl No", selector: "SL_NO", sortable: true},
        {name: "G Sl No", selector: "gseqnumber", sortable: true},
        {name: "TTR SL No", selector: "TTR_SL_NO", sortable: true},
        {name: "Company", selector: "Company", sortable: true},
        {name: "Trip Id", selector: "ITILITE_website_Trip_ID", sortable: true},
        {name: "Status", selector: "Status", sortable: true},
        {name: "Booking ID", selector: "Booking_ID", sortable: true},
        {name: "Employee First Name", selector: "Employee_First_Name", sortable: true},
        {name: "Employee Last Name", selector: "Employee_Last_Name", sortable: true},
        {name: "Options", selector: "Options", sortable: true},
        {name: "Date Of Transaction", selector: "Date_of_Transaction", sortable: true},
        {name: "Gross Amount To be charged to customer", selector: "Gross_Amount___To_be_charged_to_customer_", sortable: true},
    ],
    availableColumns: {
        bookingDetails: [
            {name: "Depature Date", selector: "Departure_Date", sortable: true},
            {name: "Depature Time", selector: "Departure_Time", sortable: true},
            {name: "Arrival Date", selector: "Arrival_Date", sortable: true},
            {name: "Arrival Time", selector: "Arrival_Time", sortable: true},
            {name: "Type Of Travel", selector: "Type_Of_Travel", sortable: true},
            {name: "From", selector: "From", sortable: true},
            {name: "To", selector: "To", sortable: true},
            {name: "Oneway / Return", selector: "Oneway___Return", sortable: true},
            {name: "Airline", selector: "Airline", sortable: true},
            {name: "Flight No", selector: "Flight_No_", sortable: true},
            {name: "Class of Travel", selector: "Class_of_Travel", sortable: true},
            {name: "Class", selector: "Class", sortable: true},
            {name: "Riya PNR", selector: "Riya_PNR", sortable: true},
            {name: "Airline PNR", selector: "Airline_PNR", sortable: true},
            {name: "CRS PNR", selector: "CRS_PNR", sortable: true},
            {name: "Ticket No", selector: "Ticket_No_", sortable: true},
            {name: "Check In Date", selector: "Check_In_Date", sortable: true},
            {name: "Checkout Date", selector: "Check_Out_Date", sortable: true},
            {name: "Total Nights", selector: "Total_Nights", sortable: true},
            {name: "Rate per Night", selector: "Rate_per_Night", sortable: true},
            {name: "Property", selector: "Property", sortable: true},
            {name: "City", selector: "City", sortable: true},
            {name: "Remarks", selector: "Remarks", sortable: true, wrap: true, format: row => `${row.Remarks && row.Remarks.length > 35 ? row.Remarks.slice(0, 40)+'...' : row.Remarks}`,},
            {name: "Agent/ Reseller", selector: "Agent__Reseller", sortable: true},
            {name: "Automated/ Manual Booking", selector: "Automated__Manual_Booking", sortable: true},
        ],
        transactionDetails: [
            {name: "Total Basic", selector: "Total_Basic", sortable: true},
            {name: "Taxes and Other Charges", selector: "Taxes_and_Other_Charges", sortable: true},
            {name: "Meals Amount", selector: "Meals_Amount", sortable: true},
            {name: "Baggage Amount", selector: "Baggage_Amount", sortable: true},
            {name: "Discount", selector: "Discount", sortable: true},
            {name: "Cancellation / Reschedule Charges", selector: "Cancellation___Reschedule_Charges", sortable: true},
            {name: "Gross Amount (Ticket value pre mark-up)", selector: "Gross_Amount__Ticket_value_pre_mark-up_", sortable: true},
            {name: "Commision", selector: "Commision", sortable: true},
            {name: "TDS", selector: "TDS", sortable: true},
            {name: "Net Amount (Payable to Vendor)", selector: "Net_Amount__Payable_to_Vendor_", sortable: true},
            {name: "Itilite Discount", selector: "Itilite_Discount", sortable: true},
            {name: "Markup", selector: "Markup", sortable: true},
            {name: "Total Transaction Amount", selector: "Total_Transaction_Amount", sortable: true},
            {name: "Service Fee", selector: "Service_Fee", sortable: true},
            {name: "GST on SF", selector: "GST_on_SF", sortable: true},
            {name: "TDS on SF", selector: "TDS_on_SF", sortable: true},
            {name: "Net Service Fee", selector: "Net_Service_Fee", sortable: true},
            {name: "Company Balance (with ITILITE) after transaction", selector: "Company_Balance__with_ITILITE__after_transaction", sortable: true},
            {name: "Company Balance (with ITILITE) after transaction V2", selector: "balanceAfterTransactionV2", sortable: true},
            {name: "Amount Received from Client", selector: "Amount_Received_from_Client", sortable: true},
            {name: "GST", selector: "GST", sortable: true},
            {name: "PG charges", selector: "PG_charges", sortable: true},
            {name: "TDS Due", selector: "TDS_Due", sortable: true},
            // {name: "TDS", selector: "TDS", sortable: true},
            {name: "Payment Ref", selector: "Payment_Ref", sortable: true},
            {name: "Payment Date", selector: "Payment_Date", sortable: true},
            {name: "Paid By (ITILITE / CLIENT)", selector: "Paid_By__ITILITE___CLIENT_", sortable: true},
        ],
        vendorDetails: [
            {name: "Vendor", selector: "Vendor", sortable: true},
            {name: "Payment Mode", selector: "Payment_Mode", sortable: true},
            {name: "ITILITE Balance (with Riya/TBO) after transaction", selector: "ITILITE_Balance__with_Riya_TBO__after_transaction", sortable: true},
            {name: "Amount Paid to Vendor", selector: "Amount_Paid_to_Vendor", sortable: true},
            {name: "Vendor code for customer", selector: "Vendor_code_for_customer", sortable: true},
            {name: "Vendor Service fee", selector: "Vendor_Service_fee", sortable: true},
        ],
        payByCardDetails: [
            {name: "Card Payment", selector: "Card_Payment", sortable: true},
            {name: "Card Transaction Id", selector: "Card_Transaction_Id", sortable: true},
            {name: "Amount Showed To Customer", selector: "Amount_Showed_To_Customer", sortable: true},
            {name: "Card Service Charge", selector: "Card_Service_Charge", sortable: true},
            {name: "Card PG Charge", selector: "Card_PG_Charge", sortable: true},
            {name: "Amount charged to Customer", selector: "Amount_charged_to_Customer", sortable: true},
            {name: "Amount Paid By Card", selector: "Amount_Paid_By_Card", sortable: true},
        ],
        invoiceDetails: [
            {name: "ITILITE Invoice", selector: "ITILITE_Invoice__", sortable: true},
            {name: "ITILITE Invoice Date", selector: "ITILITE_Invoice_Date", sortable: true},
            {name: "Airline / Hotel Invoice", selector: "Airline___Hotel_Invoice__", sortable: true},
            {name: "Airline / Hotel Invoice Date", selector: "Airline___Hotel_Invoice_Date", sortable: true},
            {name: "Airline / Hotel Invoice Status", selector: "Airline___Hotel_Invoice_Status", sortable: true},
        ],
        multicurrencyDetails: [
            {name: "Currency Code", selector: "Currency_Code", sortable: true},
            {name: "Exchange Rate", selector: "Exchange_Rate", sortable: true},
            // {name: "FC Net Amount", selector: "FC_Net_Amount", sortable: true},
            {name: "FC Balance After Transaction", selector: "FC_Balance_After_Transaction", sortable: true},
            {name: "FC Balance After Transaction V2", selector: "fcbalanceaftertransactionV2", sortable: true},
            {name: "FC Transaction Amount", selector: "FC_Transaction_Amount", sortable: true},
            {name: "FC Service Fee", selector: "FC_Service_Fee", sortable: true},
            {name: "FC Gross Amount (To be charged to customer)", selector: "FC_Gross_Amount__(To_be_charged_to_customer)", sortable: true},
            {name: "FC Amount Received from Client", selector: "FC_Amount_Received_from_Client", sortable: true},
        ],
        gstDetails: [
            {name: "GST", selector: "GST", sortable: true},
            {name: "State of Departure / Hotel", selector: "State_of_Departure___Hotel", sortable: true},
            {name: "Vendor Tax Type - IGST- CGST- UTGST", selector: "Vendor_Tax_Type_-_IGST-_CGST-_UTGST", sortable: true},
            {name: "Vendor Tax Amount - IGST", selector: "Vendor_Tax_Amount_-_IGST", sortable: true},
            {name: "Vendor Tax Amount - CGST", selector: "Vendor_Tax_Amount_-_CGST", sortable: true},
            {name: "Vendor Tax Amount - SGST", selector: "Vendor_Tax_Amount_-_SGST", sortable: true},
            {name: "Vendor Tax Amount - UTGST", selector: "Vendor_Tax_Amount_-_UTGST", sortable: true},
        ],
        others: [
            {name: "Index", selector: "Index", sortable: true},
            {name: "Date Updated", selector: "Date_Updated", sortable: true},
            {name: "Entity", selector: "Entity", sortable: true},
            {name: "Department", selector: "Department", sortable: true},
            {name: "Emp ID", selector: "Emp_ID", sortable: true},
        ]
    },
    downloadData: []
}

export default function(state = initialState, action) {
    const { type, payload } = action
    // console.log('action-->', action, 'payload--->',payload)

    switch(type) {
        case SELECT_VIEW_SUCCESS:
            // console.log('selectView', payload)
            let tableColumn = []

            if (payload === 'default') {
                tableColumn = state.selectView.default;
            } else if (payload === 'bookingDetails') {
                tableColumn = state.selectView.bookingDetails;
            } else if (payload === 'transactionDetails') {
                tableColumn = state.selectView.transactionDetails
            }else if (payload === 'vendorDetails') {
                tableColumn = state.selectView.vendorDetails;
            } else if (payload === 'paybycardDetails') {
                tableColumn = state.selectView.payByCardDetails;
            } else if (payload === 'invoiceDetails') {
                tableColumn = state.selectView.invoiceDetails;
            } else if (payload === 'gstDetails') {
                tableColumn = state.selectView.gstDetails;
            } else if (payload === 'multicurrencyDetails') {
                tableColumn = state.selectView.multicurrencyDetails;
            } else if (payload === 'bbtView') {
                tableColumn = state.selectView.bbtView;
            } else if (payload === 'others') {
                tableColumn = state.selectView.others;
            } else if (payload === 'showAll') {
                tableColumn = state.selectView.showAll

                // dynamic columns but not in sorted 
                // let _showAll = [...state.selectView.default]
                // let _showAllCols = []
                // for (let i = 0; i < _showAll.length; i++) {
                //     _showAllCols.push(_showAll[i].selector)
                // }
                
                // for (let i = 0; i < state.allColumns.length; i++) {
                //     if (_showAllCols.includes(state.allColumns[i].selector)) {
                //         continue
                //     } else {
                //         _showAll.push(state.allColumns[i])
                //     }
                // }

                
                // tableColumn = _showAll;
                // console.log("showall", _showAll, tableColumn)
            } else {
                // tableColumn = state.selectView.customView;
                // console.log('selectView custom', state.selectView.customView)
            }

            return {
                ...state,
                columns: tableColumn,
                // downloadData: [...filteredData]
            }

        case CUSTOM_VIEW_SUCCESS:
            // let _selectView = state.selectView.customView.push(payload) 
            // console.log(state.selectView.customView, state.selectView)
        
            let _selectedColumns = state.selectedColumns
            _selectedColumns.push(payload)

            let _availableColumns = state.availableColumns
            for (let key in _availableColumns) {
                if (key === payload.key) {
                    for (let i = 0; i < _availableColumns[key].length; i++) {
                        if (_availableColumns[key][i].selector === payload.selector) {
                            _availableColumns[key].splice(i, 1)
                            // delete _availableColumns[key][i]
                        }
                    }
                    // console.log(_availableColumns[key])
                    // console.log('key -->', key, 'cols--->', _availableColumns[key])
                }
            }
            // console.log(_selectedColumns, _availableColumns)

            return {
                ...state,
                selectedColumns: _selectedColumns,
                availableColumns: _availableColumns
                // selectView: s_selectView
                // columns: state.selectView.customView
            }

        case CUSTOM_COLUMN_REMOVE:
            let tempSelectedColumns = state.selectedColumns;
            let tempAvailableColumns = state.availableColumns

            // remove cols
            for (let i = 0; i < tempSelectedColumns.length; i++) {
                if (tempSelectedColumns[i].selector === payload.selector) {
                    tempSelectedColumns.splice(i, 1)
                    // break;
                }
            }

            for (let key in tempAvailableColumns) {
                if (key === payload.key) {
                    for (let i = 0; i < tempAvailableColumns[key].length; i++) {
                        // console.log(tempAvailableColumns[key][i].selector, payload.selector)
                        // if (tempAvailableColumns[key][i].selector === payload.selector) {
                            tempAvailableColumns[key].push(payload);
                            break;
                        // }
                    }
                }
            }

            // console.log(tempAvailableColumns, tempSelectedColumns)

            return {
                ...state,
                selectedColumns: tempSelectedColumns,
                availableColumns: tempAvailableColumns
            }

        case CUSTOM_VIEW_APPLY:
            return {
                ...state,
                // columns: state.selectView.customView
                columns: state.selectedColumns
            }

        case FETCHING_TABLE_DATA_SUCCESS:
        case BASIC_SEARCH_SUCCESS:
            function removeSpecialChar(str, seprator) {
                return str = str.replace(/[&\/\\#, +()$~%.'":*?<>{}_]/g, seprator);
            }
            
            let cols = Object.keys(payload.results[0])
            let colName = []
            let colSelector = []
            for (let i = 0; i < cols.length; i++) {
                colSelector.push(removeSpecialChar(cols[i], '_'))
                colName.push(removeSpecialChar(cols[i], ' ').split(' ').filter(char => char !== '').join(' '))
            }

            let tableColumns = []
            for(let i = 0 ; i < colName.length && i < colSelector.length; i++) {
                tableColumns.push({
                    name: colName[i],
                    selector: colSelector[i],
                    sortable: true,
                })
            }
            
            let tempData = []
            for (let i = 0; i < payload.results.length; i++) {
                let obj = {}
                for (let key in payload.results[i]) {
                    let newKey = removeSpecialChar(key, '_')
                     if (key === "invoice_details") {
                        obj['vendor_invoice'] = payload.results[i][key][0]['vendor']['invoice_number']
                        obj['transaction_invoice'] = payload.results[i][key][1]['transaction']['invoice_number']
                        obj['service_fee_invoice']  = payload.results[i][key][2]['service_fee']['invoice_number']
                        obj['trans_plus_sf_invoice']  = payload.results[i][key][3]['trans_plus_sf']['invoice_number']
                        obj['signed_invoice']  = payload.results[i][key][4]['signed']['invoice_number']
                    }
                    else{
                        obj[newKey] = payload.results[i][key]
                    }
                }
                tempData.push(obj)
            }
            
            
            // Adding the remaining column to other view
            let _othersSelector = []
            for (let key in state.selectView) {
                if (key === 'customView' || key === 'showAll') {
                    continue
                }
                for (let i = 0; i < state.selectView[key].length; i++) {
                    if (_othersSelector.includes(state.selectView[key][i].selector)) {
                        continue
                    } else {
                        _othersSelector.push(state.selectView[key][i].selector)
                    }
                }
            }

            let _othersView = []
            for (let i = 0; i < tableColumns.length; i++) {
                if (_othersSelector.includes(tableColumns[i].selector)) {
                    continue
                } else if (tableColumns[i].selector === "Test_trip") {
                    continue
                }else {
                    _othersView.push(tableColumns[i])
                }
            }

            let duplicateOthers = [...state.selectView.others]
            duplicateOthers = [...duplicateOthers, ..._othersView]
            
            return {
                ...state,
                data: tempData,
                loading: false,
                // columns: tableColumns,
                allColumns: tableColumns,
                // test
                colNames: colName,
                colSelectors: colSelector,
                selectView: {...state.selectView, others: [...duplicateOthers]},
                availableColumns: {...state.availableColumns, others: [...state.availableColumns.others, ..._othersView]}
                // downloadData: [...filteredData]
            }

        case FETCHING_TABLE_DATA_FAIL:
        case BASIC_SEARCH_FAIL:
            return {
                ...state, 
                loading: false,
                data: [],
                // columns: []
            }
        
        default:
            return state;
    }
}
