import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import { useLocation } from 'react-router-dom';


function Sidebar({ show, toggle }) {

	const location = useLocation();
	const [activeListItem, setActiveListItem] = useState(0)

	const sidebarJson = [
		{
			url: '/dashboard',
			activeImg: '/images/BBT-Entries-White.png',
			deactiveImg: '/images/BBT-Entries.png',
			listItemName: 'BBT Entries',
			className: 'sidebar__listItem'
		},
		{
			url: '/zohoInvoices',
			activeImg: '/images/invoice_tracker_tiny_1.png',
			deactiveImg: '/images/invoice_tracker_tiny_1.png',
			listItemName: 'Auto-Invoicing Tracker',
			className: 'sidebar__listItem'
		},
		{
			url: '/zohoAutoGeneratedInvoices',
			activeImg: '/images/invoice_tracker_tiny_1.png',
			deactiveImg: '/images/invoice_tracker_tiny_1.png',
			listItemName: 'Zoho Auto Generated Invoices',
			className: 'sidebar__listItem'
		},
		{
			url: '/InvoiceGeneration',
			activeImg: '/images/invoice_tracker_tiny_1.png',
			deactiveImg: '/images/invoice_tracker_tiny_1.png',
			listItemName: 'Invoice PDF Tracker',
			className: 'sidebar__listItem'
		},
		{
			url: '/viewInvoices',
			activeImg: '/images/View-Invoices-White.png',
			deactiveImg: '/images/View-Invoices.png',
			listItemName: 'View Invoices',
			className: 'sidebar__listItem'
		},
		{
			url: '/uploadInvoices',
			activeImg: '/images/Bulk-Upload-White.png',
			deactiveImg: '/images/Bulk-Upload.png',
			listItemName: 'Upload Invoices',
			className: 'sidebar__listItem'
		},
		{
			url: '/processTacker',
			activeImg: '/images/clock_tiny.png',
			deactiveImg: '/images/clock_tiny.png',
			listItemName: 'Upload Tracker',
			className: 'sidebar__listItem'
		},
		{
			url: '/mappingTracker',
			activeImg: '/images/node_small_bg.png',
			deactiveImg: '/images/node_small_bg.png',
			listItemName: 'Mapping Tracker',
			className: 'sidebar__listItem'
		},
		{
			url: '/zohoInput',
			activeImg: '/images/input_logo_small.png',
			deactiveImg: '/images/input_logo_small.png',
			listItemName: 'Input File',
			className: 'sidebar__listItem'
		},
		{
			url: '/ttrDownload',
			activeImg: '/images/download-gray.png',
			deactiveImg: '/images/download-gray.png',
			listItemName: 'TTR Report',
			className: 'sidebar__listItem'
		},
		{
			url: '/latestBalance',
			activeImg: '/images/BBT-Entries-White.png',
			deactiveImg: '/images/BBT-Entries.png',
			listItemName: 'Latest Balance',
			className: 'sidebar__listItem'
		}
	]

	const handleClick = (index) => {
		setActiveListItem(index)
		toggle(false)
	}

	const wrapperRef = useRef();
	useEffect(() => {

		let handler = (event) => {
			if (!wrapperRef.current.contains(event.target)) {
				toggle(false);
				// setIsOpen(false)
			}
		}
		document.addEventListener("mousedown", handler)

		return () => {
			document.removeEventListener("mousedown", handler);
		}
	});


	console.log('sidebar', show, toggle, 'activeItem', activeListItem)
	return (
		<div >

			<div
				className={show ? "sidebar__overlay active" : "sidebar__overlay"}
			>
				<div ref={wrapperRef} className={show ? "sidebar" : "sidebar open"}>
					<div className="sidebar__nav">
						<div className="c1"></div>
						<button
							className="hamburger"
							onClick={() => toggle(false)}
						>
							<img src="images/hamburger.png" alt="Hamburger Icon" />
						</button>
						<img src="images/logo-source.png" alt="Itilite Logo" />
					</div>
					<ul className="sidebar__list">
						{sidebarJson.map((liItem, index) => {
							return (
								<li
									className={location.pathname === liItem.url ? `${liItem.className} active` : liItem.className}
									key={index}
									onClick={() => handleClick(index)}
								>
									<Link to={liItem.url} style={{ width: "100%", height: "100%", display: "flex", alignItems: 'center' }}>
										<span>
											<img
												className="sidebar__listIcon"
												src={location.pathname === liItem.url ? liItem.activeImg : liItem.deactiveImg}
												alt="BBT Icon"
											/>
										</span>
										<div className={location.pathname === liItem.url ? 'sidebar__listItemText active' : 'sidebar__listItemText'}>{liItem.listItemName}</div>
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default Sidebar;


