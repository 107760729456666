import React, { useState, useEffect } from "react";
import {
	Row,
	Col,
	Button,
	Form,
	FormControl,
	Table,
} from "react-bootstrap";
import DatatableServerSide from '../common/DatatableServerSide'
import Footer from '../uploadInvoices/Footer';
import { tableData, cols } from './tableData';
import { useDispatch, useSelector } from "react-redux";
import "./process-tracker.css";
import Skeleton from 'react-skeleton-loader';
import Datatable1 from "../dashboard/Datatable";


function ProcessTacker() {
	const [selectInput, setSelectInput] = useState('day')
	const [searchInput, setSearchInput] = useState(3)
	const [responseData, setResponseData] = useState([])
	const [dataLoading, setDataLoading] = useState(true)
	const [awaiting, setAwaiting] = useState(null)
	const [failed, setFailed] = useState(null)
	const [success, setSuccess] = useState(null)
	const [mappingReady, setReady] = useState(null)

	const dispatch = useDispatch()

	const submitSearchValue = (event) => {
		if(searchInput){
		event.preventDefault();
		setDataLoading(true)
		dispatch(tableData(selectInput, searchInput))
			.then(res => {
				setDataLoading(false)
				if (res.data !== undefined) {
					setResponseData(res.data.results)
					setAwaiting(res.data.report.awaiting_count)
					setFailed(res.data.report.failed_count)
					setReady(res.data.report.ready_for_mapping ? "Yes": "No")
					setSuccess(res.data.report.success_count)
				} else {
					setResponseData([])
					setAwaiting(null)
					setFailed(null)
					setReady(null)
					setSuccess(null)
				}
			}).catch(error => {
				setDataLoading(false)
				setResponseData([])
				setAwaiting(null)
				setFailed(null)
				setReady(null)
				setSuccess(null)
				console.error('server error during fetching data', error);
			});
		}
	}

	useEffect(() => {
		dispatch(tableData())
			.then(res => {
				setDataLoading(false)
				if (res.data !== undefined) {
					setResponseData(res.data.results)
					setAwaiting(res.data.report.awaiting_count)
					setFailed(res.data.report.failed_count)
					setReady(res.data.report.ready_for_mapping ? "Yes": "No")
					setSuccess(res.data.report.success_count)
				} else {
					setResponseData([])
					setAwaiting(null)
					setFailed(null)
					setReady(null)
					setSuccess(null)
				}
			}).catch(error => {
				setDataLoading(false)
				setResponseData([])
				setAwaiting(null)
				setFailed(null)
				setReady(null)
				setSuccess(null)
				console.error('Error during fetching data', error);
			});
	}, [dispatch])
	return (
		<div className="viewInvoices">
			<div className="viewInvoices__container">
				<h4 className="viewInvoices__title">Invoice Process Tracker</h4>
				<div className="viewInvoices__horizontalLine"></div>
				<div className="searchSection">
					<Row>
						<Col md={2}>
							<Form.Control as='select' onChange={(e) => setSelectInput(e.target.value)} className="tracker__selectSearch">
								<option value="day">Number of Days</option>
								<option value="batch_id">Batch ID</option>
								{/* <option value="created_at">Date</option> */}
							</Form.Control>
						</Col>
						<Col md={2}>
							<FormControl onChange={(e) => setSearchInput(e.target.value)} className="tracker__searchInput" type='text' placeholder='Enter value here. Default day is 3.' />
						</Col>
						<Col>
							<Button className="mx-0" onClick={submitSearchValue} id='search-btn'>
								<img src='images/search-icon.png' alt='Search Icon' />
							</Button>
						</Col>
					</Row>

				</div>
				<div className="viewInvoices__horizontalLine"></div>

				<Row>
					<Col>Awaiting Process: {awaiting}</Col>
					<Col>Failed: {failed}</Col>
					<Col>Success: {success}</Col>
					<Col>Ready for Mapping: {mappingReady}</Col>
				</Row>

				{(!dataLoading) ? (      
				(responseData.length > 0) ? (
					<Datatable1
						data={responseData}
						columns={cols()}
					/>
				): (
					<div className="dashboard__noDataFound">
					  <h1>No data Available</h1>
					</div>
				  )
				): (
					<Table striped bordered>
						<thead>
							<tr>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
							</tr>
						</thead>
						<tbody>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => {
								return (
									<tr className='align-items-center'>
										<td><Skeleton /></td>
										<td><Skeleton /></td>
										<td><Skeleton /></td>
										<td><Skeleton /></td>
										<td><Skeleton /></td>
										<td><Skeleton /></td>
										<td><Skeleton /></td>
									</tr>
								);
							})}
						</tbody>
					</Table>
					)}
			</div>
			<Footer />
		</div>
	)
}


export default ProcessTacker;
