import React, { useState, createRef, useRef } from 'react';
import './IndividualInvoice.css';
// import Navbar from '../../dashboard/Navbar';
import Footer from '../Footer';
import Dropzone from 'react-dropzone'
import axios, { CancelToken, isCancel } from 'axios';
import { Redirect, useHistory } from 'react-router-dom';
import { ProgressBar } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteInvoice, 
    // saveIndividualUploadedInvoice 
} from '../../../actions/ViewInvoices/invoices';
import ToastNotification from '../../common/ToastNotification';
import swal from 'sweetalert';
import { apiUrl } from '../../../utils/apiUrl';



function IndividualInvoiceUpload(props) {

    const [typeOfInvoice, setTypeOfInvoice] = useState('Transaction Invoice');
    const [invoiceFile, setInvoiceFile] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadSuccess, setIsUploadSuccess] = useState(false);
    const [isUploadFailure, setIsUploadFailure] = useState(false);
    const [cancelBtnDisplay, setCancelBtnDisplay] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [maxPdfSize, setMaxPdfSize] = useState(52428800);
    const [displayRadio, setDisplayRadio] = useState(true);
    const [displayTplusSF, setDisplayTplusSF] = useState(props.location.state.transPlusSfEnabled);

    const [displayInvoice, setDisplayInvoice] = useState({})
    const [toastNotication, setToastNotification] = useState(false);

    const [uploadMsg, setUploadMsg] = useState("Uploading...")
    const dispath = useDispatch();
    const history = useHistory();

    const dropzoneRef = createRef();
    const cancelFileUpload = useRef(null);

    const openDailog = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    }

    const onChangeInvoice = (event) => {
        setTypeOfInvoice(event.target.value)
    }

    const cancelUpload = () => {
        setDisplayRadio(true)
        if (isUploadSuccess) {
            // delete invoice
            let invoice = invoiceFile;
            let invoiceNumber = invoice[0].name.split('.')
            let obj = {
                bbtSlNo: props.location.state.slNo,
                invoiceNumber: invoiceNumber[0], 
                invoiceType: typeOfInvoice, 
                all: false
            }
            setDisplayInvoice(obj)
            dispath(deleteInvoice(obj)).then(res => {
                console.log('cancel', invoice, invoiceNumber[0], obj, res)
                if (res.status === 200) {
                    setIsUploadFailure(false);
                    setIsUploadSuccess(false);
                    setIsUploading(false);
                    setCancelBtnDisplay(false)
                    setToastNotification(true)
                }
            })
        } else {
            if(cancelFileUpload.current) {
                cancelFileUpload.current('User has canceled the file upload')
                setIsUploading(false)
            }
        }

        if (isUploadFailure) {
            setIsUploadFailure(false)
            setIsUploading(false)
            setCancelBtnDisplay(false)
            setDisplayRadio(true)
        }
    }

    const handleFileUpload = (acceptedFiles) => {
        setUploadPercentage(0)
        setUploadMsg("Uploading...")
        if (acceptedFiles[0].size > maxPdfSize) {
            swal('Pdf max size exceed')
        } else {
            setInvoiceFile(acceptedFiles)
            setIsUploading(true)
            setCancelBtnDisplay(true)
            console.log('ondrop', acceptedFiles, acceptedFiles[0].name.includes('.pdf'))
            let data = new FormData();
            data.append('invoice_file', acceptedFiles[0])
    
            let timeStamp = Date.now()
            console.log(timeStamp)
    
            const options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total} = progressEvent;
                    let percent = Math.floor(loaded * 100) / total;
    
                    if (percent <= 100) {
                        setUploadPercentage(percent)
                    }
                    if(percent === 100){
                        setUploadMsg("Processing...")
                    }
                },
                cancelToken: new CancelToken(cancel => cancelFileUpload.current = cancel)
            }
                axios.post(`${apiUrl.INVOICE_SUBMIT}?pdf_process=false&bbt_sl_no=${props.location.state.slNo}&invoice_type=${typeOfInvoice}`, data, options).then(res => {
                    setUploadPercentage(100)
                    setTimeout(() => {
                        setUploadPercentage(0)
                    }, 1000)
                    setIsUploading(false)
                    setIsUploadSuccess(true)
                    setDisplayRadio(false)
             
                }).catch(err => {
                    console.log(err.response)
                    if(isCancel(err)) {
                        alert(err.message)
                    }
                    setIsUploading(false)
                    setIsUploadFailure(true)
                    setDisplayRadio(true)
                });
        }
    }

    const files = invoiceFile.map(file => {
        return file.path
    });


    const renderFileUpload = (getRootProps, getInputProps, isDragActive) => {
        if(!isUploading && !isUploadSuccess && !isUploadFailure) {
            return <div {...getRootProps({className: isDragActive ? 'dropzone uploadInvoices__uploadArea drag' : 'dropzone uploadInvoices__uploadArea'})}>
                        <input {...getInputProps()} />
                        <div className="uploadInvoices__uploadingSection">
                            <div className="uploadInvoices__fileSize">Maximum file size 50MB | File Format: PDF</div>
                            <img src="images/upload.png" alt="upload" />
                            <div className="uploadInvoices__dragDropText">Drag and drop the file here</div>
                            <div className="uploadInvoices__orText">or</div>
                            <button type="button" onClick={openDailog} className="uploadInvoices__uploadBtn">Select File</button>
                        </div>
                    </div>
        } else if (isUploading && !isUploadSuccess && !isUploadFailure) {
            return  <div {...getRootProps({className: 'dropzone uploadInvoices__uploadArea'})}>
                        <input {...getInputProps()} />
                        <div className="uploadInvoices__progressUpload">
                            <div className="uploadingInvoices__uploadingText">{uploadMsg}</div>
                            <div className="uploadInvoices__showingProgress">{files}</div>
                            { uploadPercentage > 0 && <ProgressBar now={uploadPercentage} />}
                            <div className="uploadInvoices__doNotRefresh">Please DO NOT close or refresh this page while the file is uploading</div>
                        </div>
                    </div>
        } else if (!isUploading && isUploadSuccess && !isUploadFailure) {
            return  <div {...getRootProps({className: `dropzone ${isUploadSuccess ? 'uploadInvoices__uploadArea success' : ''}`})}>
                        <div className="uploadInvoices__progressUpload">
                            <div className="uploadingInvoices__uploadedText">Uploaded Successfully</div>
                            <div className="uploadInvoices__showingProgress d-flex align-items-center pl-2">
                                <span className="pr-3"><img src="images/successfully-uploaded.png" alt="uploaded" /></span>
                                {files}
                            </div>
                        </div>
                    </div>
        } else {
            return  <div {...getRootProps({className: `dropzone ${!isUploadSuccess ? 'uploadInvoices__uploadArea fail' : ''}`})}>
                        <div className="uploadInvoices__progressUpload">
                            <div className="uploadingInvoices__uploadedText fail">Upload Failed</div>
                            <div className="uploadInvoices__showingProgress d-flex justify-content-between align-items-center pl-3">
                                <span><img className="pr-2" src="images/upload-failed.png" alt="failed" /> {files} </span>
                                <button 
                                    onClick={() => handleInvoiceRetry()} 
                                    className="uploadInvoices__retry"><img className="px-1" src="images/retry.png" alt="retry" /> Retry</button>
                            </div>
                        </div>
                    </div>
        }
    }

    const handleInvoiceRetry = () => {
        setIsUploading(true)
        setIsUploadSuccess(false)
        setIsUploadFailure(false)
        setDisplayRadio(true)
        handleFileUpload(invoiceFile);
    }

    const handleFinish = () => {
        if(isUploadSuccess) {
            history.push({
                pathname: '/viewInvoices',
                state: {
                    from: props.location.pathname,
                    company: props.location.state.company,
                    dataLoading: true
                }
            })
        }
    }


    if(props.location.state === undefined) {
        return <Redirect to="/viewInvoices" />
    }


    console.log(props)
    return (
        <div>
            {/* <Navbar /> */}
            <ToastNotification show={toastNotication} setShow={setToastNotification} invoice={displayInvoice} />
            <div className="uploadInvoices">
                <div className="uploadInvoices__container">
                    <h4 className="individualInvoice__title">Please select the type of invoice which you want to upload</h4>
                    <div className="uploadInvoives__horizontalLine"></div>
                    {displayRadio && <div className="uploadInvoice__radioContainer">
                        <div className="d-flex">
                            <input onChange={onChangeInvoice} defaultChecked type="radio" id="transactionInvoice" name="invoice" value="Transaction Invoice" />
                            <label className="individualInvoice__labelText" for="transactionInvoice">Transaction Invoice</label>
                        </div>
                        <div className="d-flex">
                            <input onChange={onChangeInvoice} type="radio" id="serviceFeeInvoice" name="invoice" value="Service Fee Invoice" />
                            <label className="individualInvoice__labelText" for="serviceFeeInvoice">Service Fee Invoice</label>
                        </div>
                        <div className="d-flex">
                            <input onChange={onChangeInvoice} type="radio" id="vendorInvoice" name="invoice" value="Vendor Invoice" />
                            <label className="individualInvoice__labelText" for="vendorInvoice">Vendor Invoice</label>
                        </div>
                        {displayTplusSF && <div className="uploadInvoice__radioContainer">
                            <div className="d-flex">
                                <input onChange={onChangeInvoice} type="radio" id="tPlusSfInvoice" name="invoice" value="Trans Plus SF Invoice" />
                                <label className="individualInvoice__labelText" for="tPlusSfInvoice">Unsigned Invoice</label>
                            </div>
                            <div className="d-flex">
                                <input onChange={onChangeInvoice} type="radio" id="tPlusSfSignedInvoice" name="invoice" value="Signed Invoice" />
                                <label className="individualInvoice__labelText" for="tPlusSfSignedInvoice">Signed</label>
                            </div>
                        </div>}
                    </div>}
                    <Dropzone onDrop={handleFileUpload} accept={['.pdf', '.zip']} maxSize={maxPdfSize} onClick={false} noKeyboard={true}>
                        {({getRootProps, getInputProps, isDragActive}) => (
                            renderFileUpload(getRootProps, getInputProps, isDragActive)
                        )}
                    </Dropzone>
                    

                </div>
                <div className="individualInvoice__btns">
                    {cancelBtnDisplay && <button onClick={cancelUpload} className="individualInvoice__cancelBtn">Cancel</button>}
                    <button onClick={handleFinish} className={`${isUploadSuccess ? 'individualInvoice__finishBtn success' : 'individualInvoice__finishBtn'}`}>Finish</button>
                </div> 
            </div>
            <Footer />
        </div>
    )
}

export default IndividualInvoiceUpload
