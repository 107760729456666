import React from "react";
import {
    Row,
    Col,
    Button,
    Form,
    Modal,
  } from "react-bootstrap";

function AdvanceFilterModal({ showAdvanceFilterModal, advanceFilterModalClose, customValues, addMoreCustomValue, removeCustomValues }) {
  return (
    <Modal
      show={showAdvanceFilterModal}
      onHide={advanceFilterModalClose}
      size='lg'
      centered
      aria-labelledby='example-custom-modal-styling-title'
    >
      <Row>
        <Col className='mr-5 font-weight-bold modal-header-text pl-0'>
          Advance Filters
        </Col>
        <Col className='text-right text-muted pr-0'>Clear All</Col>
      </Row>

      <Form as={Row}>
        <Col
          md={6}
          className='pl-0 mt-3'
          className='pl-0 mt-3'
          id='add-more-custom'
        >
          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Company
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='email' placeholder='Email' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Time Period
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='date' placeholder='Email' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Options
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='email' placeholder='Email' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Property
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='email' placeholder='Email' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Vendor
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='email' placeholder='Email' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Payment Mode
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='email' placeholder='Email' />
            </Col>
          </Form.Group>

          {/* <button onClick={addMoreCustomValue} className="border-0 btn-add-more my-3">
                    <span class="mr-2"><img src="images/plus-blue.png" /></span>
                    Add More
                  </button> */}
        </Col>

        <Col md={6} className='pl-0 mt-3'>
          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Trip ID
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='email' placeholder='Email' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Ticket No.
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='email' placeholder='Email' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Automated / Manual
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='email' placeholder='Email' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Net Amount
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='email' placeholder='Email' />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            <Form.Label column sm={4} className='pl-0'>
              Agent / Reseller
            </Form.Label>
            <Col sm={8}>
              <Form.Control type='email' placeholder='Email' />
            </Col>
          </Form.Group>
        </Col>
      </Form>

      {/* adding values dynamic */}

      {customValues.map((customValue, index) => {
        return (
          <Form.Group as={Row} className='mb-2' controlId='formHorizontalEmail'>
            {/* <Form.Label column sm={4} className="pl-0">
                    {Company}
                  </Form.Label> */}
            <Col md={6} className='pl-0 d-flex'>
              <Form.Control
                className='mr-3'
                type='text'
                placeholder='Custom Values'
              />
              <Form.Control
                className='mr-3'
                value={customValue}
                type='text'
                placeholder='Enter the text'
              />
            </Col>
            <Col md={6} className='pl-0'>
              <button
                onClick={() => removeCustomValues(index)}
                style={{
                  border: "none",
                  background: "none",
                  outline: "none",
                }}
              >
                <img src='images/minus.png' />
              </button>
            </Col>
          </Form.Group>
        );
      })}

      <Row>
        <button 
          onClick={addMoreCustomValue} 
          className='btn-add-more my-3'
        >
          <span class='mr-2'>
            <img src='images/plus-blue.png' />
          </span>
          Add More
        </button>
      </Row>
      <div className='my-3'></div>

      <div className='d-flex justify-content-end'>
        <Button
          onClick={advanceFilterModalClose}
          className='modal-btn-cancle'
        >
          Cancle
        </Button>
        <Button className='modal-btn-apply ml-2'>Apply</Button>
      </div>
    </Modal>
  );
}

export default AdvanceFilterModal;
