import React, { useState, useEffect } from 'react';
import format from "date-fns/format";
import Skeleton from 'react-skeleton-loader';
import { Table } from "react-bootstrap";
import './ViewInvoices.css';
import './Slider.css';
import Footer from '../Footer';
import DatePicker from "react-datepicker";
import DatatableServerSide from '../../common/DatatableServerSide';
import { fetchInvoices, saveDeleteInvoice, companySelected, saveCompanyAndDate, getColumns, setCurrentPage, setPerPage, fetchCompany,
fetch_trans_plus_sf_clients } from '../../../actions/ViewInvoices/invoices';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Switch from './Switch';
import moment from "moment";
import axios from 'axios';
import swal from 'sweetalert';
import { apiUrl } from '../../../utils/apiUrl';



function ViewInvoices(props) {
	const [company, setCompany] = useState('');
	const [isSwitchOn, setISwitchOn] = useState(false);

	const [startDate, setStartDate] = useState(new Date(moment().subtract(14, "days")));
	const [endDate, setEndDate] = useState(new Date(moment()));


	const dispatch = useDispatch();
	const history = useHistory();

	const { data, columns, totalRows, loading, companyDaterange, isCompanySelected, currentPage, perPage, companies, isTransPlusSfEnabled, trans_plus_sf_clients } = useSelector(state => state.viewInvoices);

	const handleCompanyChange = (event) => {
		// (setIsCompanySelected(true))
		dispatch(companySelected(true));
		dispatch(fetch_trans_plus_sf_clients(event.target.value));
		setCompany(event.target.value)
		if (isSwitchOn) {
			dispatch(getColumns('showAll'))
		} else {
			dispatch(getColumns('default'))
		}

		// fetch all invoices table
		let obj = {
			company: event.target.value,
			fromDate: format(startDate, 'yyyy-MM-dd'),
			toDate: format(endDate, 'yyyy-MM-dd'),
			perPage: perPage,
			page: currentPage
		}

		dispatch(fetchInvoices(obj))
	}

	/* --------- useEffect for didMount---------*/
	useEffect(() => {
		dispatch(fetchCompany())
		dispatch(fetch_trans_plus_sf_clients())

		if (isCompanySelected) {
			let obj = {
				company: companyDaterange.company,
				fromDate: companyDaterange.fromDate,
				toDate: companyDaterange.toDate,
				perPage: companyDaterange.perPage,
				page: companyDaterange.page
			}

			// dispatch(fetchInvoices(obj))
			dispatch(fetchInvoices(obj))
			setCompany(companyDaterange.company)
			dispatch(fetch_trans_plus_sf_clients(companyDaterange.company));
			setStartDate(new Date(companyDaterange.fromDate))
			setEndDate(new Date(companyDaterange.toDate))
		}
	}, [])

	/* --------- useEffect for didUpdate ---------*/
	useEffect(() => {
		// save the data
		let obj = {
			company: company,
			fromDate: format(startDate, 'yyyy-MM-dd'),
			toDate: format(endDate, 'yyyy-MM-dd'),
			perPage: perPage,
			page: currentPage
		}
		dispatch(saveCompanyAndDate(obj))
	}, [startDate, endDate, company, currentPage, perPage])

	const [invoice, setInvoice] = useState([])

	const handleClick = (id) => {
		let _temp = data
		let delInv = _temp.filter(ele => ele.id === id)
		setInvoice(delInv)
		dispatch(saveDeleteInvoice(delInv))

		history.push({
			pathname: '/deleteInvoice',
			state: {
				from: props.location.pathname
			}
		})
	}

	// pagination
	const [paginationResetDefaultPage, setPaginationResetDefaultPage] = useState(false)

	const handlePageChange = async (page) => {
		// setDataLoading(true)
		let obj = {
			company: company,
			fromDate: format(startDate, 'yyyy-MM-dd'),
			toDate: format(endDate, 'yyyy-MM-dd'),
			perPage: perPage,
			page: page
		}

		dispatch(fetchInvoices(obj))
		dispatch(setCurrentPage(page))
		console.log('currentpage>>>', currentPage)
	}


	const handlePerRowsChange = async (newPerPage, page) => {

		// setDataLoading(true);
		let obj = {
			company: company,
			fromDate: format(startDate, 'yyyy-MM-dd'),
			toDate: format(endDate, 'yyyy-MM-dd'),
			perPage: newPerPage,
			page: page
		}

		dispatch(fetchInvoices(obj))

		// setPerPage(newPerPage);
		dispatch(setPerPage(newPerPage))
		// setCurrentPage(1)
		dispatch(setCurrentPage(1))
		setPaginationResetDefaultPage(!paginationResetDefaultPage)
		// setDataLoading(false);
	};

	const handleSearch = () => {
		// loading true
		let obj = {
			company: company,
			fromDate: format(startDate, 'yyyy-MM-dd'),
			toDate: format(endDate, 'yyyy-MM-dd'),
			perPage: perPage
		}

		dispatch(fetchInvoices(obj))
		setCurrentPage(1)
		// loading fasle
	}

	const handleDownloadInvoice = async (id) => {
		let tempData = [...data]
		let downloadData = tempData.filter(row => row.id === id);
		let company = downloadData[0].Company
		let slNo = downloadData[0].SL_NO

		try {
			const response = await axios({
				url: `${apiUrl.INVOICE_DOWNLOAD}?bbt_sl_no=${slNo}&company=${company}`,
				method: "GET",
				headers: {
					"Authorization": localStorage.token
				}
			})

			if (response.status === 200) {
				if (response.data.isBase64Encoded){
					downloadEncodedFile(response.data.fileEncode)
				}else{
					console.log("wrong file format response")
					swal("Can't download file.")
				}
			}
		} catch (error) {
			if (error.response && error.response.data) {
				swal(error.response.data.message) // error message
			}else{
				swal('Something went wrong.')
			}
		}
	}

	const downloadEncodedFile = (encodedData) => {
		const blob = b64toBlob(encodedData, "application/zip");
		const blobUrl = URL.createObjectURL(blob);
		window.location = blobUrl;
	}

	const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];
	  
		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		  const slice = byteCharacters.slice(offset, offset + sliceSize);
	  
		  const byteNumbers = new Array(slice.length);
		  for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		  }
	  
		  const byteArray = new Uint8Array(byteNumbers);
		  byteArrays.push(byteArray);
		}
	  
		const blob = new Blob(byteArrays, {type: contentType});
		return blob;
	  }

	const datatableRender = (data, cols, dataLoading) => {
		let columns = [...cols].filter(item => !item.hasOwnProperty('invoice_group'));
		let invoice_columns = [];
		let invoice_group = isTransPlusSfEnabled ? 'invoice_group3' : 'invoice_group1';
		invoice_columns = [...cols].filter(item => item.invoice_group === invoice_group);
		columns = columns.concat(invoice_columns)
		let item = {
			name: "Action",
			selector: "",
			cell: row => {
				if ((row.service_fee_invoice.length === 0 && row.transaction_invoice.length === 0 && row.vendor_invoice.length === 0
				 && (typeof row.trans_plus_sf_invoice === "undefined" || row.trans_plus_sf_invoice.length === 0) && (typeof row.signed_invoice === "undefined" ||
				 row.signed_invoice.length === 0)) || (row.service_fee_invoice === "" && row.transaction_invoice === "" && row.vendor_invoice === ""
					 && row.trans_plus_sf_invoice === "" && row.signed_invoice === "")
//					 check both of the below cases
				) {
					return (
						<div className="d-flex justify-content-around w-100">
							<button className="viewInvoices__deleteIcon" onClick={() => handleClick(row.id)} value={row.id} disabled><img src="images/disable-download.png" /></button>
							<button className="viewInvoices__deleteIcon" onClick={() => handleClick(row.id)} value={row.id} disabled><img src="images/disable-delete.png" /></button>
							<Link to={{
								pathname: "/uploadInvoice",
								state: {
									from: props.location.pathname,
									slNo: row.SL_NO,
									transPlusSfEnabled: isTransPlusSfEnabled,
									company: company
								}
							}}><img src="images/upload-gray.png" /></Link>
						</div>
					)
				} else {
					return (
						<div className="d-flex justify-content-around w-100">
							<button className="viewInvoices__deleteIcon" onClick={() => handleDownloadInvoice(row.id)} value={row.id}><img src="images/download-gray.png" /></button>
							<button className="viewInvoices__deleteIcon" onClick={() => handleClick(row.id)} value={row.id}><img src="images/delete.png" /></button>

							<Link to={{
								pathname: "/uploadInvoice",
								state: {
									from: props.location.pathname,
									slNo: row.SL_NO,
									transPlusSfEnabled: isTransPlusSfEnabled,
									company: company
								}
							}}><img src="images/upload-gray.png" /></Link>
						</div>
					)
				}
			},
			maxWidth: '100px'
		}
		columns.push(item)
		if (!dataLoading) {
			if (data.length > 0 && cols.length > 0) {
				return (<DatatableServerSide
					data={data}
					columns={columns}
					totalRows={totalRows}
					handlePerRowsChange={handlePerRowsChange}
					handlePageChange={handlePageChange}
					currentPage={currentPage}
					perPage={perPage}
					paginationResetDefaultPage={paginationResetDefaultPage}
				/>);
			} else {
				return <div className="dashboard__noDataFound">
					<h1>No data Available</h1>
				</div>
			}
		} else {
			console.log("data", dataLoading)
			if (dataLoading) {
				console.log('len', data.length)
				return <Table striped bordered>
					<thead>
						<tr>
							<th><Skeleton /></th>
							<th><Skeleton /></th>
							<th><Skeleton /></th>
							<th><Skeleton /></th>
							<th><Skeleton /></th>
							<th><Skeleton /></th>
							<th><Skeleton /></th>
						</tr>
					</thead>
					<tbody>
						{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => {
							return (
								<tr className='align-items-center'>
									<td><Skeleton /></td>
									<td><Skeleton /></td>
									<td><Skeleton /></td>
									<td><Skeleton /></td>
									<td><Skeleton /></td>
									<td><Skeleton /></td>
									<td><Skeleton /></td>
								</tr>
							);
						})}
					</tbody>
				</Table>

			} else {
				console.log('no data found')
				return <h1>No data found</h1>
			}
		}
	}

	const handleToggle = () => {
		if (!isSwitchOn) {
			// alert('Show all')
			dispatch(getColumns('showAll'))
		} else {
			dispatch(getColumns('default'))
			// alert('Limited')
		}
	}

	const handleChangeRaw = (event) => {
		if (event.target.value === "" || event.target.value === undefined) {
			alert('Please select the date')
		}
	}

	const handleStartDate = (date) => {
		if (!date) return

		setStartDate(date)
	}

	const handleEndDate = date => {
		if (!date) return

		setEndDate(date)
	}

	// filter data
	const [filterText, setFilterText] = useState('');
	const filteredItems = data.filter(item =>
		(item['Traveler_name'] && item['Traveler_name'].toLowerCase().includes(filterText.toLowerCase())) ||
		(item['Trip_Details'] && item['Trip_Details'].toLowerCase().includes(filterText.toLowerCase())) ||
		(item['ITILITE_website_Trip_ID'] && item['ITILITE_website_Trip_ID'].toLowerCase().includes(filterText.toLowerCase())) ||
		(item['SL_NO'] && String(item['SL_NO']).toLowerCase().includes(filterText.toLowerCase())) ||
		(item['gseqnumber'] && String(item['gseqnumber']).toLowerCase().includes(filterText.toLowerCase())) ||
		(item['TTR_SL_NO'] && String(item['TTR_SL_NO']).toLowerCase().includes(filterText.toLowerCase()))
	);

	// console.log(totalRows, 'switch>>', data)
	return (
		<div className="viewInvoices">
			{/* <Navbar /> */}

			<div className="viewInvoices__container">
				<h4 className="viewInvoices__title">Invoices</h4>
				<div className="viewInvoices__horizontalLine"></div>
				<select onChange={handleCompanyChange} value={company} className='viewInovices__selectCompany'>
					<option>Select Company</option>
					{companies?.map(com => {
						return (
							<option value={com}>{com}</option>
						)
					})}
				</select>

				{isCompanySelected && (
					<React.Fragment>
						<div className="viewInvoices__dateFilter">
							<label>From</label>
							<div className="viewInvoices__timePeriod">
								<DatePicker
									selected={startDate}
									value={startDate ? startDate : null}
									onChange={handleStartDate}
							
									dateFormat="dd-MMM-yyyy"
									className="viewInvoices__datepicker"
									placeholderText="From Date"
								/>
								<img
									className="viewInvoices__calenderImg"
									src='images/calender-grey.png'
									alt='calender-icon'
								/>
							</div>
							<label>TO</label>
							<div className="viewInvoices__timePeriod">
								<DatePicker
									selected={endDate}
									value={endDate ? endDate : null}
									onChange={handleEndDate}
									dateFormat="dd-MMM-yyyy"
									minDate={startDate}
									maxDate={new Date(moment(startDate).add(2, 'months'))}
									placeholderText="To Date"
									className="viewInvoices__datepicker"
				
								/>
								<img
									className="viewInvoices__calenderImg"
									src='images/calender-grey.png'
									alt='calender-icon'
								/>
							</div>
							<button onClick={handleSearch} className="viewInvoices__txnDateSeach">Search</button>
						</div>
						<div className="viewInvoices__horizontalLine"></div>

						<div className="viewInvoices__tableSection">
							{/* 1st option */}
							<div className="viewInvoices__filterSection d-none">
								<div>
									<label className="viewInvoices__text">Show Entries</label>
									<select className="viewInvoices__select viewInvoices__text">
										<option>10</option>
										<option>50</option>
										<option>100</option>
									</select>
								</div>
								<div>
									<label className="viewInvoices__text">Search</label>
									<input
										// onChange={(event) => setFilterText(event.target.value)} 
										className="viewInvoices__inputSearch" />
								</div>
							</div>

							<div className="viewInvoices__toggleCols d-none">
								<label className="viewInvoices__text">View All Columns</label>
								<Switch
									isOn={isSwitchOn}
									onColor="#EB5C24"
									handleToggle={() => { setISwitchOn(!isSwitchOn); handleToggle() }}
								/>
							</div>

							{/* 2nd option */}
							<div className="viewInvoices__filterSection">
								<div>
									<label className="viewInvoices__text">Search</label>
									<input
										onChange={(event) => setFilterText(event.target.value)}
										className="viewInvoices__inputSearch"
										placeholder="SL No, G SL No, TTR SL No, Trip Id, Traveler"
									/>
								</div>
								<div className="viewInvoices__toggleCols">
									<label className="viewInvoices__text">View All Columns</label>
									<Switch
										isOn={isSwitchOn}
										onColor="#EB5C24"
										handleToggle={() => { setISwitchOn(!isSwitchOn); handleToggle() }}
									/>
								</div>
							</div>
						</div>

						{data && columns.length > 1 && datatableRender(filteredItems, columns, loading)}

					</React.Fragment>
				)}
			</div>


			<Footer />
		</div>
	)
}

export default ViewInvoices
