import React, { useState, useEffect, useRef } from 'react';
import './Dropdown.css';

// const items = [
//     {
//         id: 1,
//         itemName: 'Custom View',
//     },
//     {
//         id: 2,
//         itemName: 'Default',
//     },
//     {
//         id: 3,
//         itemName: 'Booking Details',
//     },
//     {
//         id: 4,
//         itemName: 'Vendor Details',
//     },
//     {
//         id: 5,
//         itemName: 'Paybycard Details',
//     },
//     {
//         id: 6,
//         itemName: 'Invoice Details',
//     },
//     {
//         id: 7,
//         itemName: 'GST Details',
//     },
//     {
//         id: 8,
//         itemName: 'Multicurreny Details',
//     },
//     {
//         id: 9,
//         itemName: 'Show All',
//     },
// ]

function Dropdown({ items, selectView }) {

    const [isOpen, setIsOpen] = useState(false);

    const [itemSelected, setItemSelected] = useState("")

    const handleClick = item => {
        setItemSelected(item.itemName)
        setIsOpen(!isOpen)
    }

    const dropdownWrapperRef = useRef(null);

    useEffect(() => {
        let handler = (event) => {
            if(!dropdownWrapperRef.current.contains(event.target)) {
                setIsOpen(false);
                // setIsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler);
        }
    })

    return (
        <React.Fragment>
            <div ref={dropdownWrapperRef} className="dropdownWrapper">
                <div 
                    onClick={() => setIsOpen(!isOpen)} 
                    className={ isOpen ? 'dropdown__selectbox-active' : 'dropdown__selectbox'}
                >
                    <div>{itemSelected === "" ? "Default" : itemSelected}</div>
                    {isOpen ? (
                        <img src="images/arrow-up.png" alt="icon arrow" />
                    ) : (
                        <img src="images/arrow-down.png" alt="icon arrow" />
                    )}
                </div>
                {isOpen && (
                    <React.Fragment>
                        <div className="dropdown__listWrapper">
                            <div className="dropdown__hr"></div>
                            <div>
                                <ul className="dropdown__list">
                                    {items && items.map(item => (
                                        <li className="dropdown__listItem" key={item.id}>
                                            {item.itemName === 'Custom View' ? (
                                                <button onClick={() => {handleClick(item); selectView(item.value)}} value={item} className="dropdown__customBtn">{item.itemName}</button>
                                            ) : (
                                                <button onClick={() => {handleClick(item); selectView(item.value)}} value={item} className="dropdown__btns">{item.itemName}</button>
                                            )}
                                        </li>
                                    ))}
                                    
                                </ul>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                
            </div>
        </React.Fragment>
    )
}

export default Dropdown
