import {
     FETCHING_TABLE_REQUEST,
     FETCHING_TABLE_SUCCESS,
     FETCHING_TABLE_FAIL,
     SET_GSEQNUMBER,
     SET_CURRENT_PAGE,
     SET_PER_PAGE,
     FETCH_COMPANY_FAIL
 } from './../actions/ViewInvoices/types';

const initialState = {
     data: [],
     totalRows: 0,
     columns: [
          {name: "Company Name", selector: "Company", sortable: true},
          {name: "Company ID", selector: "companyId", sortable: true},
          {name: "Latest Balance", selector: "latest_balance"},
          {name: "FC Latest Balance", selector: "fc_latest_balance"},
          {name: "Updated At", selector: "updated_at", sortable: true},
     ],
     loading: true,
     error: '',
     message: '',
     perPage: 10,
     currentPage: 1,
     gseqnumber: '',
}

export default function(state = initialState, action) {
     const { type, payload } = action;
     switch(type) {
          case FETCHING_TABLE_REQUEST:
               return {
                    ...state,
                    loading: true
               }

          case FETCHING_TABLE_SUCCESS:
               return {
                    ...state,
                    loading: false,
                    data: payload.results,
                    totalRows: payload.count,
                    gseqnumber: payload.gseqnumber
               }

          case FETCHING_TABLE_FAIL:
               return {
                    ...state,
                    loading: false,
                    error: payload,
                    data: []
               }

          case SET_CURRENT_PAGE:
               return {
                    ...state,
                    currentPage: payload
               }

          case SET_PER_PAGE:
               let a = {
                    ...state,
                    perPage: payload
               }
               return a

          case FETCH_COMPANY_FAIL:
               return {
                    ...state,
                    error: payload,
                    gseqnumber: ''
               }

          case SET_GSEQNUMBER:
               return {
                    ...state,
                    gseqnumber: payload
               }

          default:
               return state
     }
}
