import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, USER_LOAD, AUTH_ERROR } from '../actions/types';


const initialState = {
	// token: null,
	token: localStorage.getItem('token'),
	isAuthenticated: null,
	loading: true,
	message: null,
	user: null
}

export default function (state = initialState, action) {
	// console.log(action)
	const { type, payload } = action;

	switch (type) {
		case LOGIN_SUCCESS:
			localStorage.setItem('token', payload.Authorization)
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false
			}

		case LOGIN_FAIL:
		case LOGOUT:
			// case AUTH_ERROR:
			localStorage.removeItem('token')
			return {
				...state,
				token: null,
				isAuthenticated: false,
				loading: false
			}

		case USER_LOAD:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				user: payload
			}

		default:
			return state;
	}
}
