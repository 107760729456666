import axios from 'axios';
import { 
    FETCHING_TABLE_DATA_SUCCESS, 
    FETCHING_TABLE_DATA_FAIL,
} from '../../actions/types';


export const cols = () =>{
     return [
    
        {name: "BBT SL NO", selector: "sequence_number", sortable: true, maxWidth: '250px'},
        {name: "Trip ID", selector: "trip_id", sortable: true, maxWidth: '250px'},
        {name: "Invoice Number", selector: "invoice_number", sortable: true, maxWidth: '250px'},
        {name: "Invoice Date", selector: "invoice_date",sortable: true, maxWidth: '180px'},
        {name: "Invoice Type", selector: "invoice_type",sortable: true, maxWidth: '180px'},
        {name: "Invoice Generated", selector: "status",maxWidth: '250px',sortable: true, cell: row => {
            if(row.status === 1){
                return ("Yes");
            }else{
                return ("No");
            }
        }},
        {name: "Client Name", selector: "client_name", sortable: true},
        {name: "Created At", selector: "created_at", sortable: true},
    ]
}

export const fetchData = (url) => async dispatch => {
    let config = {headers: {"Authorization": localStorage.token}}
    try {
        const res = await axios.get(url, config);
        dispatch({
            type: FETCHING_TABLE_DATA_SUCCESS,
            payload: res.data
        })
        return res
    } catch(error) {
        console.log(error)
        dispatch({
            type: FETCHING_TABLE_DATA_FAIL
        })
        return error
    }
}