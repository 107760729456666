import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import format from "date-fns/format";
import "react-datepicker/dist/react-datepicker.css";
import {
	Row,
	Col,
	Form,
	FormControl,
	Table,
	Button
} from "react-bootstrap";
import Footer from '../uploadInvoices/Footer';
import { useDispatch, useSelector } from "react-redux";
import "./zoho-invoice.css";
import moment from "moment";

import Skeleton from 'react-skeleton-loader';
import { apiUrl } from '../../utils/apiUrl';
import Datatable1 from "../dashboard/Datatable";
import swal from 'sweetalert';
import { fetchData, cols1 } from './tableData';
import { CSVLink, CSVDownload } from "react-csv";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {fetchCompany} from '../../actions/ViewInvoices/invoices';


function ZohoInput() {
	const [fromDate, setStartDate] = useState(new Date(moment().subtract(15, "days")));
	const [toDate, setEndDate] = useState(new Date());
	const [cidValue, setCidValue] = useState(null)
     const [slValue, setSlValue] = useState(null)
	const [tripValue, setTripValue] = useState(null)
	const [invoiceValue, setInvoiceValue] = useState(null)
	const [invoiceIdValue, setInvoiceIdValue] = useState(null)


	const [dateInput, setdateInput] = useState(true);
	const [invoiceInput, setInvoiceInput] = useState(false);
	const [invoiceIdInput, setInvoiceIdInput] = useState(false);
	const [slnoInput, setSlnoInput] = useState(false);
	const [tripIdInput, setTripIdInput] = useState(false);

	const [dataLoading, setDataLoading] = useState(true);
	const [responseData, setResponseData] = useState([]);
	
	const dispatch = useDispatch()

	const submitInput = (event) => {
		event.preventDefault();
		let url = get_url()
		setDataLoading(true)
		dispatch(fetchData(url))
		.then(res => {
			setDataLoading(false)
			if (res.data !== undefined) {
				setResponseData(res.data.results)
			} else {
				setResponseData([])
			}
		}).catch(error => {
			setDataLoading(false)
			setResponseData([])
			swal('server error during fetching data')
			console.error('server error during fetching data', error);
		});
	}

	
	const onChangeRadio = (event) => {
		let radio_value = event.target.value

		if (radio_value === "date"){
			setdateInput(true)
			setInvoiceInput(false)
			setInvoiceIdInput(false)
			setSlnoInput(false)
			setTripIdInput(false)
		}
		else if (radio_value === "sl_no"){
			setdateInput(false)
			setInvoiceInput(false)
			setInvoiceIdInput(false)
			setSlnoInput(true)
			setTripIdInput(false)
		}
		else if (radio_value === "invoice"){
			setdateInput(false)
			setInvoiceInput(true)
			setInvoiceIdInput(false)
			setSlnoInput(false)
			setTripIdInput(false)
		}
		else if (radio_value === "trip_id"){
			setdateInput(false)
			setInvoiceInput(false)
			setInvoiceIdInput(false)
			setSlnoInput(false)
			setTripIdInput(true)
		}
		else if (radio_value === "invoice_id"){
			setdateInput(false)
			setInvoiceInput(false)
			setInvoiceIdInput(true)
			setSlnoInput(false)
			setTripIdInput(false)
		}
    }

	function is_allowed_date_range(){
		let difference_in_time = toDate.getTime() - fromDate.getTime();
		let difference_in_days = difference_in_time / (1000 * 3600 * 24);
		return (difference_in_days < 63) ? true : false
	}

	function onChange(value, event) {
		if (event.action === "select-option" && event.option.value ==="*") {
		this.setState(this.options);
		} else if (event.action === "deselect-option" &&
		event.option.value === "*") {
		this.setState([]);
		} else if (event.action === "deselect-option") {
		this.setState(value.filter(o => o.value !== "*"));
		} else if (value.length === this.options.length - 1) {
		this.setState(this.options);
		} else {
		this.setState(value);
		}
	}

	function get_url(){
		if (dateInput){
			if (cidValue){
				return `${apiUrl.ZOHO_GENERATED_INVOICE}?company_id=${cidValue}&from_date=${format(fromDate, 'yyyy-MM-dd')}&to_date=${format(toDate, 'yyyy-MM-dd')}`
			}else{
				return `${apiUrl.ZOHO_GENERATED_INVOICE}?from_date=${format(fromDate, 'yyyy-MM-dd')}&to_date=${format(toDate, 'yyyy-MM-dd')}`
			}
		}
		else if (invoiceInput){
			return `${apiUrl.ZOHO_GENERATED_INVOICE}?invoice_number=${invoiceValue}`
		}
		else if (tripIdInput){
			return `${apiUrl.ZOHO_GENERATED_INVOICE}?trip_id=${tripValue}`
		}
		else if (slnoInput){
			return `${apiUrl.ZOHO_GENERATED_INVOICE}?bbt_sl_no=${slValue}`
		}
		else if (invoiceIdInput){
			return `${apiUrl.ZOHO_GENERATED_INVOICE}?invoice_id=${invoiceIdValue}`
		}
	}

	// Download data in CSV
	const [csvDownloadData, setCsvDownloadData] = useState([])
	const [csvHeaders, setCsvHeaders] = useState([])
	const downloadCSV = () => {
	   let _tableColumns = [...cols1()]
	   let columnSelector = []
	   let filteredData = []
	   let headers = []
	   let _data = [...responseData]
	   if (_data.length > 0){
		 for (let col of _tableColumns) {
		   columnSelector.push(col.selector)
		   headers.push({
			 label: col.name,
			 key: col.selector
		   })
		 }
   
		 for (let d of _data) {
		   let newObj = new Object()
		   for (let key in d) {
			 if (columnSelector.includes(key)) {
			   newObj[key] = d[key]
			 }
		   }
		   filteredData.push(newObj)
		 }
		 setCsvDownloadData(filteredData)
		 setCsvHeaders(headers)
	   }else{
		 return false
	   }
	 }

	useEffect(() => {
		let url = get_url()
		dispatch(fetchData(url))
			.then(res => {
				setDataLoading(false)
				if (res.data !== undefined) {
					setResponseData(res.data.results)
				} else {
					setResponseData([])
				}
			}).catch(error => {
				setDataLoading(false)
				console.error('Error during fetching data', error);
			});
	}, [dispatch])

	return (
		<div className="viewInvoices">
			<div className="viewInvoices__container">
				<h4 className="viewInvoices__title">Zoho Auto Generated Invoices</h4>
				<div className="viewInvoices__horizontalLine"></div>
				<div className="searchSection">
					<Row>
							Filter By :
							<Col md={6}>
							{<div className="uploadModel__radioContainer">
								<div className="d-flex">
									<input onChange={onChangeRadio} defaultChecked type="radio" id="date" name="model" value="date" />
									<label className="individualInvoice__labelText" for="date">Company ID and Invoice Date Range</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="sl_no" name="model" value="sl_no" />
									<label className="individualInvoice__labelText" for="sl_no">BBT SL NO</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="trip_id" name="model" value="trip_id" />
									<label className="individualInvoice__labelText" for="trip_id">Trip Id</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="invoice" name="model" value="invoice" />
									<label className="individualInvoice__labelText" for="invoice">Invoice Number</label>
								</div>
								<div className="d-flex">
									<input onChange={onChangeRadio} type="radio" id="invoice_id" name="model" value="invoice_id" />
									<label className="individualInvoice__labelText" for="invoice_id">Invoice ID</label>
								</div>
							</div>}
							</Col>
						</Row>

					<div className="viewInvoices__horizontalLine"></div>

					<Row>
						{dateInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
                                   {<Form.Group as={Row} className=""  controlId="formPlaintextEmail">
                                        Client ID
                                   <Col className="pl-0 mr-0" md={4}>
                                        <FormControl onChange={(e) => setCidValue(e.target.value)} className="viewInvoices__inputSearch" type='text' placeholder='Enter Client ID comma separated' maxLength={500}/>
                                   </Col>
                                   </Form.Group>}
							From
							<Col>
								<div className="dashboard__timePeriod">
									<img
									className="dashboard__calenderImg"
									src='images/calender.png'
									alt='calender-icon'
									/>
									<DatePicker
									selected={fromDate}
									onChange={date => {
										setStartDate(date);
									}}
									dateFormat="dd-MMM-yyyy"
									className="dashboard__datepicker"
									/>
								</div>
							</Col>
							To
							<Col>
								<div className="dashboard__timePeriod" >
									<img
									className="dashboard__calenderImg"
									src='images/calender.png'
									alt='calender-icon'
									/>
									<DatePicker
									selected={toDate}
									onChange={date => {
										setEndDate(date, 'MM/dd/yyyy');
									}}
									dateFormat="dd-MMM-yyyy"
									minDate={fromDate}
									maxDate={new Date(moment(fromDate).add(1, 'months'))}
									/>
								</div>
							</Col>
						</Form.Group>}

						{invoiceInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
							Invoice Number
						<Col className="pl-0 mr-0" md={6}>
							<FormControl onChange={(e) => setInvoiceValue(e.target.value)} className="viewInvoices__inputSearch" type='text' placeholder='Enter Invoice Number to search' maxLength={500}/>
						</Col>

						</Form.Group>}

						{invoiceIdInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
							Invoice ID
						<Col className="pl-0 mr-0" md={6}>
							<FormControl onChange={(e) => setInvoiceIdValue(e.target.value)} className="viewInvoices__inputSearch" type='text' placeholder='Enter Invoice ID to search' maxLength={500}/>
						</Col>

						</Form.Group>}

						{slnoInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
							Sequence Number
						<Col className="pl-0 mr-0" md={4}>
							<FormControl onChange={(e) => setSlValue(e.target.value)} className="viewInvoices__inputSearch" type='text' placeholder='Enter SL NO to search' maxLength={500}/>
						</Col>
						</Form.Group>}

						{tripIdInput && <Form.Group as={Row} className=""  controlId="formPlaintextEmail">
							Trip Id
						<Col className="pl-0 mr-0" md={4}>
							<FormControl onChange={(e) => setTripValue(e.target.value)} className="viewInvoices__inputSearch" type='text' placeholder='Enter Trip ID to search'  maxLength={500}/>
						</Col>
						</Form.Group>}

						<Col>
							<button onClick={submitInput} className="viewInvoices__txnDateSeach" id="submit_btn">Submit</button>
						</Col>
					</Row>

				</div>
				<div className="viewInvoices__horizontalLine"></div>

				<div style={{ textAlign: "right", padding: "0px 25px 15px 0"}}>
					<CSVLink onClick={downloadCSV} headers={csvHeaders} filename={"zoho_invoices.csv"} data={csvDownloadData}>
						<Button id='btn-download' className="pr-0">
						<span>
							<img className='mr-2' src='images/download.png' />
						</span>
							Download
						</Button>
					</CSVLink>
				</div>

				{(!dataLoading) ? (      
				(responseData.length > 0) ? (
					<Datatable1
						data={responseData}
						columns={cols1()}
					/>
				): (
					<div className="dashboard__noDataFound">
					  <h1>No data Available</h1>
					</div>
				  )
				): (
					<Table striped bordered>
						<thead>
							<tr>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
								<th><Skeleton /></th>
							</tr>
						</thead>
						<tbody>
							{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => {
								return (
									<tr className='align-items-center'>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
										<th><Skeleton /></th>
									</tr>
								);
							})}
						</tbody>
					</Table>
					)}

			</div>
			<Footer />
		</div>
	)
}


export default ZohoInput;
