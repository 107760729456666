import React, { Component, useState } from 'react';
import { Row, Col, InputGroup, Button, Form, FormControl } from 'react-bootstrap';
import './Login.css';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

// Validation
import { Formik } from 'formik';
import * as Yup from 'yup';


const Login = ({ login, isAuthenticated }) => {
    return (

        <Formik
            initialValues={{email: '', password: ''}}
            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    console.log('Loging in', values)
                    setSubmitting(false);
                }, 500)
                login(values.email, values.password);
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required("Email Required"),
                password: Yup.string()
                  .required("Password Required")
              })}
        >
            {props => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                } = props;

                return (
                    <div className="login">
            
                        <div className="login__container">
                            <Row>
                                <Col sm={7} className="text-center">
                                    <img src='images/LoginImage.png' className="login-banner" />
                                </Col>
                                <Col sm={5}>
                                    <div className="login-screen">
                                        <img src="images/itilite-finance-logo.png" className="login__financeLogo" />
                                        <Form onSubmit={handleSubmit}>
                                            {errors.email && touched.email && (
                                                <div className="login__feedback">{errors.email}</div>
                                            )}
                                            {errors.password && touched.password && (
                                                <div className="login__feedback">{errors.password}</div>
                                            )}
                                            <Form.Row className="align-items-center">
                                                <Col xs="12">
                                                    <Form.Label htmlFor="email" srOnly>
                                                        Email ID
                                                    </Form.Label>
                                                    <InputGroup className="mb-4 login__input">
                                                        <InputGroup.Prepend>
                                                        <InputGroup.Text className="login__inputImage">
                                                            <img src="images/email.png" className="login-input-img" />
                                                        </InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <FormControl 
                                                            onChange={handleChange} 
                                                            onBlur={handleChange}
                                                            type="text"
                                                            value={values.email}
                                                            className="login__inputEmail" 
                                                            className={`${errors.email} login__inputEmail` && `${touched.email} login__inputEmail` && "error login__inputEmail"}
                                                            name="email"
                                                            id="inlineFormInputGroup" 
                                                            placeholder="Email ID" 
                                                        />
                                                    </InputGroup>

                                                </Col>
                                                <Col xs="12">
                                                    <Form.Label htmlFor="password" srOnly>
                                                        Password
                                                    </Form.Label>
                                                    <InputGroup className="mb-4 login__input">
                                                        <InputGroup.Prepend>
                                                        <InputGroup.Text className="login__inputImage">
                                                            <img src="images/Password.png" className="login-input-img" />
                                                        </InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <FormControl 
                                                            onChange={handleChange} 
                                                            onBlur={handleBlur}
                                                            name="password"
                                                            value={values.password}
                                                            className="login__inputEmail" 
                                                            className={`${errors.password} login__inputEmail` && `${touched.password} login__inputEmail` && "error login__inputEmail"}
                                                            id="password" 
                                                            placeholder="Password"
                                                            type="password" 
                                                        />
                                                    </InputGroup>
                                                    {/* {errors.password && touched.password && (
                                                        <div className="input-feedback">{errors.password}</div>
                                                    )} */}
                                                </Col>
                                                <Col xs="12" className="login__formColumn">
                                                    <Button type="submit" disabled={isSubmitting} className="mb-2 login__btn">
                                                        Login
                                                    </Button>
                                                </Col>
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                );
            }}

        </Formik>
    );
}


Login.propTypes  = {
    login: PropTypes.func.isRequired, 
    isAuthenticated: PropTypes.bool,
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
})

const mapDispatchToProps = {
    login
}

export default connect(
    mapStateToProps,
    { login })(Login);
