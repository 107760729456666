import https from 'https';

export const httpsAgent = new https.Agent({ keepAlive: true });

const API_URL = process.env.REACT_APP_FINANCE_BACKEND_URI;

export const apiUrl = {
    LOGIN_URL: `${API_URL}/auth/login`,
    LOAD_USER: `${API_URL}/auth/user`,
    TABLE_DATA: `${API_URL}/finance/data/filter`,
    ADVANCE_FILTER_ENTITY: `${API_URL}/finance/entity/list`,
    COMPANY_ALIAS_LIST: `${API_URL}/finance/company-alias/list`,
    TTR_REPORT:`${API_URL}/finance/download/ttr-report`,
    GSEQNUMBER_DETAILS: `${API_URL}/bbt/latest-g-sl-no`,
    COMP_LATEST_BALANCE: `${API_URL}/bbt/companies-latest-balance`,
    INOVICES_TABLE: `${API_URL}/invoice/mapped-data`,
    DELETE_INVOICE: `${API_URL}/invoice/mapping/delete`,
    INVOICE_SUBMIT: `${API_URL}/invoice/submit`,
    INVOICE_MAPPING_SHEET_URL: `${API_URL}/invoice/mapping-sheet/submit`,
    INVOICE_PROCESS_TRACKER: `${API_URL}/invoice/process-tracker`,
    INVOICE_MAPPING_TRACKER: `${API_URL}/invoice/mapping-tracker`,
    INVOICE_DOWNLOAD: `${API_URL}/invoice/download`,
    ZOHO_INPUT: `${API_URL}/invoice/zoho-input`,
    EDF_FILE: `${API_URL}/function/amex-edf`,
    ZOHO_INVOICE: `${API_URL}/invoice/invoice-dashboard`,
    ZOHO_GENERATED_INVOICE: `${API_URL}/invoice/zoho-auto-invoice-tracker`,
    INVOICE_GENERATE: `${API_URL}/invoice/invoice-generation-log`,
    FETCH_TRANS_PLUS_SF_CLIENTS: `${API_URL}/finance/trans-plus-sf-clients/list`
}
