import axios from 'axios';
import  { apiUrl } from '../../utils/apiUrl';
import {
    FETCHING_INVOICES_TABLE_REQUEST,
    FETCHING_INVOICES_TABLE_SUCCESS,
    FETCHING_INVOICES_TABLE_FAIL,
    DELETE_INVOICE_REQUEST,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_FAIL,
    SAVE_DELETE_INVOICE,
    SAVE_COMPANY_N_DATERANGE,
    FILTER_DELETE_INVOICES,
    SET_COMPANY_SELECTED,
    GET_COLUMNS,
    SAVE_INDIVIDUAL_UPLOADED_INVOICE,
    SET_CURRENT_PAGE,
    SET_PER_PAGE,
    FETCH_COMPANY_SUCCESS,
    FETCH_COMPANY_FAIL,
    FETCH_TRANS_PLUS_SF_CLIENTS
} from './types';
import swal from 'sweetalert';


export const fetchInvoices = ({ company, fromDate, toDate, perPage="", page=1}) => async dispatch => {
    dispatch({
        type:  FETCHING_INVOICES_TABLE_REQUEST
    })
    try {
        const res = await axios.get(`${apiUrl.INOVICES_TABLE}?company=${company}&from_date=${fromDate}&to_date=${toDate}&start=${((page - 1)*perPage) + 1}&limit=${perPage}`);

        dispatch({
            type: FETCHING_INVOICES_TABLE_SUCCESS,
            payload: res.data
        })
    } catch (error) {
        console.log(error)
        try{
            if (error.response.status === 400){
                swal(error.response.data.message)
            }
        }catch(try_err){
            console.log(error)
        }
        dispatch({
            type: FETCHING_INVOICES_TABLE_FAIL,
            payload: error.response
        })
    }
}

export const deleteInvoice = ({ bbtSlNo, invoiceNumber, invoiceType, all }) => async dispatch => {
    dispatch({
        type: DELETE_INVOICE_REQUEST
    })
    try {
        const encodedInvoiceNumber = encodeURIComponent(invoiceNumber);
        const res = await axios.delete(`${apiUrl.DELETE_INVOICE}?bbt_sl_no=${bbtSlNo}&invoice_number=${encodedInvoiceNumber}&invoice_type=${invoiceType}&all=${all}`);

        dispatch({
            type: DELETE_INVOICE_SUCCESS,
            payload: res.data
        })
        
        // console.log('action delete>>>', res)
        return res;
    } catch (error) {
        if (error.response && error.response.data.message) {
            console.log(error.response, error.response.data)
        } else if (error.request) {
            console.log(error.request)
        }
        dispatch({
            type: DELETE_INVOICE_FAIL,
            payload: error.response
        })
        
    }
}

export const saveDeleteInvoice = (payload) => dispatch => {
    dispatch({
        type: SAVE_DELETE_INVOICE,
        payload: payload
    })
}

export const saveCompanyAndDate = (payload) => dispatch => {
    dispatch({
        type: SAVE_COMPANY_N_DATERANGE,
        payload: payload
    })
}

export const filterDeleteInvoices = (payload) => dispatch => {
    dispatch({
        type: FILTER_DELETE_INVOICES,
        payload: payload
    })
}


export const companySelected = (payload) => dispatch => {
    dispatch({
        type: SET_COMPANY_SELECTED,
        payload: payload
    })
}

export const getColumns = (payload) => dispatch => {
    dispatch({
        type: GET_COLUMNS,
        payload: payload
    })
}


export const saveIndividualUploadedInvoice = (payload) => dispatch => {
    dispatch({
        type: SAVE_INDIVIDUAL_UPLOADED_INVOICE,
        payload: payload
    })
}

export const setPerPage = (payload) => dispatch => {
    dispatch({
        type: SET_PER_PAGE,
        payload: payload
    })
}

export const setCurrentPage = (payload) => dispatch => {
    dispatch({
        type: SET_CURRENT_PAGE,
        payload: payload
    })
}

export const fetch_trans_plus_sf_clients = (company_name="") => async dispatch => {
    let url = `${apiUrl.FETCH_TRANS_PLUS_SF_CLIENTS}`;
    if(company_name){
        url += `?company_name=${company_name}`;
    }
    const res = await axios.get(`${url}`);
    dispatch({
        type: FETCH_TRANS_PLUS_SF_CLIENTS,
        payload: res.data.results
    })
}

/* --------------Fetch Company Columns unique value--------------- */
export const fetchCompany = (currency="both", filter_trans_plus_sf=2) => async dispatch => {
    try {
        let token = localStorage.token;
        let config = {
            headers: {
                "Authorization": token
            }
        }
        const res = await axios.get(`${apiUrl.COMPANY_ALIAS_LIST}?currency=${currency}&filter_trans_plus_sf=${filter_trans_plus_sf}`, config);

        dispatch({
            type: FETCH_COMPANY_SUCCESS,
            payload: res.data.results
        })
    } catch (error) {
        console.log(error)
        dispatch({
            type: FETCH_COMPANY_FAIL
        })
        return error
    }
}
