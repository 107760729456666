import { combineReducers } from 'redux';
import auth from './auth';
import tableData from './tableData';
import viewInvoices from './viewInvoices';
import viewLatestBalance from './viewLatestBalance';

export default combineReducers({
    auth,
    tableData,
    viewInvoices,
    viewLatestBalance
});