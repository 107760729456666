import axios from 'axios';
import { 
    FETCHING_TABLE_DATA_SUCCESS, 
    FETCHING_TABLE_DATA_FAIL,
    BASIC_SEARCH_SUCCESS, 
    BASIC_SEARCH_FAIL,
    SELECT_VIEW_SUCCESS,
    CUSTOM_VIEW_SUCCESS,
    CUSTOM_VIEW_APPLY,
    CUSTOM_COLUMN_REMOVE
} from './types';
import { apiUrl } from '../utils/apiUrl';
import swal from 'sweetalert';


// Data Fetch
export const tableData = ({ fromDate, toDate }) => async dispatch => {
    let config = {headers: {"Authorization": localStorage.token}}
    try {
        const res = await axios.get(`${apiUrl.TABLE_DATA}?from_date=${fromDate}&to_date=${toDate}&zip=true`, config);
        dispatch({
            type: FETCHING_TABLE_DATA_SUCCESS,
            payload: res.data
        })
        return res
    } catch(error) {
        console.log(error)
        dispatch({
            type: FETCHING_TABLE_DATA_FAIL
        })
        try{
            if (error.response.status === 400){
                swal(error.response.data.message)
            }
        }catch(try_err){
            console.log(error)
        }
        
        return error
    }
}
// Basic Search
export const basicSearch = (searchInput, selectInput, fromDate='', toDate='') => async dispatch => {
    try {
        const res = await axios.get(`${apiUrl.TABLE_DATA}?${selectInput}=${searchInput}&from_date=${fromDate}&to_date=${toDate}&zip=true`)
        dispatch({
            type: BASIC_SEARCH_SUCCESS,
            payload: res.data
        })
        return res
    } catch (error) {
        console.log(error)
        dispatch({
            type: BASIC_SEARCH_FAIL,
        })
        try{
            if (error.response.status === 400){
                swal(error.response.data.message)
            }
        }catch(try_err){
            console.log(error)
        }
        return error
    }
}

// Select View
export const selectViewAction = (payload) => dispatch => {
    dispatch({
        type: SELECT_VIEW_SUCCESS,
        payload
    })
}

// Custom View
export const customViewAction = (payload) => dispatch => {
    dispatch({
        type: CUSTOM_VIEW_SUCCESS,
        payload
    })
}

// Custom View apply
export const customViewApply = () => dispatch => {
    dispatch({
        type: CUSTOM_VIEW_APPLY,
    })
}

export const customColumnRemove = (payload) => dispatch => {
    dispatch({
        type: CUSTOM_COLUMN_REMOVE,
        payload
    })
}